import React from 'react';
import { connect } from 'react-redux';
import { configurationActions, userActions } from '../../actions';
import { Link } from 'react-router-dom';
import { Layout } from '../../components/layout';
import { Table } from '../../components/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../components/spinner';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Button, Form, FormGroup, Badge } from 'reactstrap';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryActions } from '../../actions'

const Search = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userColumns = [
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'email',
            text: 'Email',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const ticketColumns = [
        {
            dataField: 'ticket',
            text: 'Ticket Number',
        },
        {
            dataField: 'customer',
            text: 'Customer',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const inventoryColumns = [
        {
            dataField: 'customer',
            text: 'Customer',
        },
        {
            dataField: 'season',
            text: 'Season',
        },
        {
            dataField: 'date',
            text: 'Date',
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const equipmentColumns = [
        {
            dataField: 'customer',
            text: 'Customer',
        },
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'license',
            text: 'License',
        },
        {
            dataField: 'active',
            text: 'Active',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const parkingColumns = [
        {
            dataField: 'vehicle',
            text: 'Vehicle',
            sort: true,
        },
        {
            dataField: 'customer',
            text: 'Customer',
        },
        {
            dataField: 'parking_location',
            text: 'Location',
        },
        {
            dataField: 'guest_name',
            text: 'Guest Name',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'arrival',
            text: 'Arrival',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'departure',
            text: 'Departure',
            hidden: document.documentElement.clientWidth < 500,
        },
    ];

    var ticketData = [];
    var inventoryData = [];
    var equipmentData = [];
    var parkingData = [];
    var userData = [];
    for (var i = 0; i < props.results.data.length; i++) {
        var record = props.results.data[i];

        if (record.type === 'ticket') {

            ticketData.push({
                customer: record.record.requested_by.first_name + ' ' + record.record.requested_by.last_name,
                actions: (
                    <Button
                        value={record.record.id}
                        color={'secondary'}
                        type={'submit'}
                        style={{ float: 'right', marginLeft: 5 }}
                        onClick={(e) => navigate('/tickets/' + e.currentTarget.value)}
                    >
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#fff' }} />
                    </Button>
                ),
                ticket: '#' + record.record.id,
            });
        } else if (record.type === 'inventory') {
            inventoryData.push({
                customer: record.inventory.customer.first_name + ' ' + record.inventory.customer.last_name,
                season: record.inventory.season.name,
                date: moment(record.inventory.date).calendar(),
                equipment: record.inventory.equipment.name,
                actions: (
                    <Button
                        color={'secondary'}
                        type={'submit'}
                        style={{ float: 'right', marginLeft: 5 }}
                        value={record.inventory.id}
                        onClick={(e) => navigate('/inventory/' + e.currentTarget.value)}
                    >
                        <FontAwesomeIcon icon={faAngleRight} style={{ color: '#fff' }} />
                    </Button>
                ),
            });
        } else if (record.type === 'equipment') {
            equipmentData.push({
                customer: record.customer.first_name + ' ' + record.customer.last_name,
                name: record.name,
                license: record.license,
                active: (
                    <input type={'checkbox'} name={'selectedActive'} checked={!record.inactive} disabled={true} />
                ),
                actions: (
                    !record.inactive
                        ? (
                            <div style={{}}>
                                {record.family_members.length > 0 && <h4><span className={"badge " + "badge-primary"}>{`${record.family_members.length} family member${record.family_members.length == 1 ? "" : "s"}`}</span></h4>}
                                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} id={record.id} onClick={(e) => dispatch(inventoryActions.makeInactive(props.token, props.marina, e.currentTarget.id))} >
                                    Make Inactive
                                </Button>
                            </div>)
                        : (
                            <div style={{}}>
                                {record.family_members.length > 0 && <h4><span className={"badge " + "badge-primary"}>{`${record.family_members.length} family member${record.family_members.length == 1 ? "" : "s"}`}</span></h4>}
                                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} id={record.id} onClick={(e) => dispatch(inventoryActions.makeActive(props.token, props.marina, e.currentTarget.id))} >
                                    Make Active
                                </Button>
                            </div>
                        )
                )
            });
        } else if (record.type === 'parking') {
            parkingData.push({
                vehicle: record.record.vehicle,
                customer: record.record.customer,
                parking_location: record.record.parking_location,
                key_number: record.record.key_number,
                arrival: moment(record.record.arrival_date).calendar(),
                departure: record.record.departure_date ? moment(record.record.departure_date).calendar() : '-',
            });
        } else if (record.type === 'user') {
            var user = record.user;
            userData.push({
                name: (
                    <Link to={'/account/profile?userId=' + user.id} style={{ textDecoration: 'none', color: '#000', display: 'flex', alignItems: 'center' }}>
                        <img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={user.first_name + " " + user.last_name} style={{ marginRight: 10 }} />
                        <div style={{ paddingTop: 10 }}>
                            {user.first_name + " " + user.last_name}
                        </div>
                    </Link>
                ),
                email: user.email,
                actions: (
                    <div style={{}}>
                        <Button
                            color={'secondary'}
                            type={'submit'}
                            style={{ float: 'right', marginLeft: 5 }}
                            value={user.id}
                            onClick={(e) => navigate('/account/profile?userId=' + e.currentTarget.value)}
                        >
                            <FontAwesomeIcon icon={faAngleRight} style={{ color: '#fff' }} />
                        </Button>
                    </div>
                ),
            });
        }
    }

    return (
        <div>
            <h1>Search Results</h1>
            <hr />

            {props.results.data.length === 0 && !props.searching ? (
                <p>No results are found for your search</p>
            ) : (
                <>
                    <Layout title={'Customers and Staff'} hideCard={false}>
                        <Table
                            title={'Users'}
                            keyField={'type'}
                            data={userData}
                            columns={userColumns}
                            isLoading={props.searching}
                            pagination={props.results}
                            onPageChange={() => { }}
                            hidePageCount={true}
                        />
                    </Layout>

                    <Layout title={'Parking'} hideCard={false}>
                        <Table
                            title={'Parking'}
                            keyField={'type'}
                            data={parkingData}
                            columns={parkingColumns}
                            isLoading={props.searching}
                            pagination={props.results}
                            onPageChange={() => { }}
                            hidePageCount={true}
                        />
                    </Layout>

                    <Layout title={'Tickets'} hideCard={false}>
                        <Table
                            title={'Tickets'}
                            keyField={'type'}
                            data={ticketData}
                            columns={ticketColumns}
                            isLoading={props.searching}
                            pagination={props.results}
                            onPageChange={() => { }}
                            hidePageCount={true}
                        />
                    </Layout>

                    <Layout title={'Inventory'} hideCard={false}>
                        <Table
                            title={'Inventory'}
                            keyField={'type'}
                            data={inventoryData}
                            columns={inventoryColumns}
                            isLoading={props.searching}
                            pagination={props.results}
                            onPageChange={() => { }}
                            hidePageCount={true}
                        />
                    </Layout>

                    <Layout title={'Equipment'} hideCard={false}>
                        <Table
                            title={'boats'}
                            keyField={'type'}
                            data={equipmentData}
                            columns={equipmentColumns}
                            isLoading={props.searching}
                            pagination={props.results}
                            onPageChange={() => { }}
                            hidePageCount={true}
                        />
                    </Layout>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    searching: state.configuration.searching,
    results: state.configuration.searchResults,
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
});

export default connect(mapStateToProps)(Search);
