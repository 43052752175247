import { alertActions } from './'
import { inventoryConstants } from '../constants';
import { inventoryApi } from '../api'
import { history } from '../store';
import { push } from 'connected-react-router'

export const inventoryActions = {
    listEquipment,
    listEquipmentWithFilter,
    createEquipment,
    listInventory,
    addUserToEquipment,
    makeInactive,
    makeActive
};

function listEquipment(token, marina, page, user=null, query) {
    return dispatch => {
        dispatch(request());

        inventoryApi.listEquipment(token, marina, page, user, query)
            .then(
                equipment => {
                    dispatch(success(equipment));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: inventoryConstants.EQUIPMENT_LIST_REQUEST } }
    function success(equipment) { return { type: inventoryConstants.EQUIPMENT_LIST_SUCCESS, equipment } }
    function failure(error) { return { type: inventoryConstants.EQUIPMENT_LIST_FAILURE, error } }
}

function listEquipmentWithFilter(token, marina, user, filter) {
    return dispatch => {
        dispatch(request());

        var list = inventoryApi.listEquipment(token, marina, 1, user, filter)
            .then(
                equipment => {
                    dispatch(success(equipment));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    function request() { return { type: inventoryConstants.EQUIPMENT_LIST_REQUEST } }
    function success(equipment) { return { type: inventoryConstants.EQUIPMENT_LIST_SUCCESS, equipment } }
    function failure(error) { return { type: inventoryConstants.EQUIPMENT_LIST_FAILURE, error } }
}

function createEquipment(token, marina, name, year, make, model, license, serialNumber, vetted, customer, navigate) {
    return dispatch => {
        dispatch(request());

        var list = inventoryApi.createEquipment(token, marina, name, year, make, model, license, serialNumber, vetted, customer)
            .then(
                equipment => {
                    dispatch(success(equipment));
                    navigate('/inventory/equipment')
                    dispatch(alertActions.success("Equipment created!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    function request() { return { type: inventoryConstants.EQUIPMENT_CREATE_REQUEST } }
    function success(equipment) { return { type: inventoryConstants.EQUIPMENT_CREATE_SUCCESS, equipment } }
    function failure(error) { return { type: inventoryConstants.EQUIPMENT_CREATE_FAILURE, error } }
}

function listInventory(token, marina, page, ordering, filter) {
    return dispatch => {
        dispatch(request());

        inventoryApi.listInventory(token, marina, page, ordering, filter)
            .then(
                inventory => {
                    dispatch(success(inventory));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: inventoryConstants.INVENTORY_LIST_REQUEST } }
    function success(inventory) { return { type: inventoryConstants.INVENTORY_LIST_SUCCESS, inventory } }
    function failure(error) { return { type: inventoryConstants.INVENTORY_LIST_FAILURE, error } }
}


function addUserToEquipment(token, equipmentId, userId) {
    return dispatch => {
        //dispatch(request());

        var list = inventoryApi.addUserToEquipment(token, equipmentId, userId)
            .then(
                equipment => {
                    //dispatch(success(equipment));
                    //dispatch(push('/account/profile?userId=' + originalUserId))
                    window.location.reload();
                    dispatch(alertActions.success("User added to equipment!"));
                },
                error => {
                    //dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    // function request() { return { type: inventoryConstants.EQUIPMENT_CREATE_REQUEST } }
    // function success(equipment) { return { type: inventoryConstants.EQUIPMENT_CREATE_SUCCESS, equipment } }
    // function failure(error) { return { type: inventoryConstants.EQUIPMENT_CREATE_FAILURE, error } }
}


function makeInactive(token, marina, equipmentId) {
    return dispatch => {
        // dispatch(request());

        var list = inventoryApi.patchEquipment(token, equipmentId, true)
            .then(
                equipment => {
                    // dispatch(success(equipment));
                    dispatch(alertActions.success("Equipment made inactive!"));
                    dispatch(listEquipment(token, marina, 1))
                },
                error => {
                    // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    // function request() { return { type: inventoryConstants.EQUIPMENT_CREATE_REQUEST } }
    // function success(equipment) { return { type: inventoryConstants.EQUIPMENT_CREATE_SUCCESS, equipment } }
    // function failure(error) { return { type: inventoryConstants.EQUIPMENT_CREATE_FAILURE, error } }
}

function makeActive(token, marina, equipmentId) {
    return dispatch => {
        // dispatch(request());

        var list = inventoryApi.patchEquipment(token, equipmentId, false)
            .then(
                equipment => {
                    // dispatch(success(equipment));
                    dispatch(alertActions.success("Equipment made active!"));
                    dispatch(listEquipment(token, marina, 1))
                },
                error => {
                    // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    // function request() { return { type: inventoryConstants.EQUIPMENT_CREATE_REQUEST } }
    // function success(equipment) { return { type: inventoryConstants.EQUIPMENT_CREATE_SUCCESS, equipment } }
    // function failure(error) { return { type: inventoryConstants.EQUIPMENT_CREATE_FAILURE, error } }
}

