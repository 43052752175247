import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { alertActions, configurationActions, ticketActions } from '../../actions';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import { Form } from '../../components/form';
import TextField from './../../components/textfield';
import { Col, Row, Button, FormGroup, Label } from 'reactstrap';
import { CustomerSelect, EquipmentSelect, ActionSelect } from '../../components/select';
import { useNavigate } from 'react-router';

const NewTicket = (props) => {
    const [customer, setCustomer] = useState(null);
    const [agents, setAgents] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [equipment, setEquipment] = useState(null);
    const [dateRequired, setDateRequired] = useState(new Date());
    const [action, setAction] = useState(null);
    const [option, setOption] = useState(null);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        props.dispatch(configurationActions.changePageTitle('New Ticket'));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'message') {
            setMessage(value);
        }
    };

    const handleChangeSelect = (newValue, actionMeta) => {
        const { name } = actionMeta;
        if (name === 'customer') {
            setCustomer(newValue);
        } else if (name === 'agents') {
            setAgents(newValue);
        }
    };

    const handleChangeDateTime = (dateTime) => {
        setDateRequired(dateTime);
    };

    const handleChangeCustomer = (customer) => {
        const custId = customer.value;
        setCustomer(custId);
    };

    const handleChangeAgent = (agents) => {
        const agentIds = agents.map((agent) => agent.value);
        setAgents(agentIds);
    };

    const handleChangeEquipment = (equipment) => {
        const equipmentId = equipment.value;
        setEquipment(equipmentId);
    };

    const handleChangeAction = (action) => {
        const actionId = action.value;
        setAction(actionId);
    };

    const handleChangeOption = (option) => {
        const optionId = option.value;
        console.log(optionId)
        setOption(optionId);
    };

    const handleChangeQuill = (value, delta, source, editor) => {
        const text = editor.getText(value);
        setMessage(text);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);

        const assignedTo = agents.join(',');

        if (!equipment) {
            props.dispatch(alertActions.error('No equipment has been selected'));
            return;
        }

        if (!action || !option) {
            props.dispatch(alertActions.error('No action has been selected'));
            return;
        }

        if (!message) {
            props.dispatch(alertActions.error('No message has been entered'));
            return;
        }

        props.dispatch(
            ticketActions.createTicket(
                props.token,
                props.marina,
                customer,
                equipment,
                assignedTo,
                false,
                option,
                message,
                dateRequired.toISOString(),
                navigate
            )
        );
    };

    return (
        <div>
            <h1>Open New Ticket</h1>

            <Form className={'card card-body'} onSubmit={handleSubmit}>
                <div className={'m-sm-4'}>
                    {props.user.scope >= 1 && (
                        <div>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>What customer is this for?</label>
                                        <CustomerSelect onSelect={handleChangeCustomer} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Assign to agents:</label>
                                        <CustomerSelect onSelect={handleChangeAgent} staff={true} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    )}

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <label>What piece of equipment is this ticket about?</label>
                                <EquipmentSelect
                                    user={props.user.scope >= 1 ? customer : props.user.user_id}
                                    onSelect={handleChangeEquipment}
                                    isDisabled={customer === null && props.user.scope >= 1}
                                    placeholder={'Select a customer first'}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label>When do you need this done by?</label>
                                <br />
                                <DatePicker
                                    className={'form-control form-control-lg'}
                                    selected={dateRequired}
                                    onChange={handleChangeDateTime}
                                    name={'dateRequired'}
                                    minDate={new Date()}
                                    minTime={new Date().setHours(8, 0)}
                                    maxTime={new Date().setHours(23, 59, 59)}
                                    showTimeSelect={true}
                                    timeFormat={'HH:mm'}
                                    timeIntervals={30}
                                    dateFormat={'MMMM d, yyyy h:mm aa'}
                                    timeCaption={'time'}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Row form>
                            <Col md={6}>
                                <label>What do you need done?</label>
                                <ActionSelect
                                    onSelect={handleChangeAction}
                                    isDisabled={equipment === null}
                                    placeholder={'Select equipment first'}
                                />
                            </Col>
                            <Col md={6}>
                                <label>Choose an option:</label>
                                <ActionSelect
                                    onSelect={handleChangeOption}
                                    isDisabled={action === null}
                                    placeholder={'Select an action type first'}
                                    action={action}
                                    isOption={true}
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <label>Message</label>
                        <textarea className={'form-control'} onChange={handleChange} name={'message'}></textarea>
                    </FormGroup>

                    <Button color={'primary'} type={'submit'} disabled={!customer || !equipment || !dateRequired || !action || !option || !message}>
                        Open Ticket
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    user: state.authentication.claims,
});

export default connect(mapStateToProps)(NewTicket);
