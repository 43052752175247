import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { Container, Row, Col, Button } from 'reactstrap';
import { Card, CardBody } from './../../components/card';
import { Form, FormItem } from './../..//components/form';
import TextField from './../../components/textfield';
import Spinner from './../../components/spinner';
import ReactPasswordStrength from 'react-password-strength';

import { configurationActions, userActions } from '../../actions';
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from './../../vectors/dbw-logo.svg';

function Register(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [token, setToken] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [phone, setPhone] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const { search } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		props.dispatch(configurationActions.changePageTitle('Register'));
		setToken(queryString.parse(window.location.search).token);
		if (token) {
			props.dispatch(userActions.getRegisterStatus(token)).then(() => {
				if (Object.keys(props.registerStatus).length === 0) {
					setToken(null);
					return;
				}

				if (props.registerStatus.user) {
					setFirstName(props.registerStatus.user.first_name);
					setLastName(props.registerStatus.user.last_name);
					setEmail(props.registerStatus.user.email);
				}
			});
		}
	}, []);

	const passwordChangeCallback = (state) => {
		if (state.isValid) {
			setPassword(state.password);
		} else {
			setPassword(null);
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'email') {
			setEmail(value);
		} else if (name === 'password') {
			setPassword(value);
		} else if (name === 'phone') {
			setPhone(value);
		} else if (name === 'firstName') {
			setFirstName(value);
		} else if (name === 'lastName') {
			setLastName(value);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (token && email && firstName && lastName && password) {
			props.dispatch(userActions.registerUser(token, email, firstName, lastName, phone, password, navigate));
		}

	};

	if (props.requestingRegisterStatus) {
		return (
			<Form>
				<Spinner style={{ width: 40, height: 40 }} />
			</Form>
		);
	}

	let form;
	if (!token) {
		form = (
			<FormItem>
				<h3>Register for The Hub.</h3>
				<p>You need to register your interest to a member of the team to have your account activated.</p>
			</FormItem>
		);
	} else {
		form = (
			<Form onSubmit={handleSubmit}>
				<FormItem>
					<h3>Registering for {props.registerStatus.marina}</h3>
				</FormItem>
				<FormItem>
					<label><strong>First Name</strong></label>
					<TextField
						type={'text'}
						name={'firstName'}
						value={firstName}
						onChange={handleChange}
						placeholder={'Enter your first name'}
						disabled={props.registeringUser}
					/>
				</FormItem>
				<FormItem>
					<label><strong>Surname</strong></label>
					<TextField
						type={'text'}
						name={'lastName'}
						value={lastName}
						onChange={handleChange}
						placeholder={'Enter your surname'}
						disabled={props.registeringUser}
					/>
				</FormItem>
				<FormItem>
					<label><strong>Email</strong></label>
					<TextField
						type={'text'}
						name={'email'}
						value={email}
						onChange={handleChange}
						placeholder={'Enter your email'}
						disabled={props.registeringUser}
					/>
				</FormItem>
				<FormItem>
					<label>Phone Number</label>
					<TextField
						type={'text'}
						name={'phone'}
						value={phone}
						onChange={handleChange}
						placeholder={'Enter your phone number'}
						disabled={props.registeringUser}
					/>
				</FormItem>
				<FormItem>
					<label style={{ width: '100%' }}><strong>Password</strong>
						<input type="button" className={'btn-text'} value={showPassword ? 'Hide' : 'Show'} style={{ float: 'right' }} onClick={toggleShowPassword} />
					</label>
					<ReactPasswordStrength
						minLength={5}
						minScore={2}
						scoreWords={['very weak', 'weak', 'good', 'strong', 'stronger']}
						changeCallback={passwordChangeCallback}
						inputProps={{
							name: 'password',
							type: showPassword ? 'text' : 'password',
							autoComplete: 'off',
							className: '',
							placeholder: 'Enter your password',
							disabled: props.registeringUser
						}}
					/>
				</FormItem>
				<div className={'text-center mt-3'}>
					<Button color={'primary'} size={'lg'} disabled={props.registeringUser || (!firstName || !lastName || !email || !password)}>
						{props.registeringUser
							? <Spinner color={'white'} style={{ width: 40, height: 40 }} />
							: 'Sign Up'}
					</Button>
				</div>
			</Form>
		);
	}

	return (
		<main className={'main h-100 w-100'}>
			<Container className={'h-100'}>
				<Row className={'h-100'}>
					<Col sm={'10'} md={'8'} lg={'6'} className={'mx-auto d-table h-100'}>
						<div className={'d-table-cell align-middle'}>
							<div className={'text-center mt-4'}>
								<Logo style={{ width: 300 }} />
								<p className={'lead'}>
									Register to access tickets, parking and more.
								</p>
							</div>
							<Card>
								<CardBody>
									{form}
								</CardBody>
							</Card>
						</div>
					</Col>
				</Row>
			</Container>
		</main>
	);
}

function mapStateToProps(state) {
	const { registerStatus, requestingRegisterStatus, registeringUser } = state.user;
	return {
		registerStatus,
		requestingRegisterStatus,
		registeringUser
	};
}

export default connect(mapStateToProps)(Register);