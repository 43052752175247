import { alertActions } from './'
import { configurationConstants } from '../constants';
import { configurationApi, searchApi } from '../api'

export const configurationActions = {
    changeMarina,
    changePageTitle,
    fetchConfiguration,
    fetchSummary,
    fetchContent,
    toggleSidebar,
    search,
    fetchDepartments,
    createDepartment
};

function fetchConfiguration(token, page) {
    return dispatch => {
        dispatch(request());

        configurationApi.fetchConfiguration(token, page)
            .then(
                config => {
                    if (!localStorage.getItem("activeMarinaId")) {
                        localStorage.setItem("activeMarinaId", config.marinas[0].id)
                    }
                    dispatch(fetchContent(token, localStorage.getItem("activeMarinaId")));
                    dispatch(success(config));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.CONFIGURATION_REQUEST } }
    function success(config) { return { type: configurationConstants.CONFIGURATION_SUCCESS, config } }
    function failure(error) { return { type: configurationConstants.CONFIGURATION_FAILURE, error } }
}

function changeMarina(token, marinaId, navigate) {
    return dispatch => {
        localStorage.setItem("activeMarinaId", marinaId)
        dispatch({ type: configurationConstants.CHANGE_MARINA, marinaId });
        navigate("/");

        dispatch(fetchContent(token, marinaId));

        // To show loading screen
        setTimeout(function() {
            dispatch({ type: configurationConstants.MARINA_CHANGED });
        }, 500);
    };
}

function changePageTitle(pageTitle) {
    return dispatch => {
        dispatch({ type: configurationConstants.CHANGE_PAGE_TITLE, pageTitle });
    };
}

function fetchSummary(token, marina) {
    return dispatch => {
        dispatch(request());

        configurationApi.fetchSummary(token, marina)
            .then(
                summary => { 
                    dispatch(success(summary));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.SUMMARY_REQUEST } }
    function success(summary) { return { type: configurationConstants.SUMMARY_SUCCESS, summary } }
    function failure(error) { return { type: configurationConstants.SUMMARY_FAILURE, error } }
}

function fetchContent(token, marina) {
    return dispatch => {
        dispatch(request());

        configurationApi.fetchContent(token, marina)
            .then(
                config => {
                    dispatch(success(config));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.CONFIGURATION_CONTENT_REQUEST } }
    function success(content) { return { type: configurationConstants.CONFIGURATION_CONTENT_SUCCESS, content } }
    function failure(error) { return { type: configurationConstants.CONFIGURATION_CONTENT_FAILURE, error } }
}

function fetchDepartments(token, marina, page) {
    return dispatch => {
        dispatch(request());

        configurationApi.fetchDepartments(token, marina, page)
            .then(
                config => {
                    dispatch(success(config));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.DEPARTMENTS_REQUEST } }
    function success(content) { return { type: configurationConstants.DEPARTMENTS_SUCCESS, content } }
    function failure(error) { return { type: configurationConstants.DEPARTMENTS_FAILURE, error } }
}

function createDepartment(token, marina, department) {
    return dispatch => {
        dispatch(request());

        configurationApi.createDepartment(token, marina, department)
            .then(
                config => {
                    dispatch(success(config));
                    dispatch(fetchDepartments(token, marina, 1));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.CREATE_DEPARTMENT_REQUEST } }
    function success(content) { return { type: configurationConstants.CREATE_DEPARTMENT_SUCCESS, content } }
    function failure(error) { return { type: configurationConstants.CREATE_DEPARTMENT_FAILURE, error } }
}

function toggleSidebar() {
    return dispatch => {
        dispatch({ type: configurationConstants.TOGGLE_SIDEBAR });
    };
}

function search(token, marina, query, navigate) {
    return dispatch => {
        dispatch(request());

        searchApi.handleSearch(token, marina, query)
            .then(
                results => {
                    dispatch(success(results));
                    if (window.location.pathname != "/search") {
                        navigate("/search");
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: configurationConstants.SEARCH_REQUEST } }
    function success(results) { return { type: configurationConstants.SEARCH_SUCCESS, results } }
    function failure(error) { return { type: configurationConstants.SEARCH_FAILURE, error } }
}
