import { configurationConstants } from '../constants';

const defaultSummary = {
  closed: null,
  closed_percentage: null,
  opened: null,
  opened_percentage: null,
  parked: null,
  parked_percentage: null
};

const initialState = {
  loadingOrganisation: false,
  loadedOrganisation: false,
  ready: false,
  marinas: [],
  activeMarinaId: parseInt(localStorage.getItem("activeMarinaId")) || 1,
  loadingSummary: false,
  summary: defaultSummary,
  sidebarClass: '',
  searching: false,
  searchResults: {
    data: [],
    total: 0,
    offset: 0,
    pages: 0,
    current_page: 0
  },
  loadingContent: false,
  loadedContent: false,
  content: null,
  loadingDepartments: false,
  departments: {results: []}
}

export function configuration(state = initialState, action) {
  switch (action.type) {
    case configurationConstants.CONFIGURATION_REQUEST:
      return {
        loadingOrganisation: true,
        loadedOrganisation: false,
        ...state
      };
    case configurationConstants.CONFIGURATION_SUCCESS:
      var activeMarinaId = parseInt(localStorage.getItem("activeMarinaId")) || 1;
      return {
        ...state,
        loadedOrganisation: true,
        organisation: state.organisation,
        marinas: action.config.marinas,
        activeMarina: action.config.marinas.find(x => x.id === activeMarinaId),
        loadingOrganisation: false
      };
    case configurationConstants.CONFIGURATION_FAILURE:
      return {
        errorLoading: true,
        ...state
      };
    case configurationConstants.CHANGE_MARINA:
      return {
        ...state,
        loadingOrganisation: true,
        activeMarina: state.marinas.find(x => x.id === action.marinaId),
        ready: false
      };
    case configurationConstants.MARINA_CHANGED:
      return {
        ...state,
        loadingOrganisation: false,
        ready: true,
        departments: null
      };
    case configurationConstants.CHANGE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      };
    case configurationConstants.SUMMARY_REQUEST:
      return {
        ...state,
        loadingSummary: true,
        summary: defaultSummary
      };
    case configurationConstants.SUMMARY_SUCCESS:
      return {
        ...state,
        loadingSummary: false,
        summary: action.summary
      };
    case configurationConstants.SUMMARY_FAILURE:
      return {
        ...state,
        loadingSummary: false,
        summary: defaultSummary
      };
    case configurationConstants.TOGGLE_SIDEBAR:
      if (state.sidebarClass === 'collapsed') {
        return {
          ...state,
          sidebarClass: ''
        }
      } else {
        return {
          ...state,
          sidebarClass: 'collapsed'
        }
      }
    case configurationConstants.SEARCH_REQUEST:
      return {
        ...state,
        searching: true
      }
    case configurationConstants.SEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        searchResults: action.results
      }
     case configurationConstants.SEARCH_FAILURE:
      return {
        ...state,
        searching: false,
        searchResults: []
      }
    case configurationConstants.CONFIGURATION_CONTENT_REQUEST:
      return {
        ...state,
        loadingContent: true,
        loadedContent: false
      };
    case configurationConstants.CONFIGURATION_CONTENT_SUCCESS:
      return {
        ...state,
        loadingContent: false,
        loadedContent: true,
        content: action.content,
        ready: true
      };
    case configurationConstants.CONFIGURATION_CONTENT_FAILURE:
      return {
        errorLoading: true,
        ...state
      };
    case configurationConstants.DEPARTMENTS_REQUEST:
      return {
        loadingDepartments: true,
        ...state
      };
    case configurationConstants.DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loadingDepartments: false,
        departments: action.content
      };
    case configurationConstants.DEPARTMENTS_FAILURE:
      return {
        loadingDepartments: false,
        ...state
      };
    case configurationConstants.CREATE_DEPARTMENT_REQUEST:
      return {
        loadingDepartments: true,
        ...state
      };
    case configurationConstants.CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loadingDepartments: false
      };
    case configurationConstants.CREATE_DEPARTMENT_FAILURE:
      return {
        loadingDepartments: false,
        ...state
      };
    default:
      return state
  }
}