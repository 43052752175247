import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, inventoryActions } from '../../actions'
import { history } from '../../store';
import TextField from '../../components/textfield'
import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout'
import { Table } from '../../components/tables'
import { Button, Form, FormGroup } from 'reactstrap';
import { throttle } from 'throttle-debounce';

class EquipmentView extends React.Component {

    constructor(props) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);
        this.search = this.search.bind(this);
        this.throttleSearch = throttle(1000, this.handleSearch)
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("Equipment"));
        this.props.dispatch(inventoryActions.listEquipment(this.props.token, this.props.marina, 1));
    }

    onPageChange = (page, sizePerPage) => {
        this.props.dispatch(inventoryActions.listEquipment(this.props.token, this.props.marina, page));
    }

    search(e) {
        const { value } = e.target;
        this.throttleSearch(value);
    }

    handleSearch(value) {
        if (value.length > 1 || value == "") {
            this.props.dispatch(inventoryActions.listEquipment(this.props.token, this.props.marina, 1, null, value));
        }
    }

    render() {

        const columns = [
            {
                dataField: 'serial_number',
                text: 'Serial Number',
                sort: true
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                hidden: document.documentElement.clientWidth < 500
            },
            {
                dataField: 'make_model',
                text: 'Make / Model'
            },
            {
                dataField: 'license',
                text: 'License',
                hidden: document.documentElement.clientWidth < 500
            },
            {
                dataField: 'customer',
                text: 'Customer'
            },
            {
                dataField: 'approved',
                text: 'Approved',
                hidden: document.documentElement.clientWidth < 500
            },
            ,
            {
                dataField: 'active',
                text: 'Active',
                hidden: document.documentElement.clientWidth < 500
            },
            {
                dataField: 'actions',
                text: 'Actions',
                headerAlign: (column, colIndex) => 'right'
            }
        ];

        var data = [];
        for (var i = 0; i < this.props.equipment.results.length; i++) {
            var equipment = this.props.equipment.results[i];
            data.push({
                serial_number: equipment.serial_number ? equipment.serial_number : "-",
                name: equipment.name,
                make_model: equipment.model !== null ? `${equipment.model.make} ${equipment.model.model}` : "Unknown",
                license: equipment.license ? equipment.license : "-",
                customer: `${equipment.customer.first_name} ${equipment.customer.last_name}`,
                approved: (
                    <input type={'checkbox'} name={'selectedVetted'} checked={equipment.vetted} disabled={true} />
                ),
                active: (
                    <input type={'checkbox'} name={'selectedActive'} checked={!equipment.inactive} disabled={true} />
                ),
                actions: (
                    !equipment.inactive
                        ? (
                            <div style={{}}>
                                {equipment.family_members.length > 0 && <h4><span className={"badge " + "badge-primary"}>{`${equipment.family_members.length} family member${equipment.family_members.length == 1 ? "" : "s"}`}</span></h4>}
                                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} id={equipment.id} onClick={(e) => this.props.dispatch(inventoryActions.makeInactive(this.props.token, this.props.marina, e.currentTarget.id))} >
                                    Make Inactive
                                </Button>
                            </div>)
                        : (
                            <div style={{}}>
                                {equipment.family_members.length > 0 && <h4><span className={"badge " + "badge-primary"}>{`${equipment.family_members.length} family member${equipment.family_members.length == 1 ? "" : "s"}`}</span></h4>}
                                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} id={equipment.id} onClick={(e) => this.props.dispatch(inventoryActions.makeActive(this.props.token, this.props.marina, e.currentTarget.id))} >
                                    Make Active
                                </Button>
                            </div>
                        )
                )
            })
        }

        return (
            <Layout title={'Equipment'} noBorder={true}>
                <InnerTitleLayout title={"Equipment List"} hideCard={true} actionType={'Equipment'} actionLink={this.props.user.scope > 0 && '/inventory/equipment/new'} search={<TextField id={"search"} onChange={this.search} placeholder={"Search for equipment"} />}>
                    <Table
                        title={'equipment'}
                        keyField={'category'}
                        data={data}
                        columns={columns}
                        pagination={this.props.equipment}
                        isLoading={this.props.isLoading}
                        onPageChange={this.onPageChange} />
                </InnerTitleLayout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.inventory.loadingInventories,
    users: state.user.users,
    equipment: state.inventory.equipment,
    user: state.authentication.claims,
})

export default connect(mapStateToProps)(EquipmentView);
