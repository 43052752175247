import { parkingConstants } from '../constants';

const initialState = {
  loadingVehicles: false,
  vehicles: { results: [] },
  loadingRecords: false,
  records: { results: [] }
}

export function parking(state = initialState, action) {
  switch (action.type) {
    case parkingConstants.VEHICLES_REQUEST:
      return {
        ...state,
        loadingVehicles: true,
        vehicles: { results: [] }
      }
    case parkingConstants.VEHICLES_SUCCESS:
      return {
        ...state,
        loadingVehicles: false,
        vehicles: action.vehicles
      }
    case parkingConstants.VEHICLES_FAILURE:
      return {
        ...state,
        loadingVehicles: false,
        vehicles: { results: [] }
      }
    case parkingConstants.RECORDS_REQUEST:
      return {
        ...state,
        loadingRecords: true,
        records: { results: [] }
      }
    case parkingConstants.RECORDS_SUCCESS:
      return {
        ...state,
        loadingRecords: false,
        records: action.records
      }
    case parkingConstants.RECORDS_FAILURE:
      return {
        ...state,
        loadingRecords: false,
        records: { results: [] }
      }
    default:
      return state
  }
}