import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { configurationActions, ticketActions } from '../../actions';
import * as Utils from '../../utils';
import { Layout, InnerTitleLayout } from '../../components/layout';
import { Table } from '../../components/tables';
import { Button, Form, FormGroup, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const TicketView = ({
    title,
    onlyCurrentUser,
    user,
    token,
    marina,
    isLoading,
    isLoadingUser,
    users,
    tickets,
    userTickets,
}) => {
    const navigate = useNavigate();

    useEffect(() => {
        const listTickets = () => {
            const userParam = onlyCurrentUser ? (user ? user : null) : null;
            dispatch(ticketActions.listTickets(token, marina, 1, onlyUnsolved, userParam));
        };

        listTickets();
    }, [onlyCurrentUser, user, token, marina]);

    const onPageChange = (page, sizePerPage) => {
        const userParam = onlyCurrentUser ? (user ? user : null) : null;
        dispatch(ticketActions.listTickets(token, marina, 1, onlyUnsolved, userParam));
    };

    const dateFormatter = (cell, row) => {
        return Utils.dateFormatter(row.required_by);
    };

    const headerStyle = (colum, colIndex) => {
        return { width: 90 };
    };

    const openTicket = (event) => {
        const ticketId = event.currentTarget.id;
        navigate(`/tickets/${ticketId}`);
    };

    const columns = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle,
        },
        {
            dataField: 'customer',
            text: 'Requested By',
            sort: true,
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true,
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'required_by',
            text: 'Required By',
            formatter: dateFormatter,
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const columnsQuickView = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle,
        },
        {
            dataField: 'customer',
            text: 'Requested By',
            sort: true,
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const columnsQuickViewUser = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle,
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];


    const columnsQuickViewUserExpanded = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle,
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true,
        },
        {
            dataField: 'required_by',
            text: 'Required By',
            formatter: dateFormatter,
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500,
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const data = [];
    const ticketResults = onlyCurrentUser ? userTickets.results : tickets.results;

    for (let i = 0; i < ticketResults.length; i++) {
        if (isQuickView) {
            if (increaseQuickViewCount ? i >= 25 : i >= 4) {
                continue;
            }
        }

        const ticket = ticketResults[i];
        data.push({
            id: (
                <Link to={`/tickets/${ticket.id}`} style={{ color: '#000' }}>
                    <h4>
                        <span
                            className="badge badge-primary"
                            style={{
                                backgroundColor: ticket.solved ? '#f00' : '#0cc2aa',
                            }}
                        >
                            {'#' + ticket.id}
                        </span>
                    </h4>
                </Link>
            ),
            customer: (
                <Link
                    to={`/tickets/${ticket.id}`}
                    style={{ textDecoration: 'none', color: '#000' }}
                >
                    <div style={{ paddingTop: 10 }}>
                        {ticket.requested_by.first_name + ' ' + ticket.requested_by.last_name}
                    </div>
                </Link>
            ),
            equipment: ticket.equipment.name + ' (' + ticket.equipment.license + ')',
            required_by: ticket.date_required,
            action: ticket.action.action,
            actions: (
                <div style={{}}>
                    {!isQuickView && document.documentElement.clientWidth > 500 && (
                        <Badge color="secondary" style={{ float: 'left' }}>
                            {ticket.comments.length === 1 ? '1 comment' : ticket.comments.length + ' comments'}
                        </Badge>
                    )}
                    <Button
                        id={ticket.id}
                        color="secondary"
                        type="submit"
                        style={{ float: 'right', marginLeft: 5 }}
                        onClick={openTicket}
                    >
                        <FontAwesomeIcon
                            id={ticket.id}
                            onClick={openTicket}
                            icon={faAngleRight}
                            style={{ color: '#fff' }}
                        />
                    </Button>
                </div>
            ),
        });
    }

    const table = (
        <Table
            title="tickets"
            keyField="id"
            data={data}
            columns={
                isQuickView
                    ? onlyCurrentUser
                        ? increaseQuickViewCount
                            ? columnsQuickViewUserExpanded
                            : columnsQuickViewUser
                        : columnsQuickView
                    : columns
            }
            pagination={onlyCurrentUser ? userTickets : tickets}
            isLoading={onlyCurrentUser ? isLoadingUser : isLoading}
            onPageChange={onPageChange}
            action={isQuickView ? 'View all tickets' : null}
            actionLink="/tickets/all"
        />
    );

    return (
        <div>
            {!isQuickView ? (
                <Layout title="Tickets" noBorder>
                    <InnerTitleLayout title={title} hideCard actionType="Ticket" actionLink="/tickets/new">
                        {table}
                    </InnerTitleLayout>
                </Layout>
            ) : (
                <div>{table}</div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.ticket.loadingTickets,
    isLoadingUser: state.ticket.loadingUserTickets,
    users: state.user.users,
    tickets: state.ticket.tickets,
    userTickets: state.ticket.userTickets,
    user: state.authentication.claims.user_id,
});

export default connect(mapStateToProps)(TicketView);
