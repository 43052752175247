import React from 'react'
import { connect } from 'react-redux'
import { configurationActions } from '../../actions'

import { Container, Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { QuickStatusLayout, InnerTitleLayout } from '../../components/layout'
import { TicketView } from '../tickets'
import { AllVehicles } from '../parking'

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recentTicketsColumns: [
                {
                    dataField: 'customer',
                    text: 'Customer',
                    sort: true
                },
                {
                    dataField: 'equipment',
                    text: 'Equipment',
                    sort: true
                },
                {
                    dataField: 'required_by',
                    text: 'Required By'
                },
                {
                    dataField: 'action',
                    text: 'Action Required'
                },
                {
                    dataField: 'assignee',
                    text: 'Assignee'
                }
            ],
            recentTicketsData: [

            ]
        }
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("Dashboard"));
        if (this.props.user.scope > 0) {
            this.props.dispatch(configurationActions.fetchSummary(this.props.token, 1));
        }
    }

    render() {
        return (
            <div>
                <h1>Dashboard</h1>

                {this.props.user.scope > 0 &&
                    <Row>
                        <Col md={4}>
                            <QuickStatusLayout title={'Closed Tickets'} loading={this.props.summaryLoading} number={this.props.summary.closed} percentage={this.props.summary.closed_percentage} />
                        </Col>
                        <Col md={4}>
                            <QuickStatusLayout title={'Tickets In Progress'} loading={this.props.summaryLoading} number={this.props.summary.opened} percentage={this.props.summary.opened_percentage} />
                        </Col>
                        <Col md={4}>
                            <QuickStatusLayout title={'Parked Vehicles'} loading={this.props.summaryLoading} number={this.props.summary.parked} percentage={this.props.summary.parked_percentage} />
                        </Col>
                    </Row>
                }

                <Row>
                    {this.props.user.scope > 0 &&
                        <>
                            <Col lg={8}>
                                <InnerTitleLayout title={'Recent Tickets'}>
                                    <TicketView isQuickView={true} />
                                </InnerTitleLayout>
                            </Col>
                            <Col lg={4}>
                                <InnerTitleLayout title={'My Tickets'}>
                                    <TicketView isQuickView={true} onlyCurrentUser={true} />
                                </InnerTitleLayout>
                            </Col>
                        </>
                    }
                    {this.props.user.scope === 0 &&
                        <Col md={12}>
                            <InnerTitleLayout title={'My Tickets'}>
                                <TicketView isQuickView={true} onlyCurrentUser={true} />
                            </InnerTitleLayout>
                        </Col>
                    }
                </Row>

                <Row>
                    <Col md={12}>
                        <InnerTitleLayout title={'Parked Vehicles'}>
                            <AllVehicles isQuickView={true} parkedOnly={true} />
                        </InnerTitleLayout>
                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina,
    token: state.authentication.access,
    summaryLoading: state.configuration.loadingSummary,
    summary: state.configuration.summary,
    user: state.authentication.claims,
})

export default connect(mapStateToProps)(Home);
