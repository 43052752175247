import React from 'react'

const Footer = (props) => (
	<div />
)

const FooterReal = (props) => (
	<footer className="footer">
		<div className="container-fluid">
			<div className="row text-muted">
				<div className="col-6 text-left">
					© {new Date().getFullYear()} {props.marina}
				</div>
				<div className="col-6 text-right">
					<p className="mb-0">
						<span className="text-muted">Powered by MarinaMonkey</span>
					</p>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
