export const parkingConstants = {
    VEHICLES_REQUEST: 'VEHICLES_REQUEST',
    VEHICLES_SUCCESS: 'VEHICLES_SUCCESS',
    VEHICLES_FAILURE: 'VEHICLES_FAILURE',

    RECORDS_REQUEST: 'RECORDS_REQUEST',
    RECORDS_SUCCESS: 'RECORDS_SUCCESS',
    RECORDS_FAILURE: 'RECORDS_FAILURE',

    CREATE_RECORD_REQUEST: 'CREATE_RECORD_REQUEST',
    CREATE_RECORD_SUCCESS: 'CREATE_RECORD_SUCCESS',
    CREATE_RECORD_FAILURE: 'CREATE_RECORD_FAILURE',

    UPDATE_RECORD_REQUEST: 'UPDATE_RECORD_REQUEST',
    UPDATE_RECORD_SUCCESS: 'UPDATE_RECORD_SUCCESS',
    UPDATE_RECORD_FAILURE: 'UPDATE_RECORD_FAILURE',

    MARK_DEPARTED_REQUEST: 'MARK_DEPARTED_REQUEST',
    MARK_DEPARTED_SUCCESS: 'MARK_DEPARTED_SUCCESS',
    MARK_DEPARTED_FAILURE: 'MARK_DEPARTED_FAILURE',
};