import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, ticketActions } from '../../actions'
import * as Utils from '../../utils'
import { useNavigate } from "react-router-dom";

import SplitPane from 'react-split-pane';
import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout'
import { Table } from '../../components/tables'
import { Button, Form, FormGroup, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const TicketView = (props) => {
    const { onlyCurrentUser, user, forUser, token, marina, onlyUnsolved, isQuickView, increaseQuickViewCount, userTickets, tickets, isLoading, isLoadingUser } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let userArg = null;
        if (onlyCurrentUser) {
            userArg = user;

            if (forUser != null) {
                userArg = forUser.id;
            }
        }

        dispatch(ticketActions.listTickets(token, marina, 1, onlyUnsolved, userArg));
    }, [onlyCurrentUser, user, forUser, token, marina, onlyUnsolved, dispatch]);

    const onPageChange = (page, sizePerPage) => {
        let userArg = null;
        if (onlyCurrentUser) {
            userArg = user;

            if (forUser != null) {
                userArg = forUser.id;
            }
        }

        dispatch(ticketActions.listTickets(token, marina, page, onlyUnsolved, userArg));
    }

    const onTableChange = (type, { sortField, sortOrder, data }) => {
        var ordering = `${sortOrder == "asc" ? "" : "-"}${sortField}`;
        //dispatch(ticketActions.listTickets(token, marina, 1, onlyUnsolved, userArg));
    }

    const dateFormatter = (cell, row) => {
        return Utils.dateFormatter(row.required_by);
    }

    const headerStyle = (colum, colIndex) => {
        return { width: 90 };
    }

    const openTicket = (event) => {
        const ticketId = event.currentTarget.id;
        navigate('/tickets/' + ticketId);
    }

    const columns = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle
        },
        {
            dataField: 'customer',
            text: 'Requested By',
            sort: true
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true,
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'required_by',
            text: 'Required By',
            formatter: dateFormatter,
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    const columnsQuickView = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle
        },
        {
            dataField: 'customer',
            text: 'Requested By',
            sort: true
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    const columnsQuickViewUser = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    const columnsQuickViewUserExpanded = [
        {
            dataField: 'id',
            text: 'Ticket',
            sort: true,
            headerStyle: headerStyle
        },
        {
            dataField: 'equipment',
            text: 'Equipment',
            sort: true
        },
        {
            dataField: 'required_by',
            text: 'Required By',
            formatter: dateFormatter,
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'action',
            text: 'Action Required',
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    const renderData = () => {
        let data = [];
        for (let i = 0; i < (onlyCurrentUser ? userTickets.results.length : tickets.results.length); i++) {
            if (isQuickView) {
                if (increaseQuickViewCount) {
                    if (i >= 25) {
                        continue;
                    }
                } else {
                    if (i >= 4) {
                        continue;
                    }
                }
            }

            var ticket = onlyCurrentUser ? userTickets.results[i] : tickets.results[i];
            data.push({
                id: (
                    <Link to={'/tickets/' + ticket.id} style={{ color: '#000' }}>
                        <h4><span className={"badge badge-primary"} style={{ backgroundColor: (ticket.solved ? '#f00' : '#0cc2aa') }}>{"#" + ticket.id}</span></h4>
                    </Link>
                ),
                customer: (
                    <Link to={'/tickets/' + ticket.id} style={{ textDecoration: 'none', color: '#000' }}>
                        {/*<img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={ticket.requested_by.first_name + ' ' + ticket.requested_by.last_name} />*/}
                        <div style={{ paddingTop: 10 }}>
                            {ticket.requested_by.first_name + ' ' + ticket.requested_by.last_name}
                        </div>
                    </Link>
                ),
                equipment: ticket.equipment.name + ' (' + ticket.equipment.license + ')',
                required_by: ticket.date_required,
                action: ticket.action.action,
                actions: (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexWrap: 'wrap' }}>
                        {!isQuickView && document.documentElement.clientWidth > 500 && <Badge color="secondary" style={{ float: 'left' }}>{ticket.comments.length === 1 ? '1 comment' : ticket.comments.length + ' comments'}</Badge>
                        }
                        <Button id={ticket.id} color={'secondary'} type={'submit'} style={{ float: 'right', margin: 10}} onClick={openTicket}>
                            <FontAwesomeIcon id={ticket.id} onClick={openTicket} icon={faAngleRight} style={{ color: '#fff' }} />
                        </Button>
                    </div>
                )
            })
        }
        return data;
    }

    return (
        <div>
            {!isQuickView
                ? (
                    <Layout title={'Tickets'} noBorder={true}>
                        <InnerTitleLayout title={props.title} hideCard={true} actionType={'Ticket'} actionLink={'/tickets/new'}>
                            <Table
                                title={'tickets'}
                                keyField={'id'}
                                data={renderData()}
                                columns={isQuickView ? (onlyCurrentUser ? (increaseQuickViewCount ? columnsQuickViewUserExpanded : columnsQuickViewUser) : columnsQuickView) : columns}
                                pagination={onlyCurrentUser ? userTickets : tickets}
                                isLoading={onlyCurrentUser ? isLoadingUser : isLoading}
                                onPageChange={onPageChange}
                                onTableChange={onTableChange}
                                action={isQuickView ? "View all tickets" : null}
                                actionLink={'/tickets/all'} />
                        </InnerTitleLayout>
                    </Layout>
                )
                : <div>
                    <Table
                        title={'tickets'}
                        keyField={'id'}
                        data={renderData()}
                        columns={isQuickView ? (onlyCurrentUser ? (increaseQuickViewCount ? columnsQuickViewUserExpanded : columnsQuickViewUser) : columnsQuickView) : columns}
                        pagination={onlyCurrentUser ? userTickets : tickets}
                        isLoading={onlyCurrentUser ? isLoadingUser : isLoading}
                        onPageChange={onPageChange}
                        onTableChange={onTableChange}
                        action={isQuickView ? "View all tickets" : null}
                        actionLink={'/tickets/all'}
                        style={{ minHeight: 500 }}/>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.ticket.loadingTickets,
    isLoadingUser: state.ticket.loadingUserTickets,
    users: state.user.users,
    tickets: state.ticket.tickets,
    userTickets: state.ticket.userTickets,
    user: state.authentication.claims.user_id
})

export default connect(mapStateToProps)(TicketView);