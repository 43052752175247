// Location Reducer
const initialState = {
    path: '/',
}

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_PATHNAME':
            return {
                ...state,
                path: action.payload
            };
        default:
            return state;
    }
}

export default locationReducer;