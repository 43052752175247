export const inventoryConstants = {
    EQUIPMENT_LIST_REQUEST: 'EQUIPMENT_LIST_REQUEST',
    EQUIPMENT_LIST_SUCCESS: 'EQUIPMENT_LIST_SUCCESS',
    EQUIPMENT_LIST_FAILURE: 'EQUIPMENT_LIST_FAILURE',

    EQUIPMENT_CREATE_REQUEST: 'EQUIPMENT_CREATE_REQUEST',
    EQUIPMENT_CREATE_SUCCESS: 'EQUIPMENT_CREATE_SUCCESS',
    EQUIPMENT_CREATE_FAILURE: 'EQUIPMENT_CREATE_FAILURE',

    INVENTORY_LIST_REQUEST: 'INVENTORY_LIST_REQUEST',
    INVENTORY_LIST_SUCCESS: 'INVENTORY_LIST_SUCCESS',
    INVENTORY_LIST_FAILURE: 'INVENTORY_LIST_FAILURE',
};