export const authConstants = {
    REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

    LOGOUT: 'AUTH_LOGOUT',

    REFRESH_REQUEST: 'AUTH_REFRESH_REQUEST',
    REFRESH_SUCCESS: 'AUTH_REFRESH_SUCCESS',
    REFRESH_FAILURE: 'AUTH_REFRESH_FAILURE', 

    REQUEST_PASSWORD_RESET_REQUEST: 'AUTH_REQUEST_PASSWORD_RESET_REQUEST',
    REQUEST_PASSWORD_RESET_SUCCESS: 'AUTH_REQUEST_PASSWORD_RESET_SUCCESS',
    REQUEST_PASSWORD_RESET_FAILURE: 'AUTH_REQUEST_PASSWORD_RESET_FAILURE', 

    PASSWORD_RESET_REQUEST: 'AUTH_PASSWORD_RESET_REQUEST',
    PASSWORD_RESET_SUCCESS: 'AUTH_PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILURE: 'AUTH_PASSWORD_RESET_FAILURE', 
};