import React from 'react'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/async';
import { userActions } from '../../actions'

class CustomerSelect extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadOptions(inputValue, callback) {
      this.props.dispatch(userActions.listUsersWithFilter(this.props.token, this.props.marina, inputValue, this.props.staff)).then(() => callback(this.props.users))
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleChange = (selectedOption) => {
      this.props.onSelect(selectedOption);
    }

    render() {

        return (
            <AsyncSelect
              cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              placeholder={this.props.staff ? 'Search for a staff member' : 'Search for a customer'}
              noOptionsMessage={(inputText) => inputText === "" ? this.props.staff ? 'Search for a staff member' : 'Search for a customer' : this.props.staff ? 'No staff members found' : 'No customers found'}
              onChange={this.handleChange}
              isMulti={this.props.staff}
              isDisabled={this.props.isDisabled}
            />
        );

    }

}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.user.loadingUsers,
    users: state.user.usersSelect,
})

export default connect(mapStateToProps)(CustomerSelect);
