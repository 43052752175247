import { authConstants } from '../constants';
import { authApi } from '../api';
import { alertActions, configurationActions } from './';

export const authActions = {
    login,
    refresh,
    requestPasswordReset,
    resetPassword,
    logout,
};

function login(username, password, navigate) {
    return dispatch => {
        dispatch(request({ username }));

        authApi.login(username, password)
            .then(
                user => {
                    dispatch(configurationActions.fetchConfiguration(user.access));
                    dispatch(success(user));
                    navigate("/");
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error("Incorrect email or password, try again"));
                }
            );
    };

    function request(user) {
        return {
            type: authConstants.LOGIN_REQUEST,
            request: true,
            user
        }
    }

    function success(user) {
        return {
            type: authConstants.LOGIN_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: authConstants.LOGIN_FAILURE,
            error
        }
    }
}

function refresh(refresh) {
    return dispatch => {
        dispatch(request());

        authApi.refresh(refresh)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(configurationActions.fetchConfiguration(user.access));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) {
        return {
            type: authConstants.REFRESH_REQUEST,
            request: true
        }
    }

    function success(user) {
        return {
            type: authConstants.REFRESH_SUCCESS,
            user
        }
    }

    function failure(error) {
        return {
            type: authConstants.REFRESH_FAILURE,
            error
        }
    }
}

function requestPasswordReset(email, navigate) {
    return dispatch => {
        dispatch(request());

        authApi.requestPasswordReset(email)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("You've been sent an email with instructions to reset your password"));
                    navigate('/');
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {
            type: authConstants.REQUEST_PASSWORD_RESET_REQUEST
        }
    }

    function success() {
        return {
            type: authConstants.REQUEST_PASSWORD_RESET_SUCCESS
        }
    }

    function failure() {
        return {
            type: authConstants.REQUEST_PASSWORD_RESET_FAILURE
        }
    }
}

function resetPassword(password, token, navigate) {
    return dispatch => {
        dispatch(request());

        authApi.resetPassword(password, token)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success("Your password has been reset! Please login now"));
                    navigate('/');
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() {
        return {
            type: authConstants.PASSWORD_RESET_REQUEST
        }
    }

    function success() {
        return {
            type: authConstants.PASSWORD_RESET_SUCCESS
        }
    }

    function failure() {
        return {
            type: authConstants.PASSWORD_RESET_FAILURE
        }
    }
}

function logout() {
    authApi.logout();
    return { type: authConstants.LOGOUT };
}
