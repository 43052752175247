import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import Spinner from '../../components/spinner'
import { EmptySidebar } from '../../components/sidebar'
import { EmptyNavbar } from '../../components/navbar'

export default class Loading extends React.Component {

	render() {
		return (
			<>
				<div className={'main'}>
					<div className={'content'} style={{ position: 'relative', height: '95vh' }}>
						<div style={{ maxWidth: 800, minWidth: 400, minHeight: 200, margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
							<Spinner coloured={true} />
							<h3>{this.props.loadingText}</h3>
							{this.props.children}
						</div>
					</div>
				</div>
			</>
		)
	}
}
