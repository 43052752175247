import React from 'react'
import PropTypes from "prop-types";

class TextField extends React.Component {

    onChange(e) {
        var args = [e, e.target.value];
        if (this.props.onChangeArgs) {
          args = this.props.onChangeArgs(e);
        }
        this.props.onChange.apply(this, args);
      }

    render () {
        delete this.props.onChangeArgs;
        return (
            <input className={"form-control form-control-lg"} onChange={this.onChange.bind(this)} {...this.props} />
        )
    }
}

TextField.propTypes = {
    onChange: PropTypes.func,
    onChangeArgs: PropTypes.func
}

export default TextField;
