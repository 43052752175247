import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { configurationActions } from '../../actions'
import { useNavigate } from "react-router-dom";

const styles = {
	iconStyle: {
		width: 18,
		height: 18
	}
};

const CollapsableItem = ({ route, user, activeRoute }) => {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(!show);

	return (
		<li className={"sidebar-item"} key={route.path}>
			<a href={"#"} ata-bs-target="#ui" data-bs-toggle="collapse" className={`sidebar-link ${!show ? "collapsed" : ""}`} aria-expanded={show} onClick={handleShow}>
				<FontAwesomeIcon icon={route.icon} className={"align-middle"} style={styles.iconStyle} />
				<span className={"align-middle"}>{route.verbose}</span>
			</a>
			<ul id={route.verbose.replace("/", "")} className={`sidebar-dropdown list-unstyled collapse ${show ? "show" : ""}`}>
				{route.subroutes.filter(sr => !sr.hide && sr.scope <= user.scope).map(subroute => (
					<li className={activeRoute === subroute.path ? "sidebar-item collapse show" : "sidebar-item"} key={subroute.path}>
						<Link to={subroute.path} className={"sidebar-link"}>{subroute.verbose}</Link></li>
				))}
			</ul>
		</li>
	);
}

const MarinaChanger = ({ marinas, activeMarina, user, token, dispatch }) => {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(!show);
	const navigate = useNavigate();

	const changeMarina = (marinaId) => {
		console.log("MARINA TOKEN", token);
		dispatch(configurationActions.changeMarina(token, marinaId, navigate));
	}

	return (
		<li className={'sidebar-item-brand sidebar-item'}>

			{user.scope > 1
				?
				<div>
					<a href={"#"} ata-bs-target="#ui" data-bs-toggle="collapse" className={`sidebar-link ${!show ? "collapsed" : ""}`} aria-expanded={show} onClick={handleShow} style={{paddingLeft: "0px"}}>
						<span className={"sidebar-brand align-middle"}>{activeMarina}</span>
					</a>
					<ul id={""} className={`sidebar-dropdown list-unstyled collapse ${show ? "show" : ""}`}>
						{marinas.map(marina => (
							<li className={"sidebar-item"} key={marina.id}>
								<a href={'#'} className={'sidebar-link'} onClick={() => changeMarina(marina.id)}>
									<span>
										{marina.marina_name}
									</span>
								</a>
							</li>
						))}
					</ul>
				</div>
				:
				<a className={"sidebar-link sidebar-link-disabled collapsed"}>
					<span className={"sidebar-brand align-middle"}>{activeMarina}</span>
				</a>
			}
		</li>
	);

}

class Sidebar extends Component {

	constructor(props) {
		super(props);
	}

	render() {

		var routes = [];
		for (var i = 0; i < this.props.routes.length; i++) {

			var route = this.props.routes[i];
			if (route.hide || route.scope > this.props.user.scope) {
				continue;
			}

			if (route.subroutes.length === 0) {

				routes.push(
					<li className={this.props.activeRoute === route.path ? "sidebar-item" : "sidebar-item"} key={route.path}>
						<Link to={route.path} className={"sidebar-link"}>
							<FontAwesomeIcon icon={route.icon} className={"align-middle"} style={styles.iconStyle} />
							<span className={"align-middle"}>{route.verbose}</span>
						</Link>
					</li>
				);

			} else {
				routes.push(
					<CollapsableItem key={route.path + route.verbose} route={route} user={this.props.user} activeRoute={this.props.activeUser} />
				);
			}

		}

		return (
			<nav className={"sidebar" + (this.props.sidebarClass != "" ? " " + this.props.sidebarClass : "")}>
				<div className={"sidebar-content"}>
					<ul className={"sidebar-nav"}>
						<div className={'sidebar-main'}>
							<MarinaChanger marinas={this.props.marinas} activeMarina={this.props.marina} user={this.props.user} dispatch={this.props.dispatch} token={this.props.token} />
							<li className={"sidebar-header"}>Main</li>
							{routes.map(route => (
								route
							))}
						</div>
					</ul>
				</div>
			</nav>
		);
	}

}

const mapStateToProps = state => ({
	marinas: state.configuration.marinas,
	marina: state.configuration.activeMarina?.marina_name,
	loaded: !state.configuration.loadingOrganisation,
	activeRoute: state.location.path,
	user: state.authentication.claims,
	sidebarClass: state.configuration.sidebarClass,
	token: state.authentication.access,
});

export default connect(
	mapStateToProps,
)(Sidebar)