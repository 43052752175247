import { alertConstants } from '../constants';

export function alert(state = {creatingAlert: false}, action) {
  switch (action.type) {
    case alertConstants.PUSH_ALERT_REQUEST:
       return {
         ...state,
         creatingAlert: true
       }
    case alertConstants.PUSH_ALERT_SUCCESS:
       return {
         ...state,
         creatingAlert: false
       }
    case alertConstants.PUSH_ALERT_FAILURE:
       return {
         ...state,
         creatingAlert: true
       }
    default:
      return state
  }
}