export const configurationConstants = {
    CONFIGURATION_REQUEST: 'CONFIGURATION_REQUEST',
    CONFIGURATION_SUCCESS: 'CONFIGURATION_SUCCESS',
    CONFIGURATION_FAILURE: 'CONFIGURATION_FAILURE',

    SUMMARY_REQUEST: 'SUMMARY_REQUEST',
    SUMMARY_SUCCESS: 'SUMMARY_SUCCESS',
    SUMMARY_FAILURE: 'SUMMARY_FAILURE',

    CHANGE_MARINA: 'CONFIGURATION_CHANGE_MARINA',
    MARINA_CHANGED: 'CONFIGURATION_MARINA_CHANGED',
    CHANGE_PAGE_TITLE: 'CONFIGURATION_CHANGE_PAGE_TITLE',
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',

    SEARCH_REQUEST: 'SEARCH_REQUEST',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_FAILURE: 'SEARCH_FAILURE',

    CONFIGURATION_CONTENT_REQUEST: 'CONFIGURATION_CONTENT_REQUEST',
    CONFIGURATION_CONTENT_SUCCESS: 'CONFIGURATION_CONTENT_SUCCESS',
    CONFIGURATION_CONTENT_FAILURE: 'CONFIGURATION_CONTENT_FAILURE',

    DEPARTMENTS_REQUEST: 'DEPARTMENTS_REQUEST',
    DEPARTMENTS_SUCCESS: 'DEPARTMENTS_SUCCESS',
    DEPARTMENTS_FAILURE: 'DEPARTMENTS_FAILURE',

    CREATE_DEPARTMENT_REQUEST: 'CREATE_DEPARTMENT_REQUEST',
    CREATE_DEPARTMENT_SUCCESS: 'CREATE_DEPARTMENT_SUCCESS',
    CREATE_DEPARTMENT_FAILURE: 'CREATE_DEPARTMENT_FAILURE',
};