import React from 'react'
import Hamburger from './hamburger'
import Search from './search'
import UserCard from './userCard'
import { ReactComponent as Logo } from './../../vectors/dbw-logo.svg'
import { ReactComponent as Loading } from './../../vectors/loading-flipped.svg'

const EmptyNavbar = props => (

    <nav className={"navbar navbar-expand navbar-light bg-white"}>

        <div className={"navbar-collapse collapse"}>

            <ul className={"navbar-nav ml-auto"}>

                <Loading style={{ height: 30, color: 'lightgray' }} />

            </ul>

        </div>

    </nav>

)

const Navbar = props => (

    <nav className={"navbar navbar-expand navbar-light bg-white"}>

        <Hamburger />

        <Search />

        <div className={"navbar-collapse collapse"}>
            <ul className={"navbar-nav navbar-align"}>
                <Logo />
            </ul>
        </div>

        <div className={"navbar-collapse collapse"}>
            <ul className={"navbar-nav navbar-align"}>
                <UserCard />
            </ul>
        </div>
    </nav>

)

export {
    Navbar,
    EmptyNavbar
}