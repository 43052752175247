import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { configurationActions, ticketActions } from '../../actions';

import { InnerTitleLayout, Layout } from '../../components/layout';
import TextField from './../../components/textfield';
import Spinner from '../../components/spinner';
import { Table } from '../../components/tables';
import { GenericSelect } from '../../components/select';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    Form,
    FormGroup,
    Button,
    Modal,
    ModalHeader,
    ModalBody
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const rowStyle = (row, rowIndex) => {
    const style = {
        paddingLeft: 2
    };
    if (rowIndex % 2) {
        style.backgroundColor = '#f8f9fa';
    } else {
        style.backgroundColor = '#fff';
    }
    return style;
};

const Configuration = (props) => {
    const [actionModal, setActionModal] = useState(false);
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState(-1);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        props.dispatch(configurationActions.changePageTitle('Configuration'));
        props.dispatch(ticketActions.listCategories(props.token, props.marina));
    }, []);

    useEffect(() => {
        props.dispatch(configurationActions.fetchDepartments(props.token, props.marina, 1));
    }, [props.marina]);

    const toggleSourced = (event) => {
        toggle(event.target.value, false);
    }

    const toggle = (uid = -1, close = true) => {
        if (close === true) {
            setActionModal(false);
        } else {
            setActionModal(true);
            setSelectedAction(uid);
            setActions(props.categories.results.find((r) => r.id === parseInt(uid)).actions);
        }
    };

    const createAction = (category, action) => {
        props.dispatch(ticketActions.createAction(props.token, props.marina, category, action));
    }

    const updateAction = (category, action, edit) => {
        props.dispatch(ticketActions.updateAction(props.token, props.marina, category, action, edit));
    }

    const handleChangeSelectDepartment = (option, id) => {
        props.dispatch(ticketActions.updateCategory(props.token, id, option.value, navigate));
    };

    const onPageChange = (page, sizePerPage) => {
        props.dispatch(ticketActions.listCategories(props.token, page));
    }

    const multilineCell = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {cell.split("\n").map((action, index) => {
                    if (index !== 0) {
                        return (
                            <>
                                {action}
                                <br />
                            </>
                        )
                    }
                })}
                <Button value={cell.split("\n")[0]} onClick={formatExtraData}>Modify Actions</Button>
            </div>
        )
    }

    const columns = [
        {
            dataField: 'category',
            text: 'Action Category',
        },
        {
            dataField: 'department',
            text: 'Departments',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: multilineCell,
            formatExtraData: toggleSourced,
        },
    ];

    useEffect(() => {
        const updatedData = props.categories.results.map((category) => {
            const id = category.id;
            let actions = `${id}\n`;
            category.actions.forEach((action) => {
                actions += `${action.action}\n`;
            });


            return {
                category: category.category,
                actions,
                department: (
                    <GenericSelect
                        options={props.departments}
                        placeholder="Search for a department"
                        notFound="No departments"
                        id={id}
                        onSelect={handleChangeSelectDepartment}
                        value={props.departments.find((x) => x.value === category.department)}
                    />
                ),
            };
        });

        setData(updatedData);
    }, [props.departments, props.categories]);


    return (
        <Layout title="Configuration" hideCard={true}>
            <InnerTitleLayout title="Ticket Actions" actionType="Action Category" actionLink="/marina/configuration/category">
                <Table
                    title="categories"
                    keyField="category"
                    data={data}
                    columns={columns}
                    pagination={props.categories}
                    isLoading={props.isLoading}
                    onPageChange={onPageChange}
                />
                <ActionModal open={actionModal} toggle={toggle} category={selectedAction} actions={actions} createAction={createAction} updateAction={updateAction} />
            </InnerTitleLayout>
        </Layout>
    );
};

const ActionModal = (props) => {
    const [newActions, setNewActions] = useState([{}]);
    const [toDelete, setToDelete] = useState([]);
    const [state, setState] = useState({});

    useEffect(() => {
        const setToChange = {};
        props.actions.forEach((action) => {
            setToChange[action.id] = action.action;
        });
        setState(setToChange);
    }, [props.actions]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const deleteKey = (event) => {
        const value = event.target.value;
        const updatedToDelete = value.indexOf('new') !== -1 ? [...toDelete, value] : [...toDelete, parseInt(value)];
        setToDelete(updatedToDelete);
    };

    const addKey = () => {
        setNewActions((prevActions) => [...prevActions, {}]);
    };

    const submitChanges = () => {
        props.actions.forEach((action) => {
            if (action.action !== state[action.id]) {
                props.updateAction(props.category, action.id, state[action.id]);
            }
        });

        for (let i = 0; i < newActions.length; i++) {
            if (state[`new.${i}`] !== undefined) {
                props.createAction(props.category, state[`new.${i}`]);
            }
        }

        props.toggle();
    };

    return (
        <Modal isOpen={props.open} toggle={props.toggle} size="lg" centered>
            <ModalHeader toggle={props.toggle}>Actions</ModalHeader>
            <ModalBody>
                {props.isLoading ? (
                    'Loading...'
                ) : (
                    <>
                        <Form>
                            {props.actions.map((action, index) => (
                                <FormGroup key={action.id}>
                                    <label>{`Action #${index + 1}`}</label>
                                    <TextField name={action.id} value={state[action.id]} disabled={toDelete.includes(action.id)} onChange={handleChange} />
                                    {/* <Button value={action.id} onClick={deleteKey} disabled={toDelete.includes(action.id)} style={{ backgroundColor: 'red', margin: 5 }}>Delete</Button> */}
                                </FormGroup>
                            ))}
                            {newActions.map((action, index) => (
                                <FormGroup key={`new.${index}`}>
                                    <label>{`New Action`}</label>
                                    <TextField name={`new.${index}`} value={state[`new.${index}`]} disabled={toDelete.includes(`new.${index}`)} onChange={handleChange} />
                                    {/* <Button value={`new.${index}`} onClick={deleteKey} disabled={toDelete.includes(`new.${index}`)} style={{ backgroundColor: 'red', margin: 5 }}>Delete</Button> */}
                                </FormGroup>
                            ))}
                            <Button onClick={addKey}>{"Add New"}</Button>
                            <Button style={{ float: 'right' }} onClick={submitChanges}>{"Save Changes"}</Button>
                        </Form>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    categories: state.ticket.categories,
    isLoading: state.ticket.loadingCategories,
    departments: state.configuration.content ? sortOptions(state.configuration.content.departments) : [],
});

function sortOptions(results) {
    const options = [];
    for (let i = 0; i < results.length; i++) {
        options.push({
            value: results[i].id,
            label: results[i].department,
        });
    }
    return options;
}

export default connect(mapStateToProps)(Configuration);
