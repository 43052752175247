import jwt_decode from 'jwt-decode'
import { config } from './'

export const authApi = {
    login,
    refresh,
    requestPasswordReset,
    resetPassword,
    logout
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${config.url}${config.endpoint.login}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.access) {
                user.claims = jwt_decode(user.access);
                localStorage.setItem('refresh', JSON.stringify(user.refresh));
            }
            return user;
        });
}

function refresh(refresh) {

    if (new Date().getTime().valueOf() / 1000 > jwt_decode(refresh).exp) {
        logout();
        return Promise.reject("Expired refresh token");
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          refresh
        })
    };

    return fetch(`${config.url}${config.endpoint.refresh}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.access) {
                user.claims = jwt_decode(user.access);
                localStorage.setItem('refresh', JSON.stringify(user.refresh));
            }
            return user;
        });
}

function requestPasswordReset(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(`${config.url}${config.endpoint.requestPasswordReset}`, requestOptions)
        //.then(handleResponse)
        .then(status => {
            return status;
        });
}

function resetPassword(password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, token })
    };

    return fetch(`${config.url}${config.endpoint.passwordReset}`, requestOptions)
        //.then(handleResponse)
        .then(status => {
            return status;
        });
}

function logout() {
    localStorage.removeItem('refresh');
    localStorage.removeItem('activeMarinaId');
    window.location.reload(true);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
