import jwt_decode from 'jwt-decode';
import { config } from '../api';
import { authActions } from '../actions';
import store from '../store';
import { authConstants } from '../constants';

export const requestMiddleware = store => next => action => {
    const authentication = store?.getState().authentication;
    
    if (shouldRefreshToken(authentication, action)) {
        return refreshTokenAndDispatch(action, next);
    }

    if (typeof action.request === "function") {
        action.request(authentication);
    }

    return next(action);
};

const shouldRefreshToken = (authentication, action) => {
    return action.request && authentication.loggedIn && authentication.access && isTokenExpired(authentication.access);
};

const isTokenExpired = token => {
    return new Date().getTime().valueOf() / 1000 >= jwt_decode(token).exp;
};

const refreshTokenAndDispatch = (action, next) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            refresh: JSON.parse(localStorage.getItem('refresh'))
        })
    };
    
    return fetch(`${config.url}${config.endpoint.refresh}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.access) {
                user.claims = jwt_decode(user.access);
                localStorage.setItem('refresh', JSON.stringify(user.refresh));
                store.dispatch({ type: authConstants.LOGIN_SUCCESS, payload: user });
                if (typeof action.request === "function") {
                    action.request(user);
                }
            }
        });
};

const handleResponse = response => {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(authActions.logout());
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
};
