import { alertActions } from './'
import { ticketConstants } from '../constants';
import { ticketApi } from '../api'
import { history } from '../store';
import { push } from 'connected-react-router'

export const ticketActions = {
    createCategory,
    listCategories,
    createTicket,
    listTickets,
    getTicket,
    createComment,
    markSolved,
    createAction,
    updateAction,
    updateCategory
};

function createCategory(token, category, marina_id, navigate) {
    return dispatch => {
        dispatch(request());

        ticketApi.createCategory(token, category, marina_id)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/marina/configuration');
                    dispatch(alertActions.success("New category created!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_CATEGORY_CREATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_CATEGORY_CREATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_CATEGORY_CREATE_FAILURE, error } }
}

function updateCategory(token, category, department, navigate) {
    return dispatch => {
        dispatch(request());

        ticketApi.updateCategory(token, category, department)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/marina/configuration');
                    dispatch(alertActions.success("Category updated!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_CATEGORY_CREATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_CATEGORY_CREATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_CATEGORY_CREATE_FAILURE, error } }
}

function createAction(token, marina, category, action) {
    return dispatch => {
        dispatch(request());

        ticketApi.createAction(token, marina, category, action)
            .then(
                result => { 
                    dispatch(success());
                    dispatch(alertActions.success("New action created!"));
                    dispatch(listCategories(token, marina));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_ACTION_CREATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_ACTION_CREATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_ACTION_CREATE_FAILURE, error } }
}

function updateAction(token, marina, category, action, edit) {
    return dispatch => {
        dispatch(request());

        ticketApi.updateAction(token, marina, category, action, edit)
            .then(
                result => { 
                    dispatch(success());
                    dispatch(alertActions.success("Action updated!"));
                    dispatch(listCategories(token, 1));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_ACTION_UPDATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_ACTION_UPDATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_ACTION_UPDATE_FAILURE, error } }
}

function listCategories(token, marina) {
    return dispatch => {
        dispatch(request());

        var list = ticketApi.listCategories(token, marina)
            .then(
                categories => { 
                    dispatch(success(categories));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
        return list;
    };

    function request() { return { type: ticketConstants.TICKET_ACTIONS_REQUEST } }
    function success(categories) {

        var categoriesSelect = [];
        var optionsSelect = [];
        for (var i = 0; i < categories.results.length; i++) {
            var category = categories.results[i];
            categoriesSelect.push({
              value: category.id,
              label: category.category
            });

            for (var x = 0; x < category.actions.length; x++) {
              optionsSelect.push({
                value:  category.actions[x].id,
                label: category.actions[x].action,
                category:  category.id
              });
            }
        }

        return { type: ticketConstants.TICKET_ACTIONS_SUCCESS, categories, categoriesSelect, optionsSelect }
    }
    function failure(error) { return { type: ticketConstants.TICKET_ACTIONS_FAILURE, error } }
}

function createTicket(token, marina, customer, equipment, assignedTo, isUrgent, action, comment, dateRequired, navigate) {
    return dispatch => {
        dispatch(request());

        ticketApi.createTicket(token, marina, customer, equipment, assignedTo, isUrgent, action, comment, dateRequired)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/tickets');
                    dispatch(alertActions.success("New ticket submitted! You'll get a notification when it's reviewed."));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_CREATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_CREATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_CREATE_FAILURE, error } }
}

function createComment(token, user, isStaffNote, comment, ticket, marina) {
    return dispatch => {
        dispatch(request());

        ticketApi.createComment(token, user, isStaffNote, comment, ticket)
            .then(
                result => { 
                    dispatch(success());
                    dispatch(getTicket(token, ticket, marina));
                    dispatch(alertActions.success("Message sent! You'll get a notification when it's reviewed."));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_COMMENT_CREATE_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_COMMENT_CREATE_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_COMMENT_CREATE_FAILURE, error } }
}

function listTickets(token, marina, page, onlyUnsolved=false, user=null, filter) {
    return dispatch => {
        dispatch(!user ? request() : requestUser());

        ticketApi.listTickets(token, marina, page, onlyUnsolved, user, filter)
            .then(
                tickets => { 
                    dispatch(!user ? success(tickets) : successUser(tickets));
                },
                error => {
                    dispatch(!user ? failure(error.toString()) : failureUser(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKETS_REQUEST } }
    function success(tickets) { return { type: ticketConstants.TICKETS_SUCCESS, tickets } }
    function failure(error) { return { type: ticketConstants.TICKETS_FAILURE, error } }

    function requestUser() { return { type: ticketConstants.TICKETS_USER_REQUEST } }
    function successUser(tickets) { return { type: ticketConstants.TICKETS_USER_SUCCESS, tickets } }
    function failureUser(error) { return { type: ticketConstants.TICKETS_USER_FAILURE, error } }
}

function getTicket(token, ticket, marina, navigate) {
    return dispatch => {
        dispatch(request());

        ticketApi.getTicket(token, ticket, marina)
            .then(
                returnedTicket => { 
                    dispatch(success(returnedTicket));
                },
                error => {
                    dispatch(failure(error.toString()));
                    navigate('/tickets')
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.GET_TICKET_REQUEST } }
    function success(returnedTicket) { return { type: ticketConstants.GET_TICKET_SUCCESS, returnedTicket } }
    function failure(error) { return { type: ticketConstants.GET_TICKET_FAILURE, error } }
}

function markSolved(token, ticketId, navigate) {
    return dispatch => {
        dispatch(request());

        ticketApi.markSolved(token, ticketId)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/tickets')
                    dispatch(alertActions.success("Ticket marked as solved"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: ticketConstants.TICKET_SOLVED_REQUEST } }
    function success() { return { type: ticketConstants.TICKET_SOLVED_SUCCESS } }
    function failure(error) { return { type: ticketConstants.TICKET_SOLVED_FAILURE, error } }
}
