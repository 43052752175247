export const ticketConstants = {
	TICKET_CATEGORY_CREATE_REQUEST: 'TICKET_CATEGORY_CREATE_REQUEST',
    TICKET_CATEGORY_CREATE_SUCCESS: 'TICKET_CATEGORY_CREATE_SUCCESS',
    TICKET_CATEGORY_CREATE_FAILURE: 'TICKET_CATEGORY_CREATE_FAILURE',

    TICKET_ACTION_CREATE_REQUEST: 'TICKET_ACTION_CREATE_REQUEST',
    TICKET_ACTION_CREATE_SUCCESS: 'TICKET_ACTION_CREATE_SUCCESS',
    TICKET_ACTION_CREATE_FAILURE: 'TICKET_ACTION_CREATE_FAILURE',

    TICKET_ACTION_UPDATE_REQUEST: 'TICKET_ACTION_UPDATE_REQUEST',
    TICKET_ACTION_UPDATE_SUCCESS: 'TICKET_ACTION_UPDATE_SUCCESS',
    TICKET_ACTION_UPDATE_FAILURE: 'TICKET_ACTION_UPDATE_FAILURE',

    TICKET_ACTIONS_REQUEST: 'TICKET_ACTIONS_REQUEST',
    TICKET_ACTIONS_SUCCESS: 'TICKET_ACTIONS_SUCCESS',
    TICKET_ACTIONS_FAILURE: 'TICKET_ACTIONS_FAILURE',

    TICKET_CREATE_REQUEST: 'TICKET_CREATE_REQUEST',
    TICKET_CREATE_SUCCESS: 'TICKET_CREATE_SUCCESS',
    TICKET_CREATE_FAILURE: 'TICKET_CREATE_FAILURE',

    TICKET_COMMENT_CREATE_REQUEST: 'TICKET_COMMENT_CREATE_REQUEST',
    TICKET_COMMENT_CREATE_SUCCESS: 'TICKET_COMMENT_CREATE_SUCCESS',
    TICKET_COMMENT_CREATE_FAILURE: 'TICKET_COMMENT_CREATE_FAILURE',

    TICKET_SOLVED_REQUEST: 'TICKET_SOLVED_REQUEST',
    TICKET_SOLVED_SUCCESS: 'TICKET_SOLVED_SUCCESS',
    TICKET_SOLVED_FAILURE: 'TICKET_SOLVED_FAILURE',

    TICKETS_REQUEST: 'TICKETS_REQUEST',
    TICKETS_SUCCESS: 'TICKETS_SUCCESS',
    TICKETS_FAILURE: 'TICKETS_FAILURE',

    TICKETS_USER_REQUEST: 'TICKETS_USER_REQUEST',
    TICKETS_USER_SUCCESS: 'TICKETS_USER_SUCCESS',
    TICKETS_USER_FAILURE: 'TICKETS_USER_FAILURE',

    GET_TICKET_REQUEST: 'GET_TICKET_REQUEST',
    GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
    GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',
};