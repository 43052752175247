import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { authActions } from './../../actions';

const styles = {
	icon: {
		fill: 'transparent',
		marginRight: 5,
		width: 18,
		height: 18
	}
};

const UserCard = (props) => {
	const [show, setShow] = useState(false);

	const handleShow = () => setShow(!show);

	return (
		<li className={"nav-item dropdown"}>

			<a className={`nav-link dropdown-toggle ${show ? "show" : ""}`} href={"#"} onClick={handleShow}>
				<img src="/img/avatar.jpg" className="avatar img-fluid rounded-circle me-1" alt={props.firstName} />
				<span className="text-dark">Hello, {props.firstName}</span>
			</a>

			<div className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`} aria-labelledby={"userDropdown"}>

				<Link to={"/account/profile"} className={"dropdown-item"}>
					<FontAwesomeIcon icon={faUser} className={"align-middle"} style={styles.icon} size={'2x'} /> Profile
				</Link>

				<div className={"dropdown-divider"} />

				<a className={"dropdown-item"} href={"#"} onClick={props.onLogout}>Sign out</a>

			</div>

		</li>
	);
}

const mapStateToProps = ({ authentication }) => ({
	firstName: authentication.claims.first_name,
	email: authentication.claims.email
})

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: (id) => {
			dispatch(authActions.logout())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserCard)