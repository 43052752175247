import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Table } from '../../components/tables';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const VehicleView = (props) => {
  const navigate = useNavigate();
  const columns = [
    {
      dataField: 'license_plate',
      text: 'License Plate',
      sort: true,
    },
    {
      dataField: 'make_model',
      text: 'Model',
    },
    {
      dataField: 'colour',
      text: 'Colour',
      hidden: document.documentElement.clientWidth < 500,
    },
    {
      dataField: 'province',
      text: 'Province',
      hidden: document.documentElement.clientWidth < 500,
    },
    {
      dataField: 'customer',
      text: 'Customer',
    },
    {
      dataField: 'is_checked_in',
      text: 'Checked In?',
      headerAlign: (column, colIndex) => 'right',
      hidden: document.documentElement.clientWidth < 500 || props.isQuickView,
    },
  ];

  var data = [];
  for (var i = 0; i < props.vehicles.results.length; i++) {
    var vehicle = props.vehicles.results[i];
    data.push({
      license_plate: vehicle.license_plate,
      make_model: `${vehicle.make} ${vehicle.model}`,
      customer: `${vehicle.customer.first_name} ${vehicle.customer.last_name}`,
      province: vehicle.province,
      colour: vehicle.colour,
      is_checked_in: <input type={'checkbox'} name={'isCheckedIn'} value={vehicle.is_checked_in} disabled={true} style={{ float: 'right' }} />,
      actions: (
        <div style={{}}>
          <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} onClick={() => navigate('/account/profile?userId=' + vehicle.id)}>
            <FontAwesomeIcon icon={faEdit} style={{ color: '#fff' }} />
          </Button>
        </div>
      ),
    });
  }

  return (
    <Table
      title={'vehicles'}
      keyField={'license_plate'}
      data={data}
      columns={columns}
      pagination={props.vehicles}
      isLoading={props.isLoading}
      onPageChange={props.onPageChange}
    />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(VehicleView);
