import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { configurationActions } from './../../actions';
import { throttle } from 'throttle-debounce';
import { useNavigate } from 'react-router-dom';

const Search = ({ user, marina, token, dispatch }) => {
	const navigate = useNavigate();

	const handleSearch = useCallback(
		throttle(1000, (value) => {
			if (value.length > 1 || value === '') {
				dispatch(configurationActions.search(token, marina, value, navigate));
			}
		}),
		[dispatch, marina, token]
	);

	const handleChange = (e) => {
		const { value } = e.target;
		handleSearch(value);
	};

	useEffect(() => {
		dispatch(configurationActions.changePageTitle('Search'));
	}, [dispatch]);

	if (user.scope === 0) {
		return null;
	}

	return (
		<form className="form-inline d-none d-sm-inline-block">
			<input
				className="form-control mr-sm-2"
				type="text"
				placeholder="Search"
				aria-label="Search"
				onChange={handleChange}
			/>
		</form>
	);
};

const mapStateToProps = (state) => ({
	marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
	token: state.authentication.access,
	user: state.authentication.claims,
});

export default connect(mapStateToProps)(Search);
