import React from 'react'
import { connect } from 'react-redux'
import { configurationActions } from '../../actions'

import { Container, Col, Row, Button, FormGroup, Label, Badge } from 'reactstrap';
import { Layout } from '../../components/layout'
import { Card } from '../../components/card'
import BootstrapTable from 'react-bootstrap-table-next';
import { TicketView } from './'


class Tickets extends React.Component {
    
    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("Tickets"));
    }

    render() {
        return (
            <div>
                <TicketView title={"All Tickets"} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.authentication.claims,
    marinas: state.configuration.marinas
})

export default connect(mapStateToProps)(Tickets);
