import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, parkingActions } from '../../actions'

import { VehiclesView } from './'
import { Layout, InnerTitleLayout } from '../../components/layout'

class AllVehicleView extends React.Component {
    
    constructor(props) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);    
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("All Vehicles"));
        this.props.dispatch(parkingActions.listVehicles(this.props.token, this.props.marina, 1, this.props.parkedOnly));
    }

    onPageChange = (page, sizePerPage) => {
        this.props.dispatch(parkingActions.listVehicles(this.props.token, this.props.marina, page, this.props.parkedOnly));
    }

    render() {
        if (this.props.isQuickView) {
            return <VehiclesView isLoading={this.props.isLoading} pagination={this.props.vehicles} onPageChange={this.onPageChange} vehicles={this.props.vehicles} />
        } else {
            return (
                <Layout title={'Parking'} noBorder={true}>
                  <InnerTitleLayout title={"All Vehicles"} hideCard={true}>
                    <VehiclesView isLoading={this.props.isLoading} pagination={this.props.vehicles} onPageChange={this.onPageChange} vehicles={this.props.vehicles} />
                  </InnerTitleLayout>
                </Layout>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    user: state.authentication.claims.user_id,
    isLoading: state.parking.loadingVehicles,
    vehicles: state.parking.vehicles
})

export default connect(mapStateToProps)(AllVehicleView);
