import React from 'react'
import { connect } from 'react-redux'
import { configurationActions } from '../../actions'


class Error extends React.Component {
	
	componentDidMount() {
		this.props.dispatch(configurationActions.changePageTitle("404 Not Found"));
	}

	render() {
		return (
			<h1>404 not found</h1>
		)
	}
}

const mapStateToProps = (state) => ({
	
})

export default connect(mapStateToProps)(Error);
