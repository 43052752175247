import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { configurationActions, userActions } from '../../actions'
import { useNavigate } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout'
import { Table } from '../../components/tables'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons';

function UserView({ scope }) {
    const [commentsModal, setCommentsModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const { token, marina, users, isLoading } = useSelector(state => ({
        token: state.authentication.access,
        marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
        users: state.user.users,
        isLoading: state.user.loadingUsers
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(configurationActions.changePageTitle("Staff"));
        dispatch(userActions.listUsers(token, marina, scope, 1));
    }, [dispatch, token, marina, scope]);

    const onPageChange = (page, sizePerPage) => {
        dispatch(userActions.listUsers(token, marina, scope, page));
    }

    const openProfile = (event) => {
        const userId = event.currentTarget.id;
        navigate('/account/profile?userId=' + userId);
    }

    const toggle = (event) => {
        if (event.currentTarget.id === -1) {
            setCommentsModal(prevCommentsModal => !prevCommentsModal);
        } else {
            setCommentsModal(prevCommentsModal => !prevCommentsModal);
            setSelectedUser(users.results.find(u => u.id == event.currentTarget.id));
        }
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name'
        },
        {
            dataField: 'email',
            text: 'Email',
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    var data = [];
    for (var i = 0; i < users.results.length; i++) {
        var user = users.results[i];
        data.push({
            name: (
                <Link to={'/account/profile?userId=' + user.id} style={{ textDecoration: 'none', color: '#000', display: 'flex', alignItems: 'center' }}>
                    <img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={user.first_name + " " + user.last_name} />
                    <div style={{ marginLeft: 10 }}>
                        {user.first_name + " " + user.last_name}
                        <span className={"badge " + (user.profile.scope >= 1 ? "badge-secondary" : "badge-primary")}>{user.profile.scope >= 1 ? "Staff" : "Customer"}</span>
                    </div>
                </Link>

            ),
            email: user.email,
            actions: (
                <div style={{}}>
                    <Button color={'secondary'} id={user.id} type={'submit'} style={{ float: 'right', marginLeft: 5 }} onClick={openProfile}>
                        <FontAwesomeIcon id={user.id} icon={faEdit} style={{ color: '#fff' }} />
                    </Button>
                    <Button id={user.id} color={'secondary'} style={{ float: 'right' }} disabled={user.comments.length === 0} onClick={toggle}>{user.comments.length} {user.comments.length === 1 ? "Comment" : "Comments"}</Button>
                </div>
            )
        })
    }

    return (
        <div>
            <Table
                title={"Staff"}
                keyField={'category'}
                data={data}
                columns={columns}
                pagination={users}
                isLoading={isLoading}
                onPageChange={onPageChange} />
            <CommentsModal open={commentsModal} toggle={toggle} user={selectedUser} />
        </div>
    )
}

const CommentsModal = ({ user, open, toggle }) => {

    const dateFormatter = (cell, row) => {
        var date = new Date(Date.parse(row.modified));
        return (
            <span>{date.toLocaleDateString('en-GB')}</span>
        );
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2) {
            style.backgroundColor = "#f8f9fa";
        } else {
            style.backgroundColor = "#fff";
        }
        return style;
    };

    if (!user) {
        return <></>;
    }

    const columns = [
        {
            dataField: 'comment',
            text: 'Comment'
        },
        {
            dataField: 'added_by',
            text: 'Added By',
        },
        {
            dataField: 'modified',
            text: 'Added On',
            formatter: dateFormatter
        }
    ];

    var data = user.comments;

    return (
        <Modal isOpen={open} toggle={toggle} size={'lg'} centered={true}>
            <ModalHeader toggle={toggle}>Comments for {user.first_name + " " + user.last_name}</ModalHeader>
            <ModalBody>
                <BootstrapTable
                    keyField={'comment'}
                    data={data}
                    columns={columns}
                    rowStyle={rowStyle}
                    bordered={false}
                    noDataIndication={"No Comments"}
                />
            </ModalBody>
        </Modal>
    );
}


const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.user.loadingUsers,
    users: state.user.users
})

export default connect(mapStateToProps)(UserView);
