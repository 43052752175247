import React from 'react'
import { connect } from 'react-redux'
import { configurationActions, alertActions, inventoryActions } from '../../actions'

import { Container, Col, Row, Button, FormGroup, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import { CustomerSelect } from '../../components/select'
import { Table } from '../../components/tables'
import { Link } from 'react-router-dom'
import { data } from 'jquery';

class EquipmentShareModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comment: null,
            selectedCustomer: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCustomer = this.handleChangeCustomer.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeCustomer(customer) {
        var custId = customer.value;
        this.setState({ selectedCustomer: custId })
    }

    handleSubmit(e) {
        e.preventDefault();

        const { selectedCustomer } = this.state;
        if (selectedCustomer === null) {
            this.props.dispatch(alertActions.error("No customer selected"));
            return;
        }

        if (selectedCustomer) {
            this.props.dispatch(inventoryActions.addUserToEquipment(this.props.token, this.props.equipmentId, selectedCustomer));
            this.props.close();
        }
    }

    render() {
        const userColumns = [
            {
                dataField: 'data',
                text: 'Account',
            },
        ];

        const userData = [];
        for (var i = 0; i <= this.props.familyMembers.length; i++) {
            if (i == this.props.familyMembers.length) {
                userData.push({
                    data: (
                        <>
                            <FormGroup>
                                <label>Add a new customer:</label>
                                <CustomerSelect onSelect={this.handleChangeCustomer} />
                            </FormGroup>
                        </>
                    )
                });
            } else {
                var user = this.props.familyMembers[i];
                userData.push({
                    data: (
                        <div>
                            {/* <Link to={'/account/profile?userId=' + user.id} style={{ textDecoration: 'none', color: '#000' }}> */}
                                <img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={user.first_name + " " + user.last_name} />
                                <div style={{ paddingTop: 10 }}>
                                    {user.first_name + " " + user.last_name}
                                    <h4><span className={"badge " + "badge-primary"}>{"Family Member"}</span></h4>
                                </div>
                            {/* </Link> */}
                        </div>
                    )
                });
            }
        }

        return (
            <Modal isOpen={this.props.open} toggle={this.props.close} size={'lg'} centered={true} >
                <ModalHeader toggle={this.props.close}><h5>Users with access to this equipment</h5></ModalHeader>
                <Form onSubmit={this.handleSubmit}>
                    <ModalBody>

                        <Table
                            title={'Users'}
                            keyField={'data'}
                            data={userData}
                            columns={userColumns}
                            isLoading={false}
                            pagination={{
                                "count": 1,
                                "page": 1,
                                "size_per_page": 50
                            }}
                            onPageChange={() => { }}
                            hidePageCount={true} />

                    </ModalBody>
                    <ModalFooter>
                        {this.state.selectedCustomer != null ? (
                            <Button color="primary" type={'submit'} onClick={this.handleSubmit}>
                                <FontAwesomeIcon icon={faPlus} />&nbsp;Add New User
                            </Button>
                        ) : (
                            <Button color="primary" type={'submit'} onClick={this.toggle}>
                                <FontAwesomeIcon icon={faClose} />&nbsp;Close
                            </Button>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
    token: state.authentication.access,
})

export default connect(mapStateToProps)(EquipmentShareModal);
