import React from 'react';
import { createRoot } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import App from './containers/app';
import './components/appstack/';
import 'sanitize.css/sanitize.css';
import './index.css';
import './scss/_app.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const target = document.querySelector('#root');

store.subscribe(() => {
  const state = store.getState();
  const page = state.location.path;
  localStorage.setItem('pathname', page);
});

createRoot(target).render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);