import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, userActions, parkingActions } from '../../actions'
import { history } from '../../store';

import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout'
import TextField from '../../components/textfield'
import { Table } from '../../components/tables'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { throttle } from 'throttle-debounce';
import { useNavigate } from "react-router-dom";

const RegisterNewVehicle = (props) => {
    const [commentsModal, setCommentsModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        props.dispatch(configurationActions.changePageTitle("Register Vehicle"));
        props.dispatch(userActions.listUsers(props.token, props.marina, props.scope, 1));
    }, []);

    const onPageChange = (page, sizePerPage) => {
        props.dispatch(userActions.listUsers(props.token, props.marina, props.scope, page));
    };

    const search = (e) => {
        const { value } = e.target;
        throttleSearch(value);
    };

    const handleSearch = (value) => {
        if (value.length > 1 || value === "") {
            props.dispatch(userActions.listUsers(props.token, props.marina, props.scope, 1, value));
        }
    };

    const throttleSearch = (delay, fn) => {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall < delay) {
                clearTimeout(this.timer);
                this.timer = setTimeout(function () {
                    lastCall = now;
                    fn(...args);
                }, delay - (now - lastCall));
            } else {
                lastCall = now;
                fn(...args);
            }
        };
    };

    const toggleSourced = (event) => {
        toggle(event.target.value, false);
    };

    const toggle = (uid = -1, close = true) => {
        if (close === true) {
            setCommentsModal(false);
        } else {
            props.dispatch(parkingActions.listVehicles(props.token, props.marina, 1, false, uid));
            setCommentsModal(true);
            setSelectedUser(props.users.results.find(u => u.id === parseInt(uid)));
        }
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Name'
        },
        {
            dataField: 'email',
            text: 'Email',
            hidden: document.documentElement.clientWidth < 500
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right'
        }
    ];

    var data = [];
    for (var i = 0; i < props.users.results.length; i++) {
        var user = props.users.results[i];
        data.push({
            name: (
                <Link to={'/account/profile?userId=' + user.id} style={{ textDecoration: 'none', color: '#000', display: 'flex', alignItems: 'center' }}>
                    <img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={user.first_name + " " + user.last_name} style={{ marginRight: 10 }} />
                    <div style={{ paddingTop: 10 }}>
                        {user.first_name + " " + user.last_name}
                        <p>{user.email}</p>
                    </div>
                </Link>
            ),
            email: user.email,
            actions: (
                <div style={{}}>
                    <Button color={'secondary'} value={user.id} style={{ float: 'right', marginLeft: 5 }} onClick={toggleSourced}>
                        {"Register"}
                    </Button>
                </div>
            )
        });
    }

    return (
        <Layout title={props.title} noBorder={true}>
            <InnerTitleLayout title={"Select a Customer"} hideCard={true} search={<TextField id={"search"} onChange={search} placeholder={"Search for a customer"} />}>
                <Table
                    title={"customer"}
                    keyField={'category'}
                    data={data}
                    columns={columns}
                    pagination={props.users}
                    isLoading={props.isLoading}
                    onPageChange={onPageChange} />
                <RegisterModal open={commentsModal} toggle={toggle} user={selectedUser} vehicles={props.vehicles} isLoading={props.isLoadingVehicles} />
            </InnerTitleLayout>
        </Layout>
    );
};

const RegisterModal = (props) => {
    const navigate = useNavigate();

    const dateFormatter = (cell, row) => {
        var date = new Date(Date.parse(row.modified));
        return <span>{date.toLocaleDateString('en-GB')}</span>;
    };

    const openForm = (event) => {
        navigate('/parking/register/' + event.target.value);
    };

    if (props.user === null || props.user === undefined) {
        return <div />;
    }

    const columns = [
        {
            dataField: 'license',
            text: 'License Plate',
        },
        {
            dataField: 'make',
            text: 'Make/Model',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    var data = [];
    for (var i = 0; i < props.vehicles.results.length; i++) {
        var vehicle = props.vehicles.results[i];
        data.push({
            license: vehicle.license_plate,
            make: `${vehicle.colour} ${vehicle.make} ${vehicle.model !== 'None' ? vehicle.model : ''}`,
            actions: (
                <div style={{}}>
                    <Button
                        color={'secondary'}
                        value={`l.${vehicle.license_plate}`}
                        onClick={openForm}
                        style={{ float: 'right', marginLeft: 5 }}
                    >
                        {'Choose Vehicle'}
                    </Button>
                </div>
            ),
        });
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2) {
            style.backgroundColor = '#f8f9fa';
        } else {
            style.backgroundColor = '#fff';
        }
        return style;
    };

    return (
        <Modal isOpen={props.open} toggle={props.toggle} size={'lg'} centered={true}>
            <ModalHeader toggle={props.toggle}>
                Registering vehicle for {props.user.first_name + ' ' + props.user.last_name}
            </ModalHeader>
            <ModalBody>
                {props.isLoading ? (
                    'Loading...'
                ) : (
                    <>
                        <h3>Choose Existing Vehicle</h3>
                        <BootstrapTable
                            keyField={'comment'}
                            data={data}
                            columns={columns}
                            rowStyle={rowStyle}
                            bordered={false}
                            noDataIndication={'No Comments'}
                        />
                        <p style={{ textAlign: 'center' }}>--or--</p>
                        <Button
                            color={'primary'}
                            value={`c.${props.user.id}`}
                            onClick={openForm}
                            size={'lg'}
                            style={{ margin: 'auto', display: 'block' }}
                        >
                            {'New Vehicle'}
                        </Button>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.user.loadingUsers,
    users: state.user.users,
    vehicles: state.parking.vehicles,
    isLoadingVehicles: state.parking.loadingVehicles,
    scope: 0
})

export default connect(mapStateToProps)(RegisterNewVehicle);
