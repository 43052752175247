import { config } from './'

export const ticketApi = {
    listCategories,
    createCategory,
    createTicket,
    listTickets,
    getTicket,
    createComment,
    markSolved,
    createAction,
    updateAction,
    updateCategory
};

function listCategories(token, marina) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.listCategories}?marina=${marina}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function createCategory(token, category, marina_id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ category, actions: [], marina: marina_id })
    };

    return fetch(`${config.url}${config.endpoint.createCategory}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function updateCategory(token, category, department) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ department })
    };

    return fetch(`${config.url}${config.endpoint.updateCategory}/${category}/`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function createAction(token, marina, category, action) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ category, action, marina })
    };

    return fetch(`${config.url}${config.endpoint.createAction}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function updateAction(token, marina, category, action, edit) {
   const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: edit, marina })
    };

    return fetch(`${config.url}${config.endpoint.updateAction}/${action}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function createTicket(token, marina, customer, equipment, assignedTo, isUrgent, action, comment, dateRequired) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            marina: marina,
            requested_by: customer,
            assigned_to: assignedTo,
            is_urgent: isUrgent,
            action: action,
            comments: [{
                comment: comment,
                user: customer
            }],
            equipment: equipment,
            date_required: dateRequired
        })
    };

    return fetch(`${config.url}${config.endpoint.createTicket}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function createComment(token, user, isStaffNote, comment, ticket) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            user,
            is_staff_note: isStaffNote,
            is_deleted: false,
            comment,
            ticket
        })
    };

    return fetch(`${config.url}${config.endpoint.createTicketComment}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function listTickets(token, marina, page, onlyUnsolved, user, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listTicket}?marina=${marina}&page=${page}`;
    if (onlyUnsolved === true) {
        url += `&onlyUnsolved=true`
    }
    if (user != null) {
        url += `&userId=${user}`
    }
    if (filter != null) {
        url += `&filter=${filter}`
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function getTicket(token, ticket, marina) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listTicket}/${ticket}?marina=${marina}`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(ticket => {
            return ticket;
        });
}

function markSolved(token, ticketId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ ticket_id: ticketId })
    };

    return fetch(`${config.url}${config.endpoint.markSolved}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
