import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, userActions, parkingActions, inventoryActions } from '../../actions'
import { config } from '../../api';
import { history } from '../../store';
import moment from 'moment'

import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout'
import TextField from '../../components/textfield'
import { Table } from '../../components/tables'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { throttle } from 'throttle-debounce';

class InventoryList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            commentsModal: false,
            selectedUser: null
        }

        this.onPageChange = this.onPageChange.bind(this);
        this.search = this.search.bind(this);
        this.throttleSearch = throttle(1000, this.handleSearch)
        this.handleSearch = this.handleSearch.bind(this);
        this.togglePdf = this.togglePdf.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("Register Vehicle"));
        this.props.dispatch(inventoryActions.listInventory(this.props.token, this.props.marina, 1, "-id", null));
    }

    onPageChange = (page, sizePerPage) => {
        this.props.dispatch(inventoryActions.listInventory(this.props.token, this.props.marina, 1, "-id", null));
    }

    search(e) {
        const { value } = e.target;
        this.throttleSearch(value);
    }

    handleSearch(value) {
        if (value.length > 1 || value == "") {
            this.props.dispatch(inventoryActions.listInventory(this.props.token, this.props.marina, 1, "-id", value));
        }
    }

    showFile(blob) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: "application/pdf" })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = "inventory.pdf";
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    togglePdf(event) {
        const { value } = event.target;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props.token}`
            }
        };

        var url = `${config.url}${config.endpoint.pdf}?inventory_id=${value}`;
        var pdf = fetch(url, requestOptions)
            .then(r => r.blob())
            .then(this.showFile);
    }

    render() {

        const columns = [
            {
                dataField: 'name',
                text: 'Name',
                formatter: (col, row) => { return <span style={{ display: 'block', minWidth: 275, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{col}</span> }
            },
            {
                dataField: 'season',
                text: 'Information'
            },
            {
                dataField: 'date',
                text: 'Date'
            },
            {
                dataField: 'actions',
                text: 'Actions',
                headerAlign: (column, colIndex) => 'right'
            }
        ];

        var data = [];
        for (var i = 0; i < this.props.inventory.results.length; i++) {
            var inventory = this.props.inventory.results[i];
            var user = inventory.customer;
            data.push({
                name: (
                    <Link to={'/account/profile?userId=' + user.id} style={{ textDecoration: 'none', color: '#000', display: 'flex', alignItems: 'center' }}>
                        <img src={"/img/avatar.jpg"} className={"avatar img-fluid rounded-circle mr-1"} alt={user.first_name + " " + user.last_name} style={{marginRight: 10}} />
                        <div style={{ paddingTop: 10 }}>
                            {user.first_name + " " + user.last_name}
                            <p>{user.email}</p>
                        </div>
                    </Link>

                ),
                season: <>{inventory.season.name}<br />{inventory.equipment.name}</>,
                date: moment(inventory.date).calendar(),
                actions: (
                    <div style={{}}>
                        <Button color={'secondary'} value={inventory.id} style={{ float: 'right', marginLeft: 5 }} onClick={this.togglePdf}>
                            {"Download Inventory"}
                        </Button>
                    </div>
                )
            })
        }

        return (
            <Layout title={this.props.title} noBorder={true}>
                <InnerTitleLayout title={"Inventories"} hideCard={true} search={<TextField id={"search"} onChange={this.search} placeholder={"Search for an inventory"} />}>
                    <Table
                        title={"inventory"}
                        keyField={'category'}
                        data={data}
                        columns={columns}
                        pagination={this.props.inventory}
                        isLoading={this.props.isLoading}
                        onPageChange={this.onPageChange} />
                </InnerTitleLayout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    users: state.user.users,
    vehicles: state.parking.vehicles,
    inventory: state.inventory.inventory,
    isLoading: state.inventory.loadingInventories,
    scope: 0
})

export default connect(mapStateToProps)(InventoryList);
