import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ReactComponent as Loading } from './../../vectors/loading.svg'

const styles = {
	iconStyle: {
		width: 18,
		height: 18
	}
};

class Sidebar extends Component {
	
	render() {

		return (
			<nav className={"sidebar"}>
				<div className={"sidebar-content"}>

					<ul className={"sidebar-nav"}>

						<div className={'sidebar-main'}>

							<Loading style={{ width: '90%', margin: 10 }} />

						</div>

					</ul>

				</div>
			</nav>
		);
	}

}

const mapStateToProps = state => ({

});

export default connect(
  mapStateToProps,
)(Sidebar)