import { alertActions } from './'
import { parkingConstants } from '../constants';
import { parkingApi } from '../api'
import { history } from '../store';

export const parkingActions = {
    listVehicles,
    listRecords,
    createRecord,
    updateRecord,
    markDeparted
};

function listVehicles(token, marina, page, parkedOnly=false, user=null) {
    return dispatch => {
        dispatch(request());

        parkingApi.listVehicles(token, marina, page, parkedOnly, user, null)
            .then(
                vehicles => {
                    dispatch(success(vehicles));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: parkingConstants.VEHICLES_REQUEST } }
    function success(vehicles) { return { type: parkingConstants.VEHICLES_SUCCESS, vehicles } }
    function failure(error) { return { type: parkingConstants.VEHICLES_FAILURE, error } }
}


function listRecords(token, marina, page, user=null, ordering="-arrival_date", filter=null) {
    return dispatch => {
        dispatch(request());

        parkingApi.listRecords(token, marina, page, user, ordering, filter)
            .then(
                records => {
                    dispatch(success(records));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: parkingConstants.RECORDS_REQUEST } }
    function success(records) { return { type: parkingConstants.RECORDS_SUCCESS, records } }
    function failure(error) { return { type: parkingConstants.RECORDS_FAILURE, error } }
}

function createRecord(token, marina, data, navigate) {
    return dispatch => {
        dispatch(request());

        parkingApi.createRecord(token, marina, data)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/parking/records/all')
                    dispatch(alertActions.success("Vehicle registered!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: parkingConstants.CREATE_RECORD_REQUEST } }
    function success() { return { type: parkingConstants.CREATE_RECORD_SUCCESS } }
    function failure(error) { return { type: parkingConstants.CREATE_RECORD_FAILURE, error } }
}

function updateRecord(token, marina, data) {
    return dispatch => {
        dispatch(request());

        parkingApi.updateRecord(token, marina, data)
            .then(
                result => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: parkingConstants.UPDATE_RECORD_REQUEST } }
    function success() { return { type: parkingConstants.UPDATE_RECORD_SUCCESS } }
    function failure(error) { return { type: parkingConstants.UPDATE_RECORD_FAILURE, error } }
}


function markDeparted(token, marina, data) {
    return dispatch => {
        dispatch(request());

        parkingApi.markDeparted(token, marina, data)
            .then(
                result => { 
                    dispatch(success());
                    dispatch(alertActions.success("Vehicle marked as departed!"));
                    dispatch(listRecords(token, marina, 1));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: parkingConstants.MARK_DEPARTED_REQUEST } }
    function success() { return { type: parkingConstants.MARK_DEPARTED_SUCCESS } }
    function failure(error) { return { type: parkingConstants.MARK_DEPARTED_FAILURE, error } }
}
