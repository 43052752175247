import React from 'react'

const Form = (props) => (
	<form {...props}>
		 {props.children}
	</form>
);

const FormItem = (props) => (
	<div className={'form-group'}>
		<div className={'m-sm-4'}>
			{props.children}
		</div>
	</div>
);

export {
	Form,
	FormItem
}
