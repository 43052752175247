import { config } from './'

export const alertApi = {
    createPushAlert
};

function createPushAlert(token, marina, pushAlert) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ marina, message: pushAlert })
    };

    return fetch(`${config.url}${config.endpoint.createPushAlert}`, requestOptions)
        .then(handleResponse)
        .then(alert => {
            return alert;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

