import React from 'react'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../../components/spinner'

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2) {
        style.backgroundColor = "#f8f9fa";
    } else {
        style.backgroundColor = "#fff";
    }
    return style;
};

class Table extends React.Component {
	
	constructor(props) {
        super(props);

        this.customTotal = this.customTotal.bind(this);
    }

	customTotal = (from, to, size) => {
        if (this.props.isLoading) {
            return (
                <span className="react-bootstrap-table-pagination-total">
                    Loading page...
                </span>
           );
        } else if (this.props.hidePageCount) {
            return <></>;
        } else {
            return (
                <span className="react-bootstrap-table-pagination-total">
                    Showing { from } to { to } of { size } {this.props.title.toLowerCase()}
                </span>
            );
        }
    }

	render() {
		return (
            <div>
    			<BootstrapTable 
                    keyField={this.props.keyField}
                    data={this.props.data}
                    columns={this.props.columns}
                    rowStyle={rowStyle}
                    bordered={false}
                    remote={true}
                    pagination={!this.props.action && paginationFactory(
                        {
                            page: this.props.pagination.page,
                            sizePerPage: this.props.pagination.size_per_page,
                            totalSize: this.props.pagination.count,
                            sizePerPageList: [],
                            showTotal: true,
                            paginationTotalRenderer: this.customTotal,
                            onPageChange: this.props.onPageChange,
                            hidePageListOnlyOnePage: true
                        })}
                    noDataIndication={ () =>
                        {
                            if (this.props.isLoading) {
                                return <Spinner coloured={true} />
                            } else {
                                return <div>No {this.props.title.toLowerCase()} found</div>
                            }
                        }
                    }
                    responsive={true}
                    onTableChange={this.props.onTableChange}
                  />
                  {this.props.action && !this.props.isLoading &&
                      <Row style={{ marginLeft: 5, marginRight: 5 }}>
                        <Col>
                            <p style={{ paddingTop: 5 }}>{`Showing ${this.props.data.length} ${this.props.title.toLowerCase()}`}</p>
                        </Col>
                        <Col>
                            <Link to={this.props.actionLink}>
                                <Button color={'primary'} type={'submit'} style={{ float: 'right' }}>
                                    <FontAwesomeIcon icon={faAngleRight} />&nbsp;
                                    {this.props.action}
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                  }
              </div>
		)
	}
}

export {
	Table
};
