import { config } from './'

export const configurationApi = {
    fetchConfiguration,
    fetchSummary,
    fetchContent,
    fetchDepartments,
    createDepartment
};

function fetchConfiguration(token, page) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.fetchConfiguration}?page=${page}`, requestOptions)
        .then(handleResponse)
        .then(config => {
            return config;
        });
}

function fetchSummary(token, marina) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.fetchSummary}?marina=${marina}`, requestOptions)
        .then(handleResponse)
        .then(config => {
            return config;
        });
}

function fetchContent(token, marina) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.content}?marina=${marina}`, requestOptions)
        .then(handleResponse)
        .then(config => {
            return config;
        });
}

function fetchDepartments(token, marina, page) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.departments}?marina=${marina}&page=${page}`, requestOptions)
        .then(handleResponse)
        .then(config => {
            return config;
        });
}

function createDepartment(token, marina, department) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({marina, department})
    };

    return fetch(`${config.url}${config.endpoint.departments}`, requestOptions)
        .then(handleResponse)
        .then(config => {
            return config;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
