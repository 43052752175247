import React from 'react'

const Card = (props) => (
	<div className={'card'}>
		 {props.children}
	</div>
);

const CardBody = (props) => (
	<div className={'card-body'}>
		<div className={'m-sm-4'}>
			{props.children}
		</div>
	</div>
);

export {
	Card,
	CardBody
}
