import React from 'react'
import { connect } from 'react-redux'
import { configurationActions, userActions } from '../../../actions'

import { Container, Row, Col, Button } from 'reactstrap';
import { Card, CardBody } from './../../../components/card'
import { Form, FormItem } from './../../..//components/form'
import TextField from './../../../components/textfield'
import Spinner from './../../../components/spinner'
import { Layout, InnerTitleLayout } from '../../../components/layout'

class NewCustomer extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            email: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle(this.props.title));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, firstName, lastName } = this.state;
        if (email) {
            this.props.dispatch(userActions.inviteUser(
                this.props.token,
                this.props.marina,
                this.state.email,
                this.state.firstName,
                this.state.lastName
            ));
        }
    }

    render() {
        var form = (
            <Form onSubmit={this.handleSubmit} className={'card-body'}>
                <FormItem>
                    <label><strong>Email</strong></label>
                    <TextField
                        type={"text"}
                        name={"email"}
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder={"Enter customer's email"}
                        disabled={this.props.loggingIn}
                    />
                </FormItem>
                <FormItem>
                    <label>First Name (Optional)</label>
                    <TextField
                        type={"text"}
                        name={"firstName"}
                        value={this.state.firstName}
                        onChange={this.handleChange}
                        placeholder={"Enter customer's first name"}
                        disabled={this.props.loggingIn}
                    />
                </FormItem>
                <FormItem>
                    <label>Surname (Optional)</label>
                    <TextField
                        type={"text"}
                        name={"lastName"}
                        value={this.state.lastName}
                        onChange={this.handleChange}
                        placeholder={"Enter customer's surname"}
                        disabled={this.props.loggingIn}
                    />
                </FormItem>
                <div className={"text-center mt-3"}>
                    <Button color={"primary"} size={"lg"} disabled={this.props.inviting}>
                    { (this.props.inviting && this.state.email != "")
                        ? <Spinner color={'white'} style={{ width: 40, height: 40 }} />
                        : 'Invite Customer' }</Button>
                </div>
            </Form>
        );


        return (
            <Layout title={this.props.title} noBorder={true}>
                <InnerTitleLayout title={"Customers"} hideCard={true}>
                    {form}
                </InnerTitleLayout>
            </Layout>
        )
    }
}


const mapStateToProps = (state) => ({
    title: "New Customer",
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    inviting: state.user.inviteUserStatus
})

export default connect(mapStateToProps)(NewCustomer);
