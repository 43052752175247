import { config } from './'

export const userApi = {
    listUsers,
    getUser,
    createComment,
    getActivityLog,
    getRegisterStatus,
    inviteUser,
    register,
    setUserScope,
    setUserDetails,
    setActivationState
};

function listUsers(token, marina, scope, page, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listUsers}?marina=${marina}&scope=${scope}&page=${page}`;
    if (filter != null) {
        url = url + `&filter=${filter}`;
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function getUser(token, userId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.listUsers}/${userId}/`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function setUserScope(token, userId, userScope) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id: userId, profile: {scope: parseInt(userScope)}})
    };

    return fetch(`${config.url}${config.endpoint.listUsers}/${userId}/`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function setActivationState(token, userId, activationState) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id: userId, is_active: activationState})
    };

    return fetch(`${config.url}${config.endpoint.listUsers}/${userId}/`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function setUserDetails(token, userId, firstName, lastName, email, department, account, activation) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({id: userId, first_name: firstName, last_name: lastName, email: email, profile: {departments: department, account_number: account, is_active: activation}})
    };

    return fetch(`${config.url}${config.endpoint.listUsers}/${userId}/`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function createComment(token, userId, comment) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            comment: comment,
            user: userId
        })
    };

    return fetch(`${config.url}${config.endpoint.createComment}`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function getActivityLog(token, userId, page) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.activityLog}?userId=${userId}&page=${page}`, requestOptions)
        .then(handleResponse)
        .then(activityLog => {
            return activityLog;
        });
}

function getRegisterStatus(token) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return fetch(`${config.url}${config.endpoint.registerStatus}?token=${token}`, requestOptions)
        .then(handleResponse)
        .then(registerStatus => {
            return registerStatus;
        });
}

function inviteUser(token, marina, email, firstName, lastName) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            email,
            'first_name': firstName,
            'last_name': lastName,
            marina
        })
    };

    return fetch(`${config.url}${config.endpoint.inviteUser}`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function register(token, email, firstName, lastName, phone, password) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            token,
            email,
            'first_name': firstName,
            'last_name': lastName,
            phone,
            password
        })
    };

    return fetch(`${config.url}${config.endpoint.registerUser}`, requestOptions)
        .then(handleResponse)
        .then(users => {
            return users;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
