import { inventoryConstants } from '../constants';

const initialState = {
  loadingInventories: true,
  equipment: { results: [] },
  creatingEquipment: false,
  inventory: { results: [] }
}

export function inventory(state = initialState, action) {
  switch (action.type) {
    case inventoryConstants.EQUIPMENT_LIST_REQUEST:
      return {
        ...state,
        loadingInventories: true,
        equipment: { results: [] }
      };
    case inventoryConstants.EQUIPMENT_LIST_SUCCESS:
      
      var equipmentSelect = [];
      for (var i = 0; i < action.equipment.results.length; i++) {
        equipmentSelect.push({
          value: action.equipment.results[i].id,
          label: action.equipment.results[i].name + (action.equipment.results[i].model !== null ? (" (" + action.equipment.results[i].model.make + " " + action.equipment.results[i].model.model + ")") : "")
        });
      }  

      return {
        ...state,
        loadingInventories: false,
        equipment: action.equipment,
        equipmentSelect: equipmentSelect
      };
    case inventoryConstants.EQUIPMENT_CREATE_REQUEST:
     return {
       ...state,
       creatingEquipment: true
     }
    case inventoryConstants.EQUIPMENT_CREATE_SUCCESS:
     return {
       ...state,
       creatingEquipment: false
     }
    case inventoryConstants.EQUIPMENT_CREATE_FAILURE:
     return {
       ...state,
       creatingEquipment: true
     }
    case inventoryConstants.INVENTORY_LIST_REQUEST:
      return {
        ...state,
        loadingInventories: true,
        inventory: { results: [] }
      };
    case inventoryConstants.INVENTORY_LIST_SUCCESS:
            return {
        ...state,
        loadingInventories: false,
        inventory: action.inventory
      };
    default:
      return state
  }
}