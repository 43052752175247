import React from 'react'
import { connect } from 'react-redux'
import { configurationActions, userActions } from '../../actions'

import { UserView } from './'
import { Layout, InnerTitleLayout } from '../../components/layout'
import TextField from '../../components/textfield'
import { throttle } from 'throttle-debounce';

class Staff extends React.Component {
    
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.throttleSearch = throttle(1000, this.handleSearch)
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle(this.props.title));
    }

    search(e) {
        const { value } = e.target;
        this.throttleSearch(value);
    }

    handleSearch(value) {
        if (value.length > 1 || value == "") {
            this.props.dispatch(userActions.listUsers(this.props.token, this.props.marina, 1, 1, value));
        }
    }

    render() {
        return (
            <Layout title={this.props.title} noBorder={true}>
                <InnerTitleLayout title={"Staff List"} hideCard={true} search={<TextField id={"search"} onChange={this.search} placeholder={"Search for customers"} />}>
                    <UserView scope={1} title={this.props.title} />
                </InnerTitleLayout>
            </Layout>
        )
    }
}


const mapStateToProps = (state) => ({
    title: "Staff",
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
})

export default connect(mapStateToProps)(Staff);
