export const userConstants = {
    USERS_REQUEST: 'USERS_REQUEST',
    USERS_SUCCESS: 'USERS_SUCCESS',
    USERS_FAILURE: 'USERS_FAILURE',

    USER_REQUEST: 'GET_USER_REQUEST',
    USER_SUCCESS: 'GET_USER_SUCCESS',
    USER_FAILURE: 'GET_USER_FAILURE',

    USER_SCOPE_REQUEST: 'SET_USER_SCOPE_REQUEST',
    USER_SCOPE_SUCCESS: 'SET_USER_SCOPE_SUCCESS',
    USER_SCOPE_FAILURE: 'SET_USER_SCOPE_FAILURE',

    USER_DETAILS_REQUEST: 'SET_USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'SET_USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'SET_USER_DETAILS_FAILURE',

    CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
    CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
    CREATE_COMMENT_FAILURE: 'CREATE_COMMENT_FAILURE',

    SET_SELECTED_USER: 'SET_SELECTED_USER',

    ACTIVITY_LOG_REQUEST: 'GET_ACTIVITY_LOG_REQUEST',
    ACTIVITY_LOG_SUCCESS: 'GET_ACTIVITY_LOG_SUCCESS',
    ACTIVITY_LOG_FAILURE: 'GET_ACTIVITY_LOG_FAILURE',

    REGISTER_STATUS_REQUEST: 'GET_REGISTER_STATUS_REQUEST',
    REGISTER_STATUS_SUCCESS: 'GET_REGISTER_STATUS_SUCCESS',
    REGISTER_STATUS_FAILURE: 'GET_REGISTER_STATUS_FAILURE',

    REGISTER_INVITE_REQUEST: 'GET_REGISTER_INVITE_REQUEST',
    REGISTER_INVITE_SUCCESS: 'GET_REGISTER_INVITE_SUCCESS',
    REGISTER_INVITE_FAILURE: 'GET_REGISTER_INVITE_FAILURE',

    REGISTER_REQUEST: 'GET_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'GET_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'GET_REGISTER_FAILURE',
};