import { combineReducers } from 'redux'
import { alert } from './alert'
import { authentication } from './authentication'
import { configuration } from './configuration'
import { ticket } from './ticket'
import { user } from './user'
import { inventory } from './inventory'
import { parking } from './parking'
import { reducer as toastr } from 'react-redux-toastr'
import locationReducer from './location'

export default combineReducers({
	alert,
	authentication,
	configuration,
	toastr,
	ticket,
	user,
	inventory,
	parking,
	location: locationReducer
})
