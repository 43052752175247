import { config } from './'

export const inventoryApi = {
    listEquipment,
    createEquipment,
    listInventory,
    addUserToEquipment,
    patchEquipment
};

function listEquipment(token, marina, page, user=null, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listEquipment}?marina=${marina}&page=${page}`;
    if (user != null) {
        url += `&userId=${user}`
    }
    if (filter != null) {
        url += `&filter=${filter}`
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(equipment => {
            return equipment;
        });
}

function createEquipment(token, marina, name, year, make, model, license, serialNumber, vetted, customer) {
    
    var modelBody = {
        model,
        equipment_type: 0
    };

    if (model == null || model == "") {
        modelBody = undefined;
    }

    var requestBody = JSON.stringify({
        name,
        year,
        model: modelBody,
        make,
        license,
        serial_number: serialNumber,
        vetted,
        marina,
        customer,
        engines: []
    });

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: requestBody
    };

    return fetch(`${config.url}${config.endpoint.createEquipment}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function listInventory(token, marina, page, order, filter) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listInventory}?marina=${marina}&page=${page}`;
    if (order != null) {
        url += `&ordering=${order}`
    }
    if (filter != null) {
        url += `&query=${filter}`
    }

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(inventory => {
            return inventory;
        });
}

function addUserToEquipment(token, equipmentId, userId) {
    
    var requestBody = JSON.stringify({
        equipment_id: equipmentId,
        customer_id: userId
    });

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: requestBody
    };

    return fetch(`${config.url}${config.endpoint.manageEquipmentAccess}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}

function patchEquipment(token, equipmentId, inactive) {
    
    var requestBody = JSON.stringify({
        inactive
    });

    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: requestBody
    };

    return fetch(`${config.url}${config.endpoint.updateEquipment.replace("{id}", equipmentId)}`, requestOptions)
        .then(handleResponse)
        .then(categories => {
            return categories;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
