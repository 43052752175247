import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../spinner'

const Layout = (props) => (
    <div>
        <Row>
            <Col>
                <h1>{props.title}</h1>
            </Col>
            <Col>
                {props.actionType && props.actionLink &&
                    <Link to={props.actionLink}>
                        <Button color={'primary'} type={'submit'} style={{ float: 'right' }}>
                            <FontAwesomeIcon icon={faPlus} />&nbsp;
                            New {props.actionType}
                        </Button>
                    </Link>
                }
            </Col>
        </Row>
        {props.hideCard 
                    ?    props.children
                    :     
            <div className={'card'}>
                {props.noBorder
                    ?
                        <div>
                            {props.children}
                        </div>
                    :
                        <div className={'m-sm-4'}>
                            {props.children}
                        </div>
                }
            </div>
        }
    </div>
);

const InnerTitleLayout = (props) => (
    <div className={(props.hideCard ? '' : 'card') + (props.className ? ' ' + props.className : '')} style={{minHeight: 250}}>
        <div className="card-header" style={{ minHeight: 56 }}>
            <Row>
                <Col>
                {props.actionType && props.actionLink
                    ? <h5 className="card-title mb-0" style={{ marginTop: 7 }}>{props.title}</h5>
                    : <h5 className="card-title mb-0" style={{ marginTop: 7 }}>{props.title}</h5>
                }
                </Col>
                <Col>

                    {props.search && props.search}

                    {props.actionType && props.actionLink &&
                        <Link to={props.actionLink}>
                            <Button color={props.primaryAction ? 'secondary' : 'primary'} type={'submit'} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={props.actionIcon ? props.actionIcon : faPlus} />&nbsp;
                                New {props.actionType}
                            </Button>
                        </Link>
                    }

                    {props.actionType && props.action &&
                        <Button color={props.primaryAction ? 'secondary' : 'primary'} disabled={props.actionDisabled} type={'submit'} onClick={props.action} style={{ float: 'right' }}>
                            <FontAwesomeIcon icon={props.actionIcon ? props.actionIcon : faPlus} />&nbsp;
                            {props.actionType}
                        </Button>
                    }
                </Col>
            </Row>
        </div>
        {props.bordered 
            ?
                <div className={'m-sm-4'}>
                    {props.children}
                </div>
            :
                <div>
                    {props.children}
                </div>
        }
    </div>
);

const QuickStatusLayout = (props) => (
    <div className={'card flex-fill'}>
        <div className={'card-body py-2'}>
            {props.loading
                ? <Spinner coloured={true} />
                : (
                    <Row>
                        <Col md={12} className={'align-self-center text-center text-md-left'}>
                            <h4>{props.number}</h4>
                            <p className="mb-0">{props.title}</p>
                        </Col>
                    </Row>
            )}
        </div>
    </div>
);

const StatusLayout = (props) => (
    <div className={'card flex-fill'} style={{height: '80%'}}>
        <div className={'card-body py-2'}>
            {props.loading
                ? <Spinner coloured={true} />
                : (
                    <Row>
                        <Col className={'align-self-center text-center text-md-left'}>
                            {props.children}
                        </Col>
                    </Row>
            )}
        </div>
    </div>
);

export {
    Layout,
    InnerTitleLayout,
    QuickStatusLayout,
    StatusLayout
}
