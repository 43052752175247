import React, { Component, useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import PrivateRoute from '../privateroute'

import { authActions, updatePathname } from '../../actions';

import { Sidebar } from '../../components/sidebar/'
import { Navbar } from '../../components/navbar/'
import Footer from '../../components/footer/'

import Loading from '../loading'
import Home from '../home'
import About from '../about'
import Search from '../search'
import { TicketsUser, TicketsStaff, TicketsUnsolved, NewTicket, Ticket } from '../tickets'
import { MyVehicles, AllVehicles, MyParkingRecords, AllParkingRecords, RegisterNewVehicle, RegisterNewVehicleForm } from '../parking'
import { Equipment, NewEquipment, InventoryList } from '../inventory'
import { Configuration, NewCategory, Staff, Customers, Message, NewCustomer, Departments } from '../marina'
import { Login, Register, Reset } from '../auth'
import Error from '../error'
import { Profile } from '../user'
import { faTachometerAlt, faEnvelope, faParking, faShip, faWater, faUser } from '@fortawesome/free-solid-svg-icons';

const scopes = [
    "customer",
    "staff",
    "admin"
]

const privateRoutes = [
    {
        path: "/",
        component: Home,
        icon: faTachometerAlt,
        verbose: "Dashboard",
        scope: 0,
        subroutes: []
    },
    {
        icon: faEnvelope,
        verbose: "Tickets",
        scope: 0,
        subroutes: [
            {
                path: "/tickets",
                component: TicketsUser,
                verbose: "My Tickets",
                scope: 0
            },
            {
                path: "/tickets/unsolved",
                component: TicketsUnsolved,
                verbose: "Unsolved Tickets",
                scope: 1
            },
            {
                path: "/tickets/all",
                component: TicketsStaff,
                verbose: "All Tickets",
                scope: 1
            },
            {
                path: "/tickets/new",
                component: NewTicket,
                verbose: "Open New Ticket",
                scope: 0
            },
            {
                path: "/tickets/:ticketId",
                component: Ticket,
                verbose: "Ticket",
                hide: true,
                scope: 0
            },
        ]
    },
    {
        icon: faParking,
        verbose: "Parking",
        scope: 0,
        subroutes: [
            {
                path: "/parking/register",
                component: RegisterNewVehicle,
                verbose: "Register New Vehicle",
                scope: 1
            },
            {
                path: "/parking/register/:licensePlate",
                component: RegisterNewVehicleForm,
                verbose: "Register New Vehicle Form",
                scope: 1,
                hide: true
            },
            {
                path: "/parking/vehicles",
                component: MyVehicles,
                verbose: "My Vehicles",
                scope: 0
            },
            {
                path: "/parking/vehicles/all",
                component: AllVehicles,
                verbose: "All Vehicles",
                scope: 1
            },
            {
                path: "/parking/records",
                component: MyParkingRecords,
                verbose: "My Parking Records",
                scope: 0
            },
            {
                path: "/parking/records/all",
                component: AllParkingRecords,
                verbose: "All Parking Records",
                scope: 1
            }
        ]
    },
    {
        icon: faShip,
        verbose: "Equipment",
        scope: 0,
        subroutes: [
            {
                path: "/inventory/equipment",
                component: Equipment,
                verbose: "View",
                scope: 0,
            },
            {
                path: "/inventory/equipment/new",
                component: NewEquipment,
                verbose: "Add",
                scope: 1
            },
            {
                path: "/inventory",
                component: InventoryList,
                verbose: "Inventory List",
                scope: 1
            }
        ]
    },
    {
        icon: faWater,
        verbose: "Marina",
        scope: 1,
        subroutes: [
            {
                path: "/marina/staff",
                component: Staff,
                verbose: "Staff",
                scope: 2
            },
            {
                path: "/marina/customers",
                component: Customers,
                verbose: "Customers",
                scope: 1
            },
            {
                path: "/marina/customers/new",
                component: NewCustomer,
                verbose: "NewCustomer",
                scope: 1,
                hide: true
            },
            {
                path: "/marina/alert",
                verbose: "Marina Alerts",
                component: Message,
                scope: 2
            },
            {
                path: "/marina/configuration",
                verbose: "Ticket Actions",
                component: Configuration,
                scope: 1
            },
            {
                path: "/marina/configuration/category",
                verbose: "New Category",
                hide: true,
                component: NewCategory,
                scope: 2
            },
            {
                path: "/marina/configuration/departments",
                verbose: "Departments",
                hide: false,
                component: Departments,
                scope: 2
            },
            {
                path: "/about",
                component: About,
                verbose: "About",
                scope: 0,
            },
            {
                path: "/search",
                component: Search,
                verbose: "Search",
                hide: true,
                scope: 1
            }
        ]
    },
    {
        icon: faUser,
        verbose: "Account",
        scope: 0,
        hide: true,
        subroutes: [
            {
                path: "/account/profile",
                component: Profile,
                verbose: "Profile",
                scope: 0
            }
        ]
    },
]

const publicRoutes = [
    {
        path: "/login",
        component: Login,
        verbose: "Login"
    },
    {
        path: "/register",
        component: Register,
        verbose: "Register"
    },
    {
        path: "/reset",
        component: Reset,
        verbose: "Password Reset"
    }
]

function App() {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authentication);
    const configuration = useSelector(state => state.configuration);
    const marina = useSelector(state => state.configuration.activeMarina);
    const pageTitle = useSelector(state => state.configuration.pageTitle);
    const user = useSelector(state => state.authentication.claims ? state.authentication.claims : 0);
    const activeRoute = location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.refresh && !auth.loggedIn) {
            dispatch(authActions.refresh(auth.refresh));
        }
    }, [auth, dispatch]);

    useEffect(() => {
        if (marina) {
            if (!pageTitle) {
                document.title = marina.marina_name;
            } else {
                document.title = `${pageTitle} | ${marina.marina_name}`;
            }
        } else {
            document.title = "The Hub";
        }
    }, [marina, pageTitle]);

    const loggedIn = auth ? auth.loggedIn : false;
    const isRestrictedRoute = ["/login", "/register", "/reset"].includes(activeRoute);

    if (!auth.canRefresh && !isRestrictedRoute) {
        window.location.pathname = ('/login')
    }

    if ((auth.loggingIn || !auth.loggedIn) && !isRestrictedRoute) {
        return <Loading loadingText={'Loading Marina...'} />;
    }

    if (
        configuration.loadingOrganisation ||
        configuration.loadingContent ||
        (!configuration.loadedOrganisation && !configuration.loadedContent && auth.loggedIn)
    ) {
        return <Loading loadingText={'Loading Marina...'} />;
    }

    const privRoutes = privateRoutes.filter(r => r.scope <= user.scope);
    const privateRouteComponents = privRoutes.flatMap(privateRoute => {
        const subroutes = privateRoute.subroutes
            .filter(sr => sr.scope <= user.scope)
            .map(subroute => (
                { path: subroute.path, element: <PrivateRoute component={subroute.component} loggedIn={auth.loggedIn} />, key: subroute.path }
            ));

        return [
            { path: privateRoute.path, element: <PrivateRoute component={privateRoute.component} loggedIn={auth.loggedIn} />, key: privateRoute.path },
            ...subroutes,
        ];
    });

    const publicRouteComponents = publicRoutes.map(publicRoute =>
        ({ path: publicRoute.path, element: <publicRoute.component />, key: publicRoute.path })
    );

    return (
        <div className={'wrapper'}>
            {loggedIn ? (
                <>
                    <Sidebar routes={privateRoutes} />
                    <div className={'main'}>
                        <Navbar />
                        <div className={'content'}>
                            <Routes>
                                {privateRouteComponents.map(({ path, element, key }) => (
                                    <Route path={path} element={element} key={key} />
                                ))}
                                {publicRouteComponents.map(({ path, element, key }) => (
                                    <Route path={path} element={element} key={key} />
                                ))}
                                <Route path="*" element={<Error />} />
                            </Routes>
                        </div>
                        <Footer marina={marina ? marina.marina_name : ""} />
                    </div>
                </>
            ) : (
                <div className={'main'}>
                    <Routes>
                        {privateRouteComponents.map(({ path, element, key }) => (
                            <Route path={path} element={element} key={key} />
                        ))}
                        {publicRouteComponents.map(({ path, element, key }) => (
                            <Route path={path} element={element} key={key} />
                        ))}
                        <Route path="*" element={<Error />} />
                    </Routes>
                </div>
            )}
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick />
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.authentication,
    configuration: state.configuration,
    marina: state.configuration.activeMarina,
    pageTitle: state.configuration.pageTitle,
    activeRoute: state.location.path,
    user: state.authentication.claims ? state.authentication.claims : 0
})


export default connect(mapStateToProps)(props => <App {...props} />);
