import { config } from './'

export const searchApi = {
    handleSearch
};

function handleSearch(token, marina, query) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.search}?marina=${marina}&query=${query}`, requestOptions)
        .then(handleResponse)
        .then(results => {
            return results;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

