import React from 'react'
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from './../../vectors/dbw-spinner.svg'
import { ReactComponent as LogoWhite } from './../../vectors/dbw-spinner-white.svg'

class Spinner extends React.Component {
  render() {
    if (this.props.coloured) {
      return <Logo className={"spinner spinner-centre"} {...this.props} />
    } else {
      return <LogoWhite className={"spinner"} {...this.props} />
    }
  }
}

Spinner.propTypes = {
  coloured: PropTypes.bool
}

export default Spinner;
