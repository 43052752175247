import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { alertActions, configurationActions, parkingActions } from '../../actions'

import Select from 'react-select'
import DatePicker from 'react-datepicker'
import ReactQuill from 'react-quill'
import { Form } from '../../components/form'
import TextField from './../../components/textfield'
import { Col, Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { GenericSelect } from '../../components/select'
import { useNavigate, useParams } from 'react-router-dom';

const RegisterNewVehicleForm = (props) => {
    const getKeyByValue = (object, value) => {
        return object.find((obj) => obj.label === value);
    };

    const [error, setError] = useState(false);
    const [customer, setCustomer] = useState(-1);
    const [submitted, setSubmitted] = useState(false);
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [colour, setColour] = useState(null);
    const [province, setProvince] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [license, setLicense] = useState(null);
    const [guestName, setGuestName] = useState(null);
    const [keyNumber, setKeyNumber] = useState(null);
    const [location, setLocation] = useState(null);
    const [payment, setPayment] = useState(getKeyByValue(props.paymentStatuses, 'Unpaid'));
    const [trailer, setTrailer] = useState(null);

    const [loaded, setLoaded] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let date = new Date();

        let licensePlate = null;
        let customer = -1;
        let make = null;
        let model = null;
        let colour = null;
        let province = null;
        let keyNumber = null;
        let location = null;
        let payment = getKeyByValue(props.paymentStatuses, 'Unpaid');

        const pathname = window.location.pathname.split("/").pop();
        if (pathname.indexOf('l.') !== -1) {
            licensePlate = decodeURIComponent(pathname.replace('l.', ''));
            let vehicle = props.vehicles.results.find((v) => v.license_plate == licensePlate);
            if (vehicle !== undefined) {
                customer = vehicle.customer.id;
                make = getKeyByValue(props.vehicleMakes, vehicle.make);
                model = vehicle.model;
                colour = getKeyByValue(props.colours, vehicle.colour);
                province = getKeyByValue(props.provinces, vehicle.province);
            }
        } else if (pathname.indexOf('c.') !== -1) {
            customer = decodeURIComponent(pathname.replace('c.', ''));
            licensePlate = '';
        }

        if (licensePlate == null || customer == -1) {
            setError(true);
            setLoaded(true);
            return;
        }

        setCustomer(customer);
        setMake(make);
        setModel(model);
        setColour(colour);
        setProvince(province);
        setCurrentDate(date);
        setLicense(licensePlate);
        setKeyNumber(keyNumber);
        setLocation(location);
        setPayment(payment);
        setTrailer(null);
        setLoaded(true);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'license':
                setLicense(value);
                break;
            case 'model':
                setModel(value);
                break;
            case 'keyNumber':
                setKeyNumber(value);
                break;
            case 'trailer':
                setTrailer(value);
                break;
            case 'guestName':
                setGuestName(value);
                break;
            default:
                break;
        }
    };

    const handleChangeDateTime = (dateTime) => {
        setCurrentDate(dateTime);
    };

    const handleChangeSelectMake = (option) => {
        setMake(option);
    };

    const handleChangeSelectColour = (option) => {
        setColour(option);
    };

    const handleChangeSelectProvince = (option) => {
        setProvince(option);
    };

    const handleChangeSelectLocation = (option) => {
        setLocation(option);
    };

    const handleChangeSelectPayment = (option) => {
        setPayment(option);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);

        if (!license) {
            props.dispatch(alertActions.error('No license has been selected'));
            return;
        }

        if (!make || !model) {
            props.dispatch(alertActions.error('No make or model have been selected'));
            return;
        }

        if (!colour) {
            props.dispatch(alertActions.error('No colour has been selected'));
            return;
        }

        if (!province) {
            props.dispatch(alertActions.error('No province has been selected'));
            return;
        }

        if (!location) {
            props.dispatch(alertActions.error('No location has been selected'));
            return;
        }

        const data = {
            customer: parseInt(customer),
            vehicle_license_plate: license,
            vehicle_province: parseInt(province.value),
            vehicle_car_make: parseInt(make.value),
            vehicle_car_model: model,
            vehicle_car_colour: parseInt(colour.value),
            parking_arrival_date: currentDate.toISOString(),
            parking_key_number: keyNumber,
            parking_guest_name: guestName,
            parking_location: parseInt(location.value),
            parking_payment_status: parseInt(payment.value),
            trailer_license: trailer,
            change_vehicle_owner: 1,
            arrival_scan_url: '',
            vehicle_image_urls: '',
        };

        props.dispatch(parkingActions.createRecord(props.token, props.marina, data, navigate));
    };

    if (error === true) {
        props.dispatch(alertActions.error('The check-in process needs to be completed from the Register New Vehicle screen'));
        navigate('/parking/register');
        return 'There was an error.';
    }

    if (!loaded) {
        return 'Loading...';
    }

    return (
        <div>
            <h1>Register Vehicle</h1>

            <Form className={'card card-body'} onSubmit={handleSubmit}>
                <div className={'m-sm-4'}>
                    {props.user.scope >= 1 && (
                        <div>
                            <h4 style={{ fontSize: 'small', color: '#aaa', textTransform: 'uppercase', paddingBottom: 20 }}>
                                Vehicle Information
                            </h4>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>License</label>
                                        <TextField
                                            type="text"
                                            name="license"
                                            id="license"
                                            placeholder="245AFC"
                                            onChange={handleChange}
                                            value={license}
                                            required={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Make</label>
                                        <GenericSelect
                                            options={props.vehicleMakes}
                                            placeholder={'Search for a vehicle make'}
                                            notFound={'No vehicle makes found'}
                                            onSelect={handleChangeSelectMake}
                                            value={make}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Model</label>
                                        <TextField
                                            type="text"
                                            name="model"
                                            id="model"
                                            placeholder="Highlander"
                                            onChange={handleChange}
                                            value={model}
                                            required={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Colour</label>
                                        <GenericSelect
                                            options={props.colours}
                                            placeholder={'Search for a colour'}
                                            notFound={'No colours found'}
                                            onSelect={handleChangeSelectColour}
                                            value={colour}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <label>Province</label>
                                        <GenericSelect
                                            options={props.provinces}
                                            placeholder={'Search for a province'}
                                            notFound={'No provinces found'}
                                            onSelect={handleChangeSelectProvince}
                                            value={province}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    )}
                    <h4 style={{ fontSize: 'small', color: '#aaa', textTransform: 'uppercase', paddingBottom: 20 }}>
                        Parking Information
                    </h4>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <label>Date</label>
                                <br />
                                <DatePicker
                                    className={'form-control form-control-lg'}
                                    selected={currentDate}
                                    onChange={handleChangeDateTime}
                                    name={'currentDate'}
                                    minDate={new Date()}
                                    minTime={new Date().setHours(8, 0)}
                                    maxTime={new Date().setHours(23, 59, 59)}
                                    showTimeSelect={true}
                                    timeFormat={'HH:mm'}
                                    dateFormat={'MMMM d, yyyy h:mm aa'}
                                    timeCaption={'time'}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <label>Guest Name</label>
                                <TextField
                                    type="text"
                                    name="guestName"
                                    id="guestName"
                                    placeholder="John Smith"
                                    onChange={handleChange}
                                    value={guestName}
                                    required={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <label>Location</label>
                                    <GenericSelect
                                        options={props.locations}
                                        placeholder={'Search for a location'}
                                        notFound={'No locations found'}
                                        onSelect={handleChangeSelectLocation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <label>Payment</label>
                                <GenericSelect
                                    options={props.paymentStatuses}
                                    placeholder={'Search for a payment status'}
                                    notFound={'No payment statuses found'}
                                    onSelect={handleChangeSelectPayment}
                                    value={payment}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <label>Trailer</label>
                                    <TextField
                                        type="text"
                                        name="trailer"
                                        id="trailer"
                                        placeholder="245AFC"
                                        onChange={handleChange}
                                        value={trailer}
                                        required={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormGroup>
                    <Button color={'primary'} type={'submit'} disabled={!license}>
                        Register Vehicle
                    </Button>
                </div>
            </Form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    user: state.authentication.claims,
    vehicles: state.parking.vehicles,
    isLoadingVehicles: state.parking.loadingVehicles,
    vehicleMakes: state.configuration.content ? convertOptions(state.configuration.content.models) : [],
    colours: state.configuration.content ? convertOptions(state.configuration.content.colours) : [],
    provinces: state.configuration.content ? convertOptions(state.configuration.content.provinces) : [],
    locations: state.configuration.content ? convertOptions(state.configuration.content.locations) : [],
    paymentStatuses: state.configuration.content ? convertOptions(state.configuration.content.payment_status) : []
})

function convertOptions(valueObj) {
    var options = [];
    for (var i = 0; i < Object.keys(valueObj).length; i++) {
        var key = Object.keys(valueObj)[i];
        var value = Object.values(valueObj)[i];
        //if (value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
        options.push({
            value: key,
            label: value
        })
        //}
    }
    return options;
}

export default connect(mapStateToProps)(RegisterNewVehicleForm);
