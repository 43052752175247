import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { configurationActions, alertActions } from '../../actions';

import { InnerTitleLayout } from '../../components/layout';
import { Form, FormGroup, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import Spinner from './../../components/spinner';
import { useNavigate } from 'react-router-dom';

const Message = (props) => {
    const [alert, setAlert] = useState('');
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        props.dispatch(configurationActions.changePageTitle('Marina Alerts'));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAlert(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        toggle();
    };

    const toggle = () => {
        setModal((prevModal) => !prevModal);
    };

    const sendAlert = () => {
        if (alert) {
            props.dispatch(alertActions.createPushAlert(props.token, props.marina, alert, navigate));
            setAlert('');
            setModal(false);
        }
    };

    return (
        <InnerTitleLayout title="Alerts" bordered={true}>
            <Form onSubmit={handleSubmit} className="card-body">
                <FormGroup>
                    <Label>Send an alert to all customers and staff:</Label>
                    <Input
                        type="text"
                        name="alert"
                        id="alert"
                        onChange={handleChange}
                        disabled={props.isLoading}
                        value={alert}
                        required
                    />
                </FormGroup>

                <Button color="primary" type="submit" disabled={props.isLoading}>
                    {props.isLoading ? <Spinner style={{ width: 40, height: 40 }} /> : 'Send Alert'}
                </Button>

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Are you sure you want to send this alert to all customers?</ModalHeader>
                    <ModalBody>
                        <Alert color="primary" className="alert-outline">
                            <div className="alert-message">
                                <h4 className="alert-heading">
                                    <strong>{'Message from ' + props.marinaName}</strong>
                                </h4>
                                <p>{alert}</p>
                            </div>
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={sendAlert}>
                            Send
                        </Button>{' '}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </InnerTitleLayout>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    marinaName: state.configuration.activeMarina.marina_name,
    token: state.authentication.access,
    isLoading: state.alert.creatingAlert,
});

export default connect(mapStateToProps)(Message);
