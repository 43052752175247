import { config } from './'

export const parkingApi = {
    listVehicles,
    listRecords,
    createRecord,
    updateRecord,
    markDeparted
}

function listVehicles(token, marina, page, parkedOnly, user=null) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listVehicles}?marina=${marina}&page=${page}${user ? `&userId=${user}` : ''}${parkedOnly ? `&parkedOnly=true` : ''}`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(vehicles => {
            return vehicles;
        });
}

function listRecords(token, marina, page, user=null, ordering=null, filter=null) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    var url = `${config.url}${config.endpoint.listRecords}?marina=${marina}&page=${page}${user ? `&userId=${user}` : ''}${ordering ? `&ordering=${ordering}` : ''}${filter ? `&filter=${filter}` : ''}`;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(records => {
            return records;
        });
}

function createRecord(token, marina, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        },
        body: serialize(data)
    };

    return fetch(`${config.url}${config.endpoint.createParkingRecord}`, requestOptions)
        .then(handleResponseCreate)
        .then(status => {
            return status;
        });
}


function updateRecord(token, marina, data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        },
        body: serialize(data)
    };

    return fetch(`${config.url}${config.endpoint.updateParkingRecord}`, requestOptions)
        .then(handleResponseCreate)
        .then(status => {
            return status;
        });
}

function markDeparted(token, marina, data) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        }
    };

    return fetch(`${config.url}${config.endpoint.markDeparted}?record_id=${data.record_id}&payment_status=${data.payment_status}`, requestOptions)
        .then(handleResponseCreate)
        .then(status => {
            return status;
        });
}


function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function handleResponseCreate(response) {
    return response.text().then(text => {
        var json = JSON.parse(text);
        const data = text && json;
        if (!response.ok || json.code !== 0) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

