import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { configurationActions, ticketActions } from '../../actions';

import { InnerTitleLayout } from '../../components/layout';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import Spinner from './../../components/spinner';
import { useNavigate } from "react-router-dom";

const NewCategory = (props) => {
	const [newCategoryName, setNewCategoryName] = useState(null);
	const navigate = useNavigate();

		useEffect(() => {
			props.dispatch(configurationActions.changePageTitle('New Category'));
		}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setNewCategoryName(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (newCategoryName) {
			props.dispatch(ticketActions.createCategory(props.token, newCategoryName, props.marina.id, navigate));
		}
	};

	return (
		<InnerTitleLayout title={'New Category'} bordered={true}>
			<Form onSubmit={handleSubmit}>
				<FormGroup>
					<Label>New Category Name:</Label>
					<Input type={'text'} name={'category'} id={'category'} onChange={handleChange} disabled={props.isLoading} />
				</FormGroup>

				<Button color={'primary'} type={'submit'} disabled={props.isLoading}>
					{props.isLoading ? <Spinner style={{ width: 40, height: 40 }} /> : 'Create New Category'}
				</Button>
			</Form>
		</InnerTitleLayout>
	);
};

const mapStateToProps = (state) => ({
	marina: state.configuration.activeMarina,
	token: state.authentication.access,
	isLoading: state.ticket.loadingCategories,
});

export default connect(mapStateToProps)(NewCategory);
