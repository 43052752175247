import React from 'react'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/async';
import { inventoryActions } from '../../actions'

class EquipmentSelect extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadOptions = (inputValue, callback) => {
      this.props.dispatch(inventoryActions.listEquipmentWithFilter(this.props.token, this.props.marina, this.props.user, inputValue)).then(() => callback(this.props.equipment));
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleChange = (selectedOption) => {
      this.props.onSelect(selectedOption);
    }

    render() {

        return (
            <AsyncSelect
              key={this.props.user}
              cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              placeholder={this.props.isDisabled ? this.props.placeholder : 'Search for equipment'}
              noOptionsMessage={(inputText) => inputText === "" ? 'Search for equipment' : 'No equipment found'}
              onChange={this.handleChange}
              isDisabled={this.props.isDisabled}
            />
        );

    }

}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.user.loadingUsers,
    equipment: state.inventory.equipmentSelect,
})

export default connect(mapStateToProps)(EquipmentSelect);
