import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, parkingActions } from '../../actions'

import { ParkingRecordsView } from './'
import { Layout, InnerTitleLayout } from '../../components/layout'

class MyParkingRecordsView extends React.Component {
    
    constructor(props) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);    
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("My Parking Records"));
        this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, 1, this.props.user));
    }

    onPageChange = (page, sizePerPage) => {
        this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, page, this.props.user));
    }

    render() {
        return (
            <Layout title={'Parking'} noBorder={true}>
              <InnerTitleLayout title={"My Parking Records"} hideCard={true}>
                <ParkingRecordsView isLoading={this.props.isLoading} pagination={this.props.records} onPageChange={this.onPageChange} records={this.props.records} />
              </InnerTitleLayout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    user: state.authentication.claims.user_id,
    isLoading: state.parking.loadingRecords,
    records: state.parking.records
})

export default connect(mapStateToProps)(MyParkingRecordsView);
