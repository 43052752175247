import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Card, CardBody } from './../../components/card'
import { Form, FormItem } from './../..//components/form'
import TextField from './../../components/textfield'
import Spinner from './../../components/spinner'
import { useNavigate } from "react-router-dom";

import { authActions } from './../../actions';
import { configurationActions } from '../../actions'

import { ReactComponent as Logo } from './../../vectors/dbw-logo.svg'

const Login = ({ loggingIn, dispatch }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(configurationActions.changePageTitle("Login"));
	}, [dispatch]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'email') setEmail(value);
		else if (name === 'password') setPassword(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (email && password) {
			dispatch(authActions.login(email, password, navigate));
		}
	};

	return (
		<main className={"main h-100 w-100"}>
			<Container className={"h-100"}>
				<Row className={"h-100"}>
					<Col sm={"10"} md={"8"} lg={"6"} className={"mx-auto d-table h-100"}>
						<div className={"d-table-cell align-middle"}>
							<div className={"text-center mt-4"}>
								<Logo style={{ width: 300 }} />
								<p className={"lead"}>
									Sign in to your account to continue
								</p>
							</div>
							<Card>
								<CardBody>
									<Form onSubmit={handleSubmit}>
										<FormItem>
											<label>Email</label>
											<TextField
												type={"text"}
												name={"email"}
												value={email}
												onChange={handleChange}
												placeholder={"Enter your email"}
												disabled={loggingIn}
											/>
										</FormItem>
										<FormItem>
											<label>Password</label>
											<TextField
												type={"password"}
												name={"password"}
												value={password}
												onChange={handleChange}
												placeholder={"Enter your password"}
												disabled={loggingIn}
											/>
											{/*<small><Link to="/forgot-password">Forgot password?</Link></small>*/}
										</FormItem>
										<div className={"text-center mt-3"}>
											<Button color={"primary"} size={"lg"} disabled={loggingIn}>
												{loggingIn
													? <Spinner color={'white'} style={{ width: 40, height: 40 }} />
													: 'Log In'}</Button>
										</div>
									</Form>
									<a href={"/reset"} style={{ margin: 'auto', display: 'block', textAlign: 'center', marginTop: 20 }}>{"Forgot your password?"}</a>
								</CardBody>
							</Card>
						</div>
					</Col>
				</Row>
			</Container>
		</main>
	);
};

const mapStateToProps = state => {
	const { loggingIn } = state.authentication;
	return {
		loggingIn
	};
};

export default connect(mapStateToProps)(Login);