import { ticketConstants } from '../constants';

const initialState = {
  loadedCategories: true,
  categories: { results: [] },
  categoriesSelect: [],
  optionsSelect: [],
  loadingTickets: true,
  loadingUserTickets: true,
  isCreatingTicket: false,
  tickets: { results: [] },
  userTickets: { results: [] },
  currentTicket: null
}

export function ticket(state = initialState, action) {
  switch (action.type) {
    case ticketConstants.TICKET_ACTIONS_REQUEST:
      return {
        ...state,
        loadingCategories: true,
        loadedCategories: false,
        categories: { results: [] },
      };
     case ticketConstants.TICKET_ACTIONS_SUCCESS:
      return {
        ...state,
        loadingCategories: false,
        loadedCategories: true,
        categories: action.categories,
        categoriesSelect: action.categoriesSelect,
        optionsSelect: action.optionsSelect
      };
     case ticketConstants.TICKET_ACTIONS_FAILURE:
      return {
        ...state,
        loadingCategories: true,
        loadedCategories: false,
        categories: { results: [] }
      };
     case ticketConstants.TICKET_CATEGORY_CREATE_REQUEST:
       return {
         ...state,
         loadingCategories: true
       }
      case ticketConstants.TICKET_CATEGORY_CREATE_SUCCESS:
       return {
         ...state,
         loadingCategories: false
       }
      case ticketConstants.TICKET_CATEGORY_CREATE_FAILURE:
       return {
         ...state,
         loadingCategories: true
       }
      case ticketConstants.TICKETS_REQUEST:
       return {
         ...state,
         loadingTickets: true,
         tickets: { results: [] }
       }
      case ticketConstants.TICKETS_SUCCESS:
       return {
         ...state,
         loadingTickets: false,
         tickets: action.tickets
       }
      case ticketConstants.TICKETS_FAILURE:
       return {
         ...state,
         loadingTickets: false,
         tickets: { results: [] }
       }
      case ticketConstants.TICKETS_USER_REQUEST:
       return {
         ...state,
         loadingUserTickets: true,
         userTickets: { results: [] }
       }
      case ticketConstants.TICKETS_USER_SUCCESS:
       return {
         ...state,
         loadingUserTickets: false,
         userTickets: action.tickets
       }
      case ticketConstants.TICKETS_USER_FAILURE:
       return {
         ...state,
         loadingUserTickets: false,
         userTickets: { results: [] }
       }
      case ticketConstants.GET_TICKET_REQUEST:
        return {
          ...state,
          loadingTickets: true,
          currentTicket: null
        }
      case ticketConstants.GET_TICKET_SUCCESS:
        return {
          ...state,
          loadingTickets: false,
          currentTicket: action.returnedTicket
        }
      case ticketConstants.GET_TICKET_FAILURE:
        return {
          ...state,
          loadingTickets: false
        }
      case ticketConstants.TICKET_COMMENT_CREATE_REQUEST:
       return {
         ...state,
         isCreatingTicket: true
       }
      case ticketConstants.TICKET_COMMENT_CREATE_SUCCESS:
       return {
         ...state,
         isCreatingTicket: false
       }
      case ticketConstants.TICKET_COMMENT_CREATE_FAILURE:
       return {
         ...state,
         isCreatingTicket: false
       }
    default:
      return state
  }
}