import React from 'react'

export function dateFormatter(d) {
  var date = new Date(Date.parse(d));
  return (
      <span>{date.toLocaleString('en-CA', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute:'2-digit'
      })}</span>
  );
}
