import { userConstants } from '../constants';

const initialState = {
  loadingUsers: false,
  loadingActivityLog: false,
  activityLog: { results: [] },
  users: { results: [] },
  selectedUser: null,
  loadingRegisterStatus: false,
  registerStatus: {},
  inviteUserStatus: false,
  registeringUser: false,
  registerSignUpStatus: {}
}

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.USERS_REQUEST:
      return {
        ...state,
        loadingUsers: true,
        users: { results: [] }
      };
    case userConstants.USERS_SUCCESS:

      var usersSelect = [];
      for (var i = 0; i < action.users.results.length; i++) {
        usersSelect.push({
          value: action.users.results[i].id,
          label: action.users.results[i].first_name + " " + action.users.results[i].last_name + " (" + action.users.results[i].email + ")"
        });
      }

      return {
        ...state,
        loadingUsers: false,
        users: action.users,
        usersSelect: usersSelect
      };
    case userConstants.USER_REQUEST:
      return {
        ...state,
        loadingUsers: true,
        selectedUser: null
      };
    case userConstants.USER_SUCCESS:
      return {
        ...state,
        loadingUsers: true,
        selectedUser: action.user,
        pendingCommentsRefresh: false
      };
    case userConstants.USER_SCOPE_REQUEST:
      return {
        ...state,
        loadingUsers: true
      };
    case userConstants.USER_SCOPE_SUCCESS:
      return {
        ...state,
        loadingUsers: true
      };
    case userConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        loadingUsers: true
      };
    case userConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loadingUsers: true
      };
    case userConstants.SET_SELECTED_USER:
      return {
        ...state,
        loadingUsers: false,
        selectedUser: action.user
      };
    case userConstants.CREATE_COMMENT_SUCCESS:
      return {
        ...state,
        pendingCommentsRefresh: true
      }
    case userConstants.ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        loadingActivityLog: true,
        activityLog: { results: [] }
      }
    case userConstants.ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        loadingActivityLog: false,
        activityLog: action.activityLog
      }
    case userConstants.ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        loadingActivityLog: false,
        activityLog: { results: [] }
      }
    case userConstants.REGISTER_STATUS_REQUEST:
      return {
        ...state,
        requestingRegisterStatus: true,
        registerStatus: {}
      }
    case userConstants.REGISTER_STATUS_SUCCESS:
      return {
        ...state,
        requestingRegisterStatus: false,
        registerStatus: action.registerStatus
      }
    case userConstants.REGISTER_STATUS_FAILURE:
      return {
        ...state,
        requestingRegisterStatus: false,
        registerStatus: {}
      }
    case userConstants.REGISTER_INVITE_REQUEST:
      return {
        ...state,
        inviteUserStatus: true,
        registerStatus: {}
      }
    case userConstants.REGISTER_INVITE_SUCCESS:
      return {
        ...state,
        inviteUserStatus: false,
        registerStatus: action.inviteStatus
      }
    case userConstants.REGISTER_INVITE_FAILURE:
      return {
        ...state,
        inviteUserStatus: false,
        registerStatus: {}
      }
    case userConstants.REGISTER_REQUEST:
      return {
        ...state,
        registeringUser: true,
        registerSignUpStatus: {}
      }
    case userConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registeringUser: false,
        registerSignUpStatus: action.inviteStatus
      }
    case userConstants.REGISTER_FAILURE:
      return {
        ...state,
        registeringUser: false,
        registerSignUpStatus: {}
      }
    default:
      return state
  }
}