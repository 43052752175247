import React from 'react'
import { Layout, InnerTitleLayout } from '../../components/layout'

const About = () => (
  <Layout title={'About MarinaMonkey'}>
  	{'v' + process.env.REACT_APP_VERSION}
  </Layout>
)

export default About
