import React from 'react'
import { connect } from 'react-redux'
import { configurationActions, alertActions, userActions } from '../../actions'

import { Container, Col, Row, Button, FormGroup, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';

class CommentsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comment: null
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { comment } = this.state;
        if (comment === null) {
            this.props.dispatch(alertActions.error("No comment entered"));
            return;
        }

        if (comment) {
            this.props.dispatch(userActions.createComment(this.props.token, this.props.user.id, comment));
            this.props.toggle();
        }
    }

    render() {

        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} size={'lg'} centered={true}>
                <ModalHeader toggle={this.props.toggle}><h5>Customer Comments</h5></ModalHeader>
                <Form onSubmit={this.handleSubmit}>
                    <ModalBody>

                        <FormGroup>
                            <Label>New Comment for {this.props.user.first_name}:</Label>
                            <Input type={'textarea'} name={'comment'} id={'comment'} onChange={this.handleChange} disabled={this.props.isLoading} />
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type={'submit'} onClick={this.toggle}>
                        <FontAwesomeIcon icon={faPlus} />&nbsp;Add Comment</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
    token: state.authentication.access,
})

export default connect(mapStateToProps)(CommentsModal);
