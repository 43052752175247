import { authConstants } from './../constants';

let refreshToken = JSON.parse(localStorage.getItem('refresh'));
const initialState = refreshToken ? { loggedIn: false, refresh: refreshToken, canRefresh: true } : { loggedIn: false, canRefresh: false, isResetting: false };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
        canRefresh: false,
        ...action.user
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        canRefresh: true,
        ...action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        canRefresh: false
      };
    case authConstants.REFRESH_REQUEST:
      return {
        loggedIn: false,
        loggingIn: true,
        canRefresh: true,
        ...action.user
      };
    case authConstants.REFRESH_SUCCESS:
      return {
        loggedIn: true,
        canRefresh: true,
        ...action.user
      };
    case authConstants.REFRESH_FAILURE:
      return {
        loggedIn: false,
        canRefresh: false,
      };
    case authConstants.LOGOUT:
      return {
        loggedIn: false,
        canRefresh: false,
      };
    case authConstants.PASSWORD_RESET_REQUEST:
      return {
          ...state,
          isResetting: true
        }
     case authConstants.PASSWORD_RESET_SUCCESS:
       return {
          ...state,
          isResetting: false
        }
     case authConstants.PASSWORD_RESET_FAILURE:
       return {
          ...state,
          isResetting: false
        }
    default:
      return state
  }
}