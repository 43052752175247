import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { configurationActions, alertActions, userActions, inventoryActions, parkingActions, authActions } from '../../actions'
import { CommentsModal, EquipmentShareModal } from './'
import { Container, Col, Row, Button, FormGroup, Label, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';
import { InnerTitleLayout } from '../../components/layout'
import { Card } from '../../components/card'
import { Table } from '../../components/tables'
import BootstrapTable from 'react-bootstrap-table-next';
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCamera } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import { GenericSelect } from '../../components/select'
import { TicketView } from '../tickets'
import { useNavigate } from 'react-router-dom';

const Staff = (props) => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        profilePicture: null,
        commentsModal: false,
        editing: false,
        name: null,
        email: null,
        department: null,
        account_number: null,
        equipmentShareStatus: false,
        selectedEquipmentFamily: [],
        equipmentId: null,
    });

    const { profilePicture, commentsModal, editing, name, email, department, account_number, equipmentShareStatus, selectedEquipmentFamily, equipmentId } = state;

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            commentsModal: !prevState.commentsModal,
        }));
    };

    const openEquipmentShare = (family, equipmentId) => {
        setState((prevState) => ({
            ...prevState,
            equipmentShareStatus: true,
            selectedEquipmentFamily: family,
            equipmentId,
        }));
    };

    const closeEquipmentShare = (family) => {
        setState((prevState) => ({
            ...prevState,
            equipmentShareStatus: false,
            selectedEquipmentFamily: [],
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeSelectDepartment = (option) => {
        const departmentIds = option.map((item) => item.value);
        setState((prevState) => ({
            ...prevState,
            department: departmentIds,
        }));
    };

    const onPageChange = (page, sizePerPage) => {
        props.dispatch(userActions.getActivityLog(props.token, props.user.id, page));
    }

    const onPageChangeEquipment = (page, sizePerPage) => {
        props.dispatch(inventoryActions.listEquipment(props.token, props.marina, page, props.user.id))
    }

    const onPageChangeVehicles = (page, sizePerPage) => {
        props.dispatch(parkingActions.listVehicles(props.token, props.marina, page, false, props.user.id))
    }

    const onDrop = (acceptedFiles, rejectedFiles) => {
        // Handle dropped files
    };

    const onEdit = () => {
        setState((prevState) => ({
            ...prevState,
            editing: !editing,
        }));

        if (!editing) {
            return;
        }

        const fullName = name.split(' ');
        const firstName = fullName[0];
        const lastName = fullName.slice(1).join(' ');
        const dept = department !== undefined ? department : department.map((item) => item.value);

        props.dispatch(userActions.setUserDetails(props.token, props.user.id, firstName, lastName, email, dept, account_number, props.user.is_active));
    };

    useEffect(() => {
        if (props.activeUser) {
            props.dispatch(configurationActions.changePageTitle('My Profile'));
        } else {
            props.dispatch(configurationActions.changePageTitle(props.user.first_name + ' ' + props.user.last_name));
        }
        if (props.loggedInUser.scope > 0) {
            props.dispatch(userActions.getActivityLog(props.token, props.user.id, 1));
        }
        props.dispatch(inventoryActions.listEquipment(props.token, props.marina, 1, props.user.id));
        props.dispatch(parkingActions.listVehicles(props.token, props.marina, 1, false, props.user.id));
    }, []);

    useEffect((prevProps) => {
        if (props !== prevProps) {
            if (name === null) {
                const setToChange = {
                    name: props.user.first_name + ' ' + props.user.last_name,
                    email: props.user.email,
                    department: props.departments.filter((x) => props.user.profile.departments.includes(x.value)),
                    account_number: props.user.profile.account_number,
                };
                setState((prevState) => ({
                    ...prevState,
                    ...setToChange,
                }));
            }
        }
    }, [props]);

    const dateFormatter = (cell, row) => {
        return moment(row.modified).calendar();
    };

    const newLineFormatter = (cell, row) => {
        return (
            <span>
                {row.comment.split('\n').map((item, index) => (
                    <span key={index}>
                        {item}
                        <br />
                    </span>
                ))}
            </span>
        );
    };

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2) {
            style.backgroundColor = '#f8f9fa';
        } else {
            style.backgroundColor = '#fff';
        }
        return style;
    };

    const commentColumns = [
        {
            dataField: 'comment',
            text: 'Comment',
            formatter: newLineFormatter,
        },
        {
            dataField: 'added_by',
            text: 'Added By',
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    verticalAlign: 'top',
                };
            },
        },
        {
            dataField: 'modified',
            text: 'Added On',
            formatter: dateFormatter,
            style: (cell, row, rowIndex, colIndex) => {
                return {
                    verticalAlign: 'top',
                };
            },
        },
    ];

    const columnsActivityLog = [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
        },
        {
            dataField: 'comment',
            text: 'Comment',
        },
    ];

    const columnsEquipment = [
        {
            dataField: 'name',
            text: 'Name',
        },
        {
            dataField: 'make_model',
            text: 'Make/Model',
        },
        {
            dataField: 'serial',
            text: 'Serial',
        },
        {
            dataField: 'familyMembers',
            text: 'Family Members',
        },
    ];

    const columnsVehicle = [
        {
            dataField: 'license_plate',
            text: 'License',
        },
        {
            dataField: 'make_model',
            text: 'Make / Model',
        },
        {
            dataField: 'is_checked_in',
            text: 'Status',
            headerAlign: (column, colIndex) => 'right',
        },
    ];

    const dataActivityLog = props.activityLog.results.map((log) => ({
        date: moment(log.timestamp).calendar(),
        type: log.log_type,
        comment: log.log_comment,
        id: log.id,
    }));

    const dataEquipment = props.equipment.results.map((equipmentIndex) => ({
        name: equipmentIndex.name,
        make_model: equipmentIndex.model ? `${equipmentIndex.model.make} ${equipmentIndex.model.model}` : 'Unknown',
        license: equipmentIndex.license ? equipmentIndex.license : '-',
        serial: equipmentIndex.serial_number,
        familyMembers: <EquipmentLink
            familyInfo={equipmentIndex.family_members} count={equipmentIndex.family_members.length} equipmentId={equipmentIndex.id} toggle={openEquipmentShare} loggedInUser={props.loggedInUser} />,
        actions: (
            <div style={{}}>
                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 5 }} onClick={() => navigate('/account/profile?userId=' + equipmentIndex.id)}>
                    <FontAwesomeIcon icon={faEdit} style={{ color: '#fff' }} />
                </Button>
            </div>
        ),
    }));

    const dataVehicle = props.vehicles.results.map((vehicle) => ({
        license_plate: vehicle.license_plate,
        make_model: `${vehicle.make} ${vehicle.model}`,
        is_checked_in: vehicle.is_checked_in ? 'Currently Parked' : (props.user.profile.scope === 0 ? '-' : <Button color={'secondary'} value={`${vehicle.license_plate}`} style={{ float: 'right', marginLeft: 5 }} onClick={() => checkInVehicle(vehicle.license_plate)}>
            {'Check In'}
        </Button >),
    }));

    const checkInVehicle = (licensePlate) => {
        navigate('/parking/register/' + licensePlate);
    };

    return (
        <div>
            <InnerTitleLayout title={'Account'} bordered={true} disabled={props.loadingUser} actionType={props.loggedInUser.scope > 1 || props.activeUser ? (editing ? 'Save Profile' : 'Edit') : null} actionIcon={editing ? 'check-circle' : 'edit'} action={onEdit} primaryAction={editing}>
                <Row form>
                    <div className={'profile-container'}>
                        {/* <img src="/img/avatar.jpg" alt={props.user.first_name + props.user.last_name} width="140" height="140" className={'profile-image rounded-circle rounded mr-2 mb-2'} /> */}
                        {editing && false && (
                            <Dropzone onDrop={onDrop} accept="image/jpeg, image/png" className={'profile-image rounded-circle rounded mr-2 mb-2'}>
                                {({ getRootProps, getInputProps, isDragActive }) => {
                                    return (
                                        <div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}>
                                            <div className="profile-overlay mr-2 mb-2">
                                                <input {...getInputProps()} />
                                                <div className="profile-text">
                                                    <FontAwesomeIcon icon={faCamera} size="lg" />
                                                </div>
                                                {isDragActive && (
                                                    <div className="profile-text">
                                                        <FontAwesomeIcon icon={faCamera} size="lg" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                        )}
                    </div>
                    <div>
                        <div style={{ marginLeft: 20, marginTop: 20 }}>
                            {editing ? (
                                <>
                                    <Input name={'name'} placeholder={'Name'} value={name} onChange={handleChange} />
                                    <br />
                                </>
                            ) : (
                                <h1>
                                    {props.user.first_name} {props.user.last_name}
                                </h1>
                            )}
                            {editing ? (
                                <>
                                    <Input name={'email'} placeholder={'Email'} value={email} onChange={handleChange} />
                                    <br />
                                </>
                            ) : (
                                <p>{props.user.email}</p>
                            )}
                            {editing && props.loggedInUser.scope > 0 ? (
                                <>
                                    <Input name={'account_number'} placeholder={'Account Number'} value={account_number} onChange={handleChange} />
                                    <br />
                                </>
                            ) : (
                                <></>
                            )}
                            {!editing && (
                                <h4>
                                    <Badge color="secondary">{props.marinas.find((x) => x.id === props.user.profile.home_marina).marina_name}</Badge>
                                </h4>
                            )}
                            {editing && props.loggedInUser.scope > 1 && props.user.profile.scope > 0 && (
                                <FormGroup>
                                    <GenericSelect options={props.departments} placeholder={'Search for a department'} notFound={'No departments'} onSelect={handleChangeSelectDepartment} value={department} multi={true} />
                                </FormGroup>
                            )}
                            {editing && props.activeUser && (
                                <Button onClick={() => props.dispatch(authActions.requestPasswordReset(props.user.email, navigate))} style={{ marginRight: 10 }}>
                                    Reset Password
                                </Button>
                            )}
                            {editing && props.loggedInUser.scope > 1 && props.user.id !== props.loggedInUser.userId && (
                                <>
                                    <p>
                                        {props.user.profile.scope !== 0 && (
                                            <Button onClick={() => props.dispatch(userActions.setUserScope(props.token, props.user.id, 0))} style={{ marginRight: 10 }}>
                                                Make Customer
                                            </Button>
                                        )}
                                        {props.user.profile.scope !== 1 && (
                                            <Button onClick={() => props.dispatch(userActions.setUserScope(props.token, props.user.id, 1))} style={{ marginRight: 10 }}>
                                                Make Staff
                                            </Button>
                                        )}
                                        {props.user.profile.scope !== 2 && <Button onClick={() => props.dispatch(userActions.setUserScope(props.token, props.user.id, 2))}>Make Admin</Button>}
                                    </p>
                                    <p>
                                        <Button onClick={() => props.dispatch(userActions.setActivationState(props.token, props.user.id, !props.user.is_active))} style={{ marginRight: 10, backgroundColor: 'red' }}>
                                            {props.user.is_active == true ? 'Deactivate User' : 'Reactivate User'}
                                        </Button>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </InnerTitleLayout>
            {props.loggedInUser.scope >= 1 && (
                <Row form>
                    <Col lg={6}>
                        <InnerTitleLayout title={'Staff Comments'} fullHeight={true} actionType={'New Comment'} actionIcon={'plus'} action={toggle}>
                            <BootstrapTable keyField={'comment'} data={props.user.comments} columns={commentColumns} rowStyle={rowStyle} bordered={false} noDataIndication={'No Comments'} />
                            <CommentsModal open={commentsModal} toggle={toggle} user={props.user} equipmentId={equipmentId} />
                        </InnerTitleLayout>
                    </Col>

                    <Col lg={6}>
                        <InnerTitleLayout title={'Activity Log'} fullHeight={true}>
                            <Table title={'activity logs'} keyField={'id'} data={dataActivityLog} columns={columnsActivityLog} pagination={props.activityLog} isLoading={props.loadingActivityLog} onPageChange={onPageChange} />
                        </InnerTitleLayout>
                    </Col>
                </Row>
            )}

            <Row form>
                <Col lg={6}>
                    <InnerTitleLayout title={props.activeUser ? 'My Equipment' : 'Equipment'} fullHeight={true}>
                        <Table title={'equipment'} keyField={'name'} data={dataEquipment} columns={columnsEquipment} pagination={props.equipment} isLoading={props.loadingEquipment} onPageChange={onPageChangeEquipment} />
                        <EquipmentShareModal open={equipmentShareStatus} close={closeEquipmentShare} user={props.user} equipmentId={equipmentId} familyMembers={selectedEquipmentFamily} />
                    </InnerTitleLayout>
                </Col>

                <Col lg={6}>
                    <InnerTitleLayout title={props.activeUser ? 'My Vehicles' : 'Vehicles'} fullHeight={true}>
                        <Table title={'vehicle'} keyField={'license_plate'} data={dataVehicle} columns={columnsVehicle} pagination={props.vehicles} isLoading={props.loadingVehicles} onPageChange={onPageChangeVehicles} />
                    </InnerTitleLayout>
                </Col>
            </Row>
            <Row form>
                <Col lg={12}>
                    <InnerTitleLayout title={'Tickets'}>
                        <TicketView isQuickView={true} onlyCurrentUser={true} forUser={props.user} increaseQuickViewCount={true} />
                    </InnerTitleLayout>
                </Col>
            </Row>
        </div>
    );
};


const EquipmentLink = ({ familyInfo, count, toggle, equipmentId, loggedInUser }) => {
    return (
        <div style={{ textAlign: "right" }}>
            {count}
            {loggedInUser.scope >= 1 &&
                <Button color={'secondary'} type={'submit'} style={{ float: 'right', marginLeft: 20, marginTop: -3 }} onClick={() => toggle(familyInfo, equipmentId)}>
                    <FontAwesomeIcon icon={faEdit} style={{ color: '#fff' }} />
                </Button>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    marinas: state.configuration.marinas,
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    loggedInUser: state.authentication.claims,
    activityLog: state.user.activityLog,
    loadingActivityLog: state.user.loadingActivityLog,
    loadingUser: state.user.loadingUsers,
    token: state.authentication.access,
    loggedInUser: state.authentication.claims,
    equipment: state.inventory.equipment,
    loadingEquipment: state.inventory.loadingInventory,
    loadingVehicles: state.parking.loadingVehicles,
    vehicles: state.parking.vehicles,
    departments: sortOptions(state.configuration.content.departments)
})

function sortOptions(results) {
    var options = [];
    for (var i = 0; i < results.length; i++) {
        options.push({
            value: results[i].id,
            label: results[i].department
        });
    }
    return options;
}

export default connect(mapStateToProps)(Staff);
