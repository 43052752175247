import { alertActions, authActions } from './'
import { userConstants } from '../constants';
import { userApi } from '../api'
import { history } from '../store';
import { push } from 'connected-react-router'

export const userActions = {
    listUsers,
    listUsersWithFilter,
    getUser,
    setSelectedUser,
    createComment,
    getActivityLog,
    getRegisterStatus,
    inviteUser,
    registerUser,
    setUserScope,
    setUserDetails,
    setActivationState
};

function listUsers(token, marina, scope, page, filter=null) {
    return dispatch => {
        dispatch(request());

        userApi.listUsers(token, marina, scope, page, filter)
            .then(
                users => { 
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USERS_REQUEST } }
    function success(users) { return { type: userConstants.USERS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USERS_FAILURE, error } }
}

function listUsersWithFilter(token, marina, filter, staffOnly) {
    return dispatch => {
        dispatch(request());

        var list = userApi.listUsers(token, marina, staffOnly ? 1 : 0, 1, filter != "" ? filter : null)
            .then(
                users => { 
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                    dispatch(failure(error.toString()));
                }
            );
            
        return list;
    };

    function request() { return { type: userConstants.USERS_REQUEST } }
    function success(users) { return { type: userConstants.USERS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USERS_FAILURE, error } }
}

function getUser(token, userId) {
    return dispatch => {
        dispatch(request());

        userApi.getUser(token, userId)
            .then(
                users => { 
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USER_REQUEST } }
    function success(user) { return { type: userConstants.USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_FAILURE, error } }
}

function setUserScope(token, userId, userScope) {
    return dispatch => {
        dispatch(request());

        userApi.setUserScope(token, userId, userScope)
            .then(
                users => { 
                    dispatch(success(users));
                    window.location.reload();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USER_SCOPE_REQUEST } }
    function success(user) { return { type: userConstants.USER_SCOPE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_SCOPE_FAILURE, error } }
}

function setActivationState(token, userId, activationState) {
    return dispatch => {
        dispatch(request());

        userApi.setActivationState(token, userId, activationState)
            .then(
                users => { 
                    dispatch(success(users));
                    window.location.reload();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USER_SCOPE_REQUEST } }
    function success(user) { return { type: userConstants.USER_SCOPE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_SCOPE_FAILURE, error } }
}

function setUserDetails(token, userId, firstName, lastName, email, department, account, activation) {
    return dispatch => {
        dispatch(request());

        userApi.setUserDetails(token, userId, firstName, lastName, email, department, account, activation)
            .then(
                users => { 
                    dispatch(success(users));
                    window.location.reload();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.USER_DETAILS_REQUEST } }
    function success(user) { return { type: userConstants.USER_DETAILS_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USER_DETAILS_FAILURE, error } }
}

function createComment(token, userId, comment) {
    return dispatch => {
        dispatch(request());

        userApi.createComment(token, userId, comment)
            .then(
                users => { 
                    dispatch(success());
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.CREATE_COMMENT_REQUEST } }
    function success() { return { type: userConstants.CREATE_COMMENT_SUCCESS } }
    function failure() { return { type: userConstants.CREATE_COMMENT_FAILURE } }
}


function setSelectedUser(user) {
    return dispatch => {
        dispatch({ type: userConstants.SET_SELECTED_USER, user })
    }
}

function getActivityLog(token, user, page) {
    return dispatch => {
        dispatch(request());

        userApi.getActivityLog(token, user, page)
            .then(
                activityLog => { 
                    dispatch(success(activityLog));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.ACTIVITY_LOG_REQUEST } }
    function success(activityLog) { return { type: userConstants.ACTIVITY_LOG_SUCCESS, activityLog } }
    function failure(error) { return { type: userConstants.ACTIVITY_LOG_FAILURE, error } }
}

function getRegisterStatus(token) {
    return dispatch => {
        dispatch(request());

        var status = userApi.getRegisterStatus(token)
        .then(
            registerStatus => { 
                dispatch(success(registerStatus));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
        return status;
    };

    function request() { return { type: userConstants.REGISTER_STATUS_REQUEST } }
    function success(registerStatus) { return { type: userConstants.REGISTER_STATUS_SUCCESS, registerStatus } }
    function failure(error) { return { type: userConstants.REGISTER_STATUS_FAILURE, error } }
}

function inviteUser(token, marina, email, firstName, lastName) {
    return dispatch => {
        dispatch(request());

        var status = userApi.inviteUser(token, marina, email, firstName, lastName)
        .then(
            inviteStatus => { 
                dispatch(push('/marina/customers'))
                dispatch(alertActions.success("Customer invited! They've been sent an email with additional instructions."));
                dispatch(success(inviteStatus));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
        return status;
    };

    function request() { return { type: userConstants.REGISTER_INVITE_REQUEST } }
    function success(inviteStatus) { return { type: userConstants.REGISTER_INVITE_SUCCESS, inviteStatus } }
    function failure(error) { return { type: userConstants.REGISTER_INVITE_FAILURE, error } }
}

function registerUser(token, email, firstName, lastName, phone, password, navigate) {
    return dispatch => {
        dispatch(request());

        var status = userApi.register(token, email, firstName, lastName, phone, password)
        .then(
            registerStatus => { 
                dispatch(authActions.login(email, password, navigate))
                dispatch(success(registerStatus));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
        return status;
    };

    function request() { return { type: userConstants.REGISTER_REQUEST } }
    function success(registerStatus) { return { type: userConstants.REGISTER_SUCCESS, registerStatus } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

