import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Container, Row, Col, Button } from 'reactstrap';
import { Card, CardBody } from './../../components/card';
import { Form, FormItem } from './../..//components/form';
import TextField from './../../components/textfield';
import Spinner from './../../components/spinner';
import ReactPasswordStrength from 'react-password-strength';
import { configurationActions, authActions } from '../../actions';
import { ReactComponent as Logo } from './../../vectors/dbw-logo.svg';
import { useNavigate } from 'react-router-dom';

const PasswordReset = ({ isResetting }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [token] = useState(queryString.parse(window.location.search).token);
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const passwordChangeCallback = (state) => {
		if (state.isValid) {
			setPassword(state.password);
		} else {
			setPassword(null);
		}
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'email') {
			setEmail(value);
		} else if (name === 'password') {
			setPassword(value);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (token && password) {
			dispatch(authActions.resetPassword(password, token, navigate));
		}
	};

	const handleSubmitNoToken = (e) => {
		e.preventDefault();
		setSubmitted(true);
		if (email) {
			dispatch(authActions.requestPasswordReset(email, navigate));
		}
	};

	let form;
	if (isResetting) {
		form = (
			<Form>
				<Spinner style={{ width: 40, height: 40 }} />
			</Form>
		);
	} else if (!token) {
		form = (
			<Form onSubmit={handleSubmitNoToken}>
				<FormItem>
					<h3>Reset your password</h3>
				</FormItem>
				<FormItem>
					<label>
						<strong>Email</strong>
					</label>
					<TextField
						type="text"
						name="email"
						value={email}
						onChange={handleChange}
						placeholder="Enter your email"
						disabled={isResetting}
					/>
				</FormItem>
				<div className="text-center mt-3">
					<Button color="primary" size="lg" disabled={isResetting || !email}>
						{isResetting ? <Spinner color="white" style={{ width: 40, height: 40 }} /> : 'Reset Password'}
					</Button>
				</div>
			</Form>
		);
	} else {
		form = (
			<Form onSubmit={handleSubmit}>
				<FormItem>
					<h3>Reset your password</h3>
				</FormItem>
				<FormItem>
					<label style={{ width: '100%' }}>
						<strong>Password</strong>
						<input
							type="button"
							className="btn-text"
							value={showPassword ? 'Hide' : 'Show'}
							style={{ float: 'right' }}
							onClick={toggleShowPassword}
						/>
					</label>
					<ReactPasswordStrength
						minLength={5}
						minScore={2}
						scoreWords={['very weak', 'weak', 'good', 'strong', 'stronger']}
						changeCallback={passwordChangeCallback}
						inputProps={{
							name: 'password',
							type: showPassword ? 'text' : 'password',
							autoComplete: 'off',
							className: 'form-control form-control-lg',
							placeholder: 'Enter your password',
							disabled: isResetting,
						}}
					/>
				</FormItem>
				<div className="text-center mt-3">
					<Button color="primary" size="lg" disabled={isResetting || !password}>
						{isResetting ? <Spinner color="white" style={{ width: 40, height: 40 }} /> : 'Reset Password'}
					</Button>
				</div>
			</Form>
		);
	}

	return (
		<main className="main h-100 w-100">
			<Container className="h-100">
				<Row className="h-100">
					<Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
						<div className="d-table-cell align-middle">
							<div className="text-center mt-4">
								<Logo style={{ width: 300 }} />
								<p className="lead">Password Reset</p>
							</div>
							<Card>
								<CardBody>{form}</CardBody>
							</Card>
						</div>
					</Col>
				</Row>
			</Container>
		</main>
	);
};

const mapStateToProps = (state) => ({
	isResetting: state.authentication.isResetting,
});

export default connect(mapStateToProps)(PasswordReset);
