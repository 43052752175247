import React from 'react'
import { connect } from 'react-redux'
import { history } from '../../store';

import moment from 'moment'
import { Layout, InnerTitleLayout } from '../../components/layout'
import { Table } from '../../components/tables'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { parkingActions } from '../../actions'
import BootstrapTable from 'react-bootstrap-table-next';

class ParkingRecordsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            departedModal: false,
            selectedUser: null
        }

        this.toggle = this.toggle.bind(this);
        this.toggleSourced = this.toggleSourced.bind(this);
        this.callDeparted = this.callDeparted.bind(this);
    }

    toggleSourced(event) {
        this.toggle(event.target.value, false);
    }

    toggle(uid=-1, close=true) {
        if (close === true) {
            this.setState(prevState => ({
                departedModal: false
            }));
        } else {
            this.setState(prevState => ({
                departedModal: true,
                selectedUser: parseInt(uid)
            }));
        }
    }

    callDeparted(paymentStatus, recordId) {
      var data = {
        record_id: recordId,
        payment_status: paymentStatus
      };
      this.props.dispatch(parkingActions.markDeparted(this.props.token, this.props.marina, data));
      this.setState(prevState => ({
          departedModal: false
      }));
    }

    render() {

        const columns = [
          {
              dataField: 'vehicle',
              text: 'Vehicle',
              sort: true
            },
            {
              dataField: 'customer',
              text: 'Customer',
              sort: true
            },
            {
              dataField: 'parking_location',
              text: 'Location',
              hidden: document.documentElement.clientWidth < 500
            },
            {
              dataField: 'guest_name',
              text: 'Guest Name',
              hidden: document.documentElement.clientWidth < 500
            },
            {
              dataField: 'arrival_date',
              text: 'Arrival',
              sort: true
            },
            {
              dataField: 'departure_date',
              text: 'Departure',
              hidden: document.documentElement.clientWidth < 500,
              sort: true
            }
        ];

        var data = [];
        for (var i = 0; i < this.props.records.results.length; i++) {
            var record = this.props.records.results[i];
            data.push({
                vehicle: record.vehicle,
                customer: record.customer,
                parking_location: record.parking_location,
                key_number: record.key_number,
                guest_name: record.guest_name,
                arrival_date: moment(record.arrival_date).calendar(),
                departure_date: record.departure_date ? moment(record.departure_date).calendar() : (
                  <Button color={'secondary'} value={record.id} style={{ float: 'right', marginLeft: 5 }} onClick={this.toggleSourced}>
                      {"Mark Departed"}
                   </Button>
                )
            })
        }

        return (
          <>
            <Table
                title={'records'}
                keyField={'vehicle'}
                data={data}
                columns={columns}
                pagination={this.props.records}
                isLoading={this.props.isLoading}
                onPageChange={this.props.onPageChange} 
                onTableChange={this.props.onTableChange} />
            <DepartedModal open={this.state.departedModal} toggle={this.toggle} user={this.state.selectedUser} options={this.props.paymentStatuses} callDeparted={this.callDeparted} />
          </>
        )
    }
}

class DepartedModal extends React.Component {

    constructor(props) {
      super(props);
      this.callDeparted = this.callDeparted.bind(this);
    }

    callDeparted(event) {
        this.props.callDeparted(event.target.value, this.props.user);
    }

    render() {
        const columns = [
            {
                dataField: 'status',
                text: 'Mark payment as:'
            },
            {
              dataField: 'action',
              text: 'Action',
              headerAlign: (column, colIndex) => 'right'
            }
        ];

        var data = [];
        for (var i = 0; i < Object.keys(this.props.options).length; i++) {
            var key = Object.keys(this.props.options)[i];
            var value = Object.values(this.props.options)[i];
            data.push({
                status: value,
                action: (
                    <div style={{  }}>
                        <Button color={'secondary'} value={key} onClick={this.callDeparted} style={{ float: 'right', marginLeft: 5 }}>
                            {"Select"}
                         </Button>
                    </div>
                )
            })
        }

        const rowStyle = (row, rowIndex) => {
            const style = {};
            if (rowIndex % 2) {
                style.backgroundColor = "#f8f9fa";
            } else {
                style.backgroundColor = "#fff";
            }
            return style;
        };

        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} size={'lg'} centered={true}>
                <ModalHeader toggle={this.props.toggle}>Depart Vehicle</ModalHeader>
                <ModalBody>
                    { this.props.isLoading
                        ? "Loading..."
                        : (
                            <>
                              <BootstrapTable
                                  keyField={'status'}
                                  data={data}
                                  columns={columns}
                                  rowStyle={rowStyle}
                                  bordered={false}
                                  noDataIndication={"No Statuses Available"}
                                />
                            </>
                           )
                    }
                </ModalBody>
            </Modal>
        );
    }

}

const mapStateToProps = (state) => ({
  paymentStatuses: state.configuration.content ? state.configuration.content.payment_status : {},
  marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
  token: state.authentication.access
})

export default connect(mapStateToProps)(ParkingRecordsView);
