import { authApi } from './auth'
import { configurationApi } from './configuration'
import { ticketApi } from './ticket'
import { userApi } from './user'
import { inventoryApi } from './inventory'
import { alertApi } from './alert'
import { parkingApi } from './parking'
import { searchApi } from './search'

const config = {
	//url: "https://api.marinamonkey.app",
	url: "https://mm.liveflight.dev",
	//url: "http://127.0.0.1:8000",
	endpoint: {
		// Auth
		login: "/token",
		refresh: "/token/refresh",
		requestPasswordReset: "/user/password_reset/",
		passwordReset: "/user/password_reset/confirm/",

		// Config
		fetchConfiguration: "/configuration",
		fetchSummary: "/summary",
		content: "/content/all",
		departments: "/departments",

		// Tickets
		listCategories: "/ticket/category",
		createCategory: "/ticket/category/new",
		updateCategory: "/ticket/category",
		createAction: "/ticket/action",
		updateAction: "/ticket/action/update",
		listTicket: "/ticket",
		createTicket: "/ticket/new",
		createTicketComment: "/ticket/comment/new",
		markSolved: "/ticket/solve",

		// Users
		listUsers: "/user",
		createComment: "/user/comment",
		activityLog: "/user/activitylog",
		registerStatus: "/user/register/status",
		inviteUser: "/user/register/invite",
		registerUser: "/user/register/register",

		// Inventory
		listEquipment: "/equipment",
		createEquipment: "/equipment/new",
		updateEquipment: "/equipment/{id}/update/",
		listInventory: "/inventory",
		pdf: "/inventory/pdf",
		manageEquipmentAccess: "/equipment/access",

		// Push
		createPushAlert: "/push",

		// Parking
		listVehicles: "/vehicle",
		listRecords: "/record",
		createParkingRecord: "/parking/record/new",
		updateParkingRecord: "/parking/record/update",
		markDeparted: "/parking/record/departed",

		// Search
		search: "/search"
	}
}

export {
	config,
	configurationApi,
	authApi,
	ticketApi,
	userApi,
	inventoryApi,
	alertApi,
	parkingApi,
	searchApi
}