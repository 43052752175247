import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { ticketActions } from '../../actions'

class ActionSelect extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadOptions = (inputValue, callback) => {
      if (!this.props.isOption) {
        this.props.dispatch(ticketActions.listCategories(this.props.token, this.props.marina)).then(() => callback(this.props.categories));
      }
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleChange = (selectedOption) => {
      this.props.onSelect(selectedOption);
    }

    render() {

        if (this.props.isOption) {
          return (
            <Select
              options={this.props.options.filter(x => x.category === this.props.action)}
              onInputChange={this.handleInputChange}
              placeholder={this.props.isDisabled ? this.props.placeholder : this.props.isOption ? 'Search for an option' : 'Search for an action'}
              noOptionsMessage={(inputText) => inputText === "" ? this.props.isOption ? 'Search for an option' : 'Search for an action' : this.props.isOption ? 'No options found' : 'No actions found'}
              onChange={this.handleChange}
              isDisabled={this.props.isDisabled}
            />
          )
        }

        return (
            <AsyncSelect
              cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onInputChange={this.handleInputChange}
              placeholder={this.props.isDisabled ? this.props.placeholder : this.props.isOption ? 'Search for an option' : 'Search for an action'}
              noOptionsMessage={(inputText) => inputText === "" ? this.props.isOption ? 'Search for an option' : 'Search for an action' : this.props.isOption ? 'No options found' : 'No actions found'}
              onChange={this.handleChange}
              isDisabled={this.props.isDisabled}
            />
        );

    }

}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.user.loadingUsers,
    categories: state.ticket.categoriesSelect,
    options: state.ticket.optionsSelect
})

export default connect(mapStateToProps)(ActionSelect);
