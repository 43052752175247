import { alertConstants } from '../constants';
import { toastr } from 'react-redux-toastr'
import { alertApi } from '../api';
import { push } from 'connected-react-router'

export const alertActions = {
    success,
    error,
    clear,
    createPushAlert
};

function success(message) {
	toastr.success(message);
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
	toastr.error(message);
    return { type: alertConstants.ERROR, message };
}

function clear() {
	toastr.removeByType('success');
	toastr.removeByType('error');
    return { type: alertConstants.CLEAR };
}

function createPushAlert(token, marina, pushAlert, navigate) {
    return dispatch => {
        dispatch(request());

        alertApi.createPushAlert(token, marina, pushAlert)
            .then(
                result => { 
                    dispatch(success());
                    navigate('/marina/alert')
                    dispatch(alertActions.success("Message sent!"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: alertConstants.PUSH_ALERT_REQUEST } }
    function success() { return { type: alertConstants.PUSH_ALERT_SUCCESS } }
    function failure(error) { return { type: alertConstants.PUSH_ALERT_FAILURE, error } }
}
