import React from 'react'
import { connect } from 'react-redux'
import { configurationActions } from '../../actions'

const Hamburger = (props) => (
	<a className={'sidebar-toggle d-flex mr-2'} onClick={props.onMenuToggle}>
		<i className={'hamburger align-self-center'}></i>
	</a>
)

const mapStateToProps = ({ authentication }) => ({

})

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuToggle: (id) => {
    	dispatch(configurationActions.toggleSidebar())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hamburger)