import React, { useState } from 'react';
import { connect } from 'react-redux';
import { alertActions, configurationActions, inventoryActions } from '../../actions';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ReactQuill from 'react-quill';
import { Form } from '../../components/form';
import TextField from './../../components/textfield';
import { Col, Row, Button, FormGroup, Label, Input } from 'reactstrap';
import { CustomerSelect } from '../../components/select';
import { useNavigate } from 'react-router-dom';

const NewEquipment = (props) => {
	const [submitted, setSubmitted] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [selectedName, setSelectedName] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedMake, setSelectedMake] = useState(null);
	const [selectedModel, setSelectedModel] = useState(null);
	const [selectedLicense, setSelectedLicense] = useState(null);
	const [selectedSerial, setSelectedSerial] = useState(null);
	const [selectedVetted, setSelectedVetted] = useState(false);
	const navigate = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'selectedCustomer') {
			setSelectedCustomer(value);
		} else if (name === 'selectedName') {
			setSelectedName(value);
		} else if (name === 'selectedYear') {
			setSelectedYear(value);
		} else if (name === 'selectedMake') {
			setSelectedMake(value);
		} else if (name === 'selectedModel') {
			setSelectedModel(value);
		} else if (name === 'selectedLicense') {
			setSelectedLicense(value);
		} else if (name === 'selectedSerial') {
			setSelectedSerial(value);
		} else if (name === 'selectedVetted') {
			setSelectedVetted(e.target.checked);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setSubmitted(true);

		if (selectedCustomer === null) {
			props.dispatch(alertActions.error('No customer has been selected'));
			return;
		}

		props.dispatch(
			inventoryActions.createEquipment(
				props.token,
				props.marina,
				selectedName,
				selectedYear,
				selectedMake,
				selectedModel,
				selectedLicense,
				selectedSerial,
				selectedVetted,
				selectedCustomer,
				navigate
			)
		);
	};

	return (
		<div>

			<Form className={'card card-body'} onSubmit={handleSubmit}>
				<div className={'m-sm-4'}>
					{props.user.scope >= 1 && (
						<div>
							<Row form>
								<Col md={6}>
									<FormGroup>
										<label>What customer is this for?</label>
										<CustomerSelect
											onSelect={(customer) => setSelectedCustomer(customer.value)}
										/>
									</FormGroup>
								</Col>
							</Row>
							<hr />
						</div>
					)}

					<FormGroup>
						<Row form>
							<Col md={6}>
								<FormGroup>
									<label>Name</label>
									<br />
									<TextField
										name={'selectedName'}
										value={selectedName}
										onChange={handleChange}
										required={true}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<label>Year</label>
									<br />
									<TextField
										name={'selectedYear'}
										value={selectedYear}
										onChange={handleChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row form>
							<Col md={6}>
								<FormGroup>
									<label>Make</label>
									<br />
									<TextField
										name={'selectedMake'}
										value={selectedMake}
										onChange={handleChange}
										required={true}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<label>Model</label>
									<br />
									<TextField
										name={'selectedModel'}
										value={selectedModel}
										onChange={handleChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row form>
							<Col md={6}>
								<FormGroup>
									<label>License</label>
									<br />
									<TextField
										name={'selectedLicense'}
										value={selectedLicense}
										onChange={handleChange}
										required={true}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<label>Serial</label>
									<br />
									<TextField
										name={'selectedSerial'}
										value={selectedSerial}
										onChange={handleChange}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row form>
							<Col md={6}>
								<FormGroup>
									<label className={'custom-control custom-checkbox'}>
										<input
											type={'checkbox'}
											name={'selectedVetted'}
											className={'custom-control-input'}
											value={selectedVetted}
											onChange={handleChange}
										/>
										<span className={'custom-control-label'}>Vetted?</span>
									</label>
								</FormGroup>
							</Col>
						</Row>
					</FormGroup>

					<Button color={'primary'} type={'submit'}>
						Add New Equipment
					</Button>
				</div>
			</Form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	token: state.authentication.access,
	user: state.authentication.claims,
	marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
});

export default connect(mapStateToProps)(NewEquipment);