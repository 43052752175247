import React from 'react'
import { connect } from 'react-redux'
import { configurationActions } from '../../actions'
import { TicketView } from './'


class Tickets extends React.Component {
    
    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("My Tickets"));
    }

    render() {

        return (
            <div>
                <TicketView title={"My Tickets"} onlyCurrentUser={true} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.authentication.claims,
    marinas: state.configuration.marinas
})

export default connect(mapStateToProps)(Tickets);
