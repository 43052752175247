import React from 'react'
import { connect } from 'react-redux'
import { configurationActions, userActions } from '../../actions'

import { Layout } from '../../components/layout'
import { UserView } from './'
import Spinner from '../../components/spinner'


class Profile extends React.Component {
    
    constructor(props) {
        super(props);

        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        var userId = this.props.userId;
        if (userId === null) {
            this.props.dispatch(userActions.getUser(this.props.token, this.props.userClaims.user_id));
        } else {
            this.props.dispatch(userActions.getUser(this.props.token, userId));
        }
    }

    render() {

        if (this.props.pendingCommentsRefresh) {
            this.refresh();
        }

        return (
            <div>
                {this.props.user != null
                    ? (
                        <Layout title={this.props.userId != null ? 'User Profile' : 'My Profile'} hideCard={true}>
                            <UserView user={this.props.user} activeUser={this.props.userId === null} />
                        </Layout>
                    )
                    : <Spinner coloured={true} />
                }
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userClaims: state.authentication.claims,
    marinas: state.configuration.marinas,
    token: state.authentication.access,
    user: state.user.selectedUser,
    userId: new URLSearchParams(window.location.search).get('userId'),
    pendingCommentsRefresh: state.user.pendingCommentsRefresh
})

export default connect(mapStateToProps)(Profile);
