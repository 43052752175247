import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { configurationActions, parkingActions } from '../../actions'

import { ParkingRecordsView } from './'
import { Layout, InnerTitleLayout } from '../../components/layout'
import TextField from '../../components/textfield'
import { throttle } from 'throttle-debounce';

class AllParkingRecordsView extends React.Component {
    
    constructor(props) {
        super(props);

        this.onPageChange = this.onPageChange.bind(this);
        this.search = this.search.bind(this);
        this.throttleSearch = throttle(1000, this.handleSearch)
        this.handleSearch = this.handleSearch.bind(this); 
    }

    componentDidMount() {
        this.props.dispatch(configurationActions.changePageTitle("All Parking Records"));
        this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, 1));
    }

    onPageChange = (page, sizePerPage) => {
        this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, page));
    }

    onTableChange = (type, { sortField, sortOrder, data }) => {
        var ordering = `${sortOrder == "asc" ? "" : "-"}${sortField}`;
        this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, 1, null, ordering));
    }

    search(e) {
        const { value } = e.target;
        this.throttleSearch(value);
    }

    handleSearch(value) {
        if (value.length > 1 || value == "") {
            this.props.dispatch(parkingActions.listRecords(this.props.token, this.props.marina, 1, null, "-arrival_date", value));
        }
    }

    render() {
        return (
            <Layout title={'Parking'} noBorder={true}>
              <InnerTitleLayout title={"All Parking Records"} hideCard={true} search={<TextField id={"search"} onChange={this.search} placeholder={"Search for a record"} />}>
                <ParkingRecordsView isLoading={this.props.isLoading} pagination={this.props.records} onPageChange={this.onPageChange} records={this.props.records} onTableChange={this.onTableChange} />
              </InnerTitleLayout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    user: state.authentication.claims.user_id,
    isLoading: state.parking.loadingRecords,
    records: state.parking.records
})

export default connect(mapStateToProps)(AllParkingRecordsView);
