import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { ticketActions } from '../../actions'

class ActionSelect extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            options: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        //this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleChange = (selectedOption) => {
      this.props.onSelect(selectedOption, this.props.id);
    }

    render() {
        return (
            <Select
              options={this.props.options}
              onInputChange={this.handleInputChange}
              placeholder={this.props.placeholder}
              noOptionsMessage={(inputText) => inputText === "" ? this.props.placeholder : this.props.notFound}
              onChange={this.handleChange}
              isDisabled={this.props.isDisabled}
              defaultValue={this.props.value}
              isMulti={this.props.multi}
            />
        );

    }

}

export default ActionSelect;
