import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { configurationActions, ticketActions } from '../../actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { InnerTitleLayout, StatusLayout } from '../../components/layout';
import { Form, FormGroup, Button, Row, Col, Badge } from 'reactstrap';
import Spinner from './../../components/spinner';
import { push } from 'connected-react-router';

const Ticket = (props) => {
    const [message, setMessage] = useState("");
    const { ticketId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const {
            dispatch,
            token,
            marina,
        } = props;

        dispatch(ticketActions.getTicket(token, ticketId, marina, navigate));
        dispatch(configurationActions.changePageTitle('Ticket #' + ticketId));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMessage(value);
    };

    const handleReply = () => {
        handleSubmit(false);
    };

    const handleStaffNote = () => {
        handleSubmit(true);
    };

    const handleSolve = () => {
        const {
            dispatch,
            token,
        } = props;
        handleSubmit(false);
        dispatch(ticketActions.markSolved(token, ticketId, navigate));
    };

    const handleSubmit = (isStaffNote) => { 
        if (message !== '') {
            const {
                dispatch,
                token,
                user,
                ticket,
                marina,
            } = props;

            dispatch(
                ticketActions.createComment(
                    token,
                    user.user_id,
                    isStaffNote,
                    message,
                    ticket.id,
                    marina
                )
            );
        }
    };

    const {
        isLoading,
        ticket,
        comments,
        user,
        equipment,
    } = props;

    if (isLoading || ticket === null) {
        return <Spinner coloured={true} />;
    }

    return (
        <div>

            <Row>
                <Col md={4}>
                    <StatusLayout>
                        <p className="mb-1 mt-1">{'Ticket'}</p>
                        <h3>{'#' + ticketId}</h3>
                    </StatusLayout>
                </Col>
                <Col md={4}>
                    <StatusLayout>
                        <p className="mb-1 mt-1">{'Required'}</p>
                        <h3>{moment(ticket.date_required).calendar()}</h3>
                    </StatusLayout>
                </Col>
                <Col md={4}>
                    <StatusLayout>
                        <p className="mb-1 mt-1">{ticket.action.category}</p>
                        <h3>{ticket.action.action}</h3>
                    </StatusLayout>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <StatusLayout>
                        <div className="media">
                            <div className="media-body">
                                <p className="mb-1 mt-1">{'Requested By'}</p>
                                <Link to={'/account/profile?userId=' + ticket.requested_by.id}>
                                    <h3>{ticket.requested_by.first_name + ' ' + ticket.requested_by.last_name}</h3>
                                </Link>
                            </div>
                        </div>
                    </StatusLayout>
                </Col>
                <Col md={4}>
                    <StatusLayout>
                        <div className="media">
                            <div className="media-body">
                                <p className="mb-1 mt-1">{'Assigned to'}</p>
                                <h3>
                                    {ticket.assigned_to?.length == 0
                                        ? <i>Unassigned</i>
                                        : ticket.assigned_to.map((assigned, i) => {
                                            if (i < ticket.assigned_to.length - 1) {
                                                return (
                                                    <>
                                                        {props.user.scope == 0 ?
                                                            assigned.first_name
                                                            : <Link to={'/account/profile?userId=' + assigned.id}>
                                                                {assigned.first_name}
                                                            </Link>
                                                        }
                                                        {", "}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <>
                                                        {props.user.scope == 0 ?
                                                            assigned.first_name
                                                            : <Link to={'/account/profile?userId=' + assigned.id}>
                                                                {assigned.first_name}
                                                            </Link>
                                                        }
                                                    </>
                                                )
                                            }
                                        })}
                                </h3>
                            </div>
                        </div>
                    </StatusLayout>
                </Col>
                <Col md={4}>
                    <StatusLayout>
                        <div className="media">
                            <div className="media-body">
                                <p className="mb-1 mt-1">{'Equipment'}</p>
                                {/*<Link to={'/inventory/equipment/' + ticket.equipment.id}>*/}
                                <h3>{ticket.equipment.name + (equipment ? (' (' + ticket.equipment.model.make + ' ' + ticket.equipment.model.model + ')') : '')}</h3>
                                {/*</Link>*/}
                            </div>
                        </div>
                    </StatusLayout>
                </Col>
            </Row>

            <InnerTitleLayout title={'Comments'} bordered={true}>

                <div>

                    {_.orderBy(comments, 'created', 'asc').map(comment => {
                        return (
                            <div key={comment.id}>
                                <div className="media" style={comment.is_staff_note ? { backgroundColor: '#ffffca', display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}>
                                    <Link to={'/account/profile?userId=' + comment.user.id}>
                                        <img src="/img/avatar.jpg" width="40" height="40" className="rounded-circle mr-3" alt={comment.user.first_name + ' ' + comment.user.last_name} style={{ marginRight: 15 }} />
                                    </Link>
                                    <div className="media-body">
                                        <small className="float-right text-navy">
                                            {moment(comment.created).fromNow()}
                                        </small>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <strong>
                                                <Link to={'/account/profile?userId=' + comment.user.id}>
                                                    {comment.user.first_name + ' ' + comment.user.last_name}
                                                </Link>
                                            </strong>
                                            {comment.is_staff_note &&
                                                <>
                                                    {' '}
                                                    <Badge color="secondary" style={{ marginLeft: 10 }}>{'Staff Note'}</Badge>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <p>{comment.comment}</p>
                                <small className="text-muted">
                                    {moment(comment.created).calendar()}
                                </small>

                                <hr />
                            </div>
                        );
                    })}

                    <FormGroup>
                        <Form>
                            <h4>Add a reply:</h4>
                            <div className="media" style={{ marginTop: 20, display: 'flex', alignItems: 'flex-start' }}>
                                <img src="/img/avatar.jpg" width="40" height="40" className="rounded-circle mr-3" alt={'You'} style={{ marginRight: 20 }} />
                                <div className="media-body" style={{ flex: 1 }}>
                                    <textarea className={'form-control'} style={{ marginTop: 5, width: '100%' }} onChange={handleChange} name={'message'}></textarea>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                                        <div>
                                            <Button color={'secondary'} onClick={handleReply} disabled={!message}>{user.scope > 0 ? 'Reply to Customer' : 'Reply'}</Button>
                                            {user.scope > 0 &&
                                                <>
                                                    <Button color={'primary'} onClick={handleStaffNote} disabled={!message} style={{ marginLeft: 10 }}>{'Add Note'}</Button>
                                                </>
                                            }
                                        </div>
                                        {user.scope > 0 && !ticket.solved &&
                                            <Button color={'danger'} onClick={handleSolve}>{'Mark Solved'}</Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </FormGroup>

                </div>

            </InnerTitleLayout>

        </div>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    marinaName: state.configuration.activeMarina.marina_name,
    token: state.authentication.access,
    isLoading: state.ticket.loadingTickets,
    ticket: state.ticket.currentTicket,
    comments: state.ticket.currentTicket ? state.ticket.currentTicket.comments : [],
    user: state.authentication.claims,
});

export default connect(mapStateToProps)(Ticket);