import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ loggedIn, component: Component }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!loggedIn) {
            navigate('/login');
        }
    }, [loggedIn, navigate]);

    if (!Component) {
        return <></>
    }

    return (
        <>
            <Component />
        </>
    );
}

export default PrivateRoute;