import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { configurationActions, userActions } from '../../actions';
import { history } from '../../store';

import BootstrapTable from 'react-bootstrap-table-next';
import { Layout, InnerTitleLayout } from '../../components/layout';
import { Table } from '../../components/tables';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import TextField from '../../components/textfield';

const DepartmentView = (props) => {
    const [commentsModal, setCommentsModal] = useState(false);

    useEffect(() => {
        props.dispatch(configurationActions.changePageTitle("Departments"));
        props.dispatch(configurationActions.fetchDepartments(props.token, props.marina, 1));
    }, [props.marina]);

    const onPageChange = (page, sizePerPage) => {
        props.dispatch(configurationActions.fetchDepartments(props.token, props.marina, 1));
    };

    const toggle = () => {
        setCommentsModal(prev => !prev);
    };

    const create = (name) => {
        props.dispatch(configurationActions.createDepartment(props.token, props.marina, name));
        setCommentsModal(false);
    };

    const columns = [
        {
            dataField: 'department',
            text: 'Department'
        }
    ];

    const data = props.departments.results.map(department => ({ department: department.department }));

    return (
        <Layout title={"Departments"} noBorder={true}>
            <InnerTitleLayout title={"Manage Departments"} hideCard={true} action={toggle} actionType={"New Department"}>
                <Table
                    title={"Departments"}
                    keyField={'category'}
                    data={data}
                    columns={columns}
                    pagination={props.departments}
                    isLoading={props.isLoading}
                    onPageChange={onPageChange}
                />
            </InnerTitleLayout>
            <NewDepartmentModal open={commentsModal} toggle={toggle} create={create} />
        </Layout>
    );
};

const NewDepartmentModal = (props) => {
    const [name, setName] = useState("");

    const onChange = (event) => {
        setName(event.target.value);
    };

    const onCreate = () => {
        props.create(name);
        setName("");
    };

    return (
        <Modal isOpen={props.open} toggle={props.toggle} size={'lg'} centered={true}>
            <ModalHeader toggle={props.toggle}>New Department</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <TextField value={name} onChange={onChange} placeholder={"Department Name"} />
                </FormGroup>
                <FormGroup>
                    <Button onClick={onCreate}>Create</Button>
                </FormGroup>
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    marina: state.configuration.activeMarina ? state.configuration.activeMarina.id : 1,
    token: state.authentication.access,
    isLoading: state.configuration.loadingDepartments,
    departments: state.configuration.departments
});

export default connect(mapStateToProps)(DepartmentView);
